import { KeyboardArrowDown } from '@mui/icons-material';
import { Menu, MenuItem, Typography, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFlex } from 'src/components/StyledFlex';
import { QuestionnaireMethodology } from 'src/Esg/types';

interface Props {
  value: QuestionnaireMethodology;
  onChange: (value: QuestionnaireMethodology) => void;
}
export default function QuestionnaireMethodologySelector(props: Props) {
  const { value, onChange } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onClose = () => setAnchorEl(null);
  return (
    <>
      <Button
        variant="text"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDown />}
      >
        {t('esg.questionnaireMethodology.methodology')}:{' '}
        {t(`esg.questionnaireMethodology.${value}`)}
      </Button>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
        {Object.values(QuestionnaireMethodology).map((methodology) => (
          <MenuItem
            key={methodology}
            onClick={() => {
              onChange(methodology);
              onClose();
            }}
          >
            {t(`esg.questionnaireMethodology.${methodology}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
