import React from 'react';

import { QuestionnaireV3 as Props } from './types';
import QuestionnaireData from './Data';
import QuestionnaireV3Auth from './Data/QuestionnaireV3Auth';

export default function QuestionnaireV3(props: Props) {
  if (!props.questionnaireAnswerId) return null;
  return (
    <QuestionnaireV3Auth {...props}>
      <QuestionnaireData {...props} />
    </QuestionnaireV3Auth>
  );
}
