import React from 'react';
import NumberField from '.';
import { Box, IconButton, TextFieldProps } from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';
import { StyledFlex } from '../StyledFlex';

interface Props {
  maxDigits?: number;
  decimalPlaces?: number;
  onAccept: (
    // eslint-disable-next-line no-unused-vars
    value: unknown
  ) => void;
  onReject?: () => void;
  buttonsAlwaysVisible?: boolean;
}

export default function AcceptableNumberField(props: TextFieldProps & Props) {
  const { onAccept, onReject, buttonsAlwaysVisible = false, ..._props } = props;
  const [value, setValue] = React.useState(props.value);
  const [acceptTimeout, setAcceptTimeout] = React.useState<any>(null);
  const isEditing = value !== props.value;

  const handleAcceptClick = () => {
    clearTimeout(acceptTimeout);
    return isEditing && Number(value) && onAccept(value);
  };

  const handleRejectClick = () => {
    clearTimeout(acceptTimeout);
    setValue(props.value);
    if (onReject) onReject();
  };

  const handleBlur = () =>
    setAcceptTimeout(setTimeout(() => setValue(props.value), 300));

  return (
    <StyledFlex>
      <NumberField
        {..._props}
        value={value}
        onChange={(v) => setValue(v)}
        onKeyDown={(e) => e.key === 'Enter' && handleAcceptClick()}
        InputProps={{
          onBlur: handleBlur,
        }}
      />

      <Box
        sx={{ display: isEditing || buttonsAlwaysVisible ? 'flex' : 'none' }}
      >
        <IconButton
          size="small"
          onClick={handleAcceptClick}
          disabled={!isEditing}
          sx={{ mr: '-10px', '&:hover': { bgcolor: 'transparent' } }}
        >
          <CheckCircle />
        </IconButton>
        <IconButton
          onClick={handleRejectClick}
          sx={{ '&:hover': { bgcolor: 'transparent' } }}
        >
          <Close />
        </IconButton>
      </Box>
    </StyledFlex>
  );
}
