import React from 'react';
import { client, handleToastError } from '../../../../utils/api-client';
import { injectNewAnswers, paths } from '../helpers';
import QuestionnaireContext from './QuestionnaireContextProvider';
import { QuestionnaireV3 } from '../types';
import PageLoading from 'src/components/PageLoading';

// bulk qv3 auth
export default function QuestionnaireRequestData(props: QuestionnaireV3) {
  const { questionnaireAnswerId, tokenAuth } = props;

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState();

  const refetch = React.useCallback(
    () =>
      tokenAuth().then(
        (token) =>
          token &&
          client
            .get(`${paths.questionnaireAnswers}/${questionnaireAnswerId}`, {
              headers: {
                'X-Questionnaires-Token': token,
              },
            })
            .then((res) => res.data)
            .catch((err) => handleToastError(err))
      ),
    [tokenAuth, questionnaireAnswerId]
  );

  React.useEffect(() => {
    refetch().then((data) => {
      setData(injectNewAnswers(data));
      setLoading(false);
    });
  }, []);

  const contextProps = {
    ...props,
    data,
    refetch,
  };

  if (loading) return <PageLoading />;

  return <QuestionnaireContext {...contextProps} />;
}
