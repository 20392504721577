import React, { useState } from 'react';
import { IntuitiveCalendarPeriod } from '../IntuitiveCalendar';
import {
  getFirstDateOfPeriod,
  timeConverter,
} from '../../../utils/timeConverter';
import { useAppSelector } from '../../../redux-file/hooks';
import {
  ButtonGreen,
  ButtonWhite,
  CloseBtn,
  FromButtonWrapper,
  Title,
  ToButtonWrapper,
  ToTitle,
  WarningText,
  Wrapper,
} from './index.styles';
import WarningModalCopyForm from './warningModal';
import { useTranslation } from 'react-i18next';
import { client } from '../../../utils/api-client';
import { PreviewBox } from '../../Pages/Questionnaire/index.styles';
import { Overlay } from '../CollectConsentsFormModal/index.styles';
import { useGhgOrganization } from 'src/common/hooks';
import { Button } from '@mui/material';

interface Props {
  form:
    | 'DirectEmissionsData'
    | 'AssetsFranchisesAndInvestmentsData'
    | 'ElectiricyEmissionsData'
    | 'WasteAndOtherActivitiesData'
    | 'TransportationAndDistributionData';
  orgId: number | null;
  refresh: Function;
}

const CopyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Copy_icon">
      <path
        id="Vector"
        d="M15.75 2.25H6.75C5.925 2.25 5.25 3.0375 5.25 4V16.25H6.75V4H15.75V2.25ZM18 5.75H9.75C8.925 5.75 8.25 6.5375 8.25 7.5V19.75C8.25 20.7125 8.925 21.5 9.75 21.5H18C18.825 21.5 19.5 20.7125 19.5 19.75V7.5C19.5 6.5375 18.825 5.75 18 5.75ZM18 19.75H9.75V7.5H18V19.75Z"
        fill="white"
      />
    </g>
  </svg>
);

function CloseButton({ action }: { action: Function }) {
  return (
    <CloseBtn
      onClick={() => action()}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z"
        fill="#606462"
      />
    </CloseBtn>
  );
}

export default function CopyForm({ form, orgId, refresh }: Props) {
  const [calendar, setCalendar] = useState<'source' | 'target'>('source');
  const [visible, setVisible] = useState(false);
  const [warning, setWarning] = useState(false);
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;
  const { t } = useTranslation();
  const [tempData, setTempData] = useState<any>();
  const [sourceDate, setSourceDate] = useState(new Date());
  const [targetDate, setTargetDate] = useState(new Date());
  const { selectedDate } = useAppSelector((state) => state.questionnaire);
  const url = (period: string) =>
    `/web/v2/questionnaires/${form}?organization_id=${orgId}&period=${period}`;

  const handleClose = () => {
    setWarning(false);
    setVisible(false);
    setCalendar('source');
    setTargetDate(new Date());
    setSourceDate(new Date());
    setTempData(null);
  };

  const handleConfirm = async () => {
    await client.put(
      url(timeConverter(targetDate, reportingPeriod)),
      JSON.stringify({ ...tempData })
    );
    if (
      targetDate.toISOString().slice(0, 10) ===
      selectedDate.toISOString().slice(0, 10)
    ) {
      refresh();
    }
    setVisible(false);
    setCalendar('source');
    setWarning(false);
    setTempData(null);
  };

  const handleBack = () => setCalendar('source');

  const handleCopy = async () => {
    const temp = (
      await client.get(url(timeConverter(targetDate, reportingPeriod)))
    ).data;

    if (
      (form !== 'TransportationAndDistributionData' &&
        // @ts-ignore
        Object.values(temp).filter((el) => el.length !== 0).length !== 0) ||
      (form === 'TransportationAndDistributionData' &&
        (temp.distribution.downstream.length !== 0 ||
          temp.distribution.upstream.length !== 0 ||
          temp.transportation.downstream.length !== 0 ||
          temp.transportation.upstream.length !== 0))
    ) {
      if (
        targetDate.toISOString().slice(0, 10) ===
        selectedDate.toISOString().slice(0, 10)
      ) {
        refresh();
      }
      setVisible(false);
      setWarning(true);
    } else {
      await handleConfirm();
    }
  };

  return (
    <div>
      <Button startIcon={<CopyIcon />} onClick={() => setVisible(!visible)}>
        {t('copy_form.buttons.from')}
      </Button>

      {visible && (
        <Wrapper>
          {calendar === 'source' && (
            <PreviewBox>
              <CloseButton action={handleClose} />
              <Title>{t('copy_form.from')}</Title>
              <IntuitiveCalendarPeriod
                reportingPeriod={reportingPeriod}
                selectedDate={getFirstDateOfPeriod(
                  sourceDate,
                  reportingPeriod ?? 'M'
                )}
                setSelectedDate={(selDate: Date) => {
                  setSourceDate(selDate);

                  client
                    .get(url(timeConverter(selDate, reportingPeriod)))
                    .then(({ data }) => {
                      setTempData(data);
                    });
                }}
              />
              <FromButtonWrapper>
                <Button size="large" onClick={() => setCalendar('target')}>
                  {t('copy_form.buttons.from')}
                </Button>
              </FromButtonWrapper>
            </PreviewBox>
          )}
          {calendar === 'target' && (
            <PreviewBox>
              <CloseButton action={handleClose} />

              <ToTitle>{t('copy_form.to')}</ToTitle>
              <WarningText>{t('copy_form.to-warning')}</WarningText>
              <IntuitiveCalendarPeriod
                reportingPeriod={reportingPeriod}
                selectedDate={getFirstDateOfPeriod(
                  targetDate,
                  reportingPeriod ?? 'M'
                )}
                setSelectedDate={async (selDate: Date) => {
                  setTargetDate(selDate);
                }}
              />
              <ToButtonWrapper>
                <Button variant="secondary" size="large" onClick={handleBack}>
                  {t('copy_form.buttons.back')}
                </Button>
                <Button size="large" onClick={async () => handleCopy()}>
                  {t('copy_form.buttons.to')}
                </Button>
              </ToButtonWrapper>
            </PreviewBox>
          )}
        </Wrapper>
      )}
      {warning && (
        <WarningModalCopyForm
          abortButton={handleClose}
          goButton={async () => await handleConfirm()}
        />
      )}
      {visible || warning ? <Overlay /> : null}
    </div>
  );
}
