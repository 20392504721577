import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';
import { ImageSlider } from '../components/ImageSlider';
import { generateImageUrls } from '../utils';

export default function Stage0() {
  const { t, language: l } = useLanguage({ keyPrefix: 'esg.dma' });
  return (
    <DmaStage
      title={t('stagesV2.introduction.title')}
      subtitle={t('stagesV2.introduction.subtitle')}
      containerSx={{ border: '4px solid #e9bc46' }}
    >
      <DmaStep
        title={t('stagesV2.introduction.steps.1')}
        navigateToOutside="https://assets.envirly.pl/dmav2/step0/glossary-pl.pdf"
      />
      <DmaStep
        title={t('stagesV2.introduction.steps.2')}
        videoComponent={<ImageSlider images={generateImageUrls(l, 1, 5)} />}
      />
      <DmaStep
        title={t('stagesV2.introduction.steps.3')}
        videoComponent={<ImageSlider images={generateImageUrls(l, 2, 9)} />}
      />
    </DmaStage>
  );
}
