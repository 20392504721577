import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductionProcessSurveyIntro from './Intro';
import ProductionProcessSurveyQuestionnaire from './Questionnaire';
import ProductionProcessSurveyEnd from './End';
import cbam from '../theme';
import { ThemeProvider } from '@mui/material';
import Auth from 'src/components/Auth/QueryParamTokenAuthInterceptor';
import { client } from 'src/utils/api-client';
import { useDispatch } from 'react-redux';
import { usePlatform } from 'src/redux-file/hooks';
import {
  setActiveOrganization,
  setActiveOrganizationId,
} from 'src/redux-file/platform/slice';
import PageLoading from 'src/components/PageLoading';

const EnsureOrganization = (props: { children: React.ReactNode }) => {
  const { activeOrganizationId } = usePlatform();
  const dispatch = useDispatch();

  React.useEffect(() => {
    client.get('/web/cbam/survey/organization').then((response) => {
      const organization = response.data;
      dispatch(setActiveOrganization(organization));
      dispatch(setActiveOrganizationId(organization.id));
    });
  }, []);
  if (!activeOrganizationId) return <PageLoading />;
  return props.children as React.ReactElement;
};

export default function CbamProductionProcessSurveyRoute() {
  return (
    <ThemeProvider theme={cbam}>
      <Auth>
        <EnsureOrganization>
          <Routes>
            <Route index element={<ProductionProcessSurveyIntro />} />
            <Route path="questionnaire">
              <Route
                path=":tab?"
                element={<ProductionProcessSurveyQuestionnaire />}
              />
            </Route>
            <Route path="end" element={<ProductionProcessSurveyEnd />} />
          </Routes>
        </EnsureOrganization>
      </Auth>
    </ThemeProvider>
  );
}
