import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

import { UniversalField } from '../types';
import { useTranslation } from 'react-i18next';
import { HOVER_COLOR } from '../../../helpers';
import SampleAnswer from './SampleAnswer';

export default function SimpleTextField(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled, hints } = props;
  const { label, placeholder, required } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: string) => {
    // required field
    if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (_value: string) => {
    _validate(_value);
    onChange(!!_value ? _value : null);
  };

  // not showing the label when placeholder is set
  // except when the value is set (placeholder is covered by value anyways)
  const _label = !value && !!placeholder ? null : label;

  return (
    <>
      <Autocomplete
        freeSolo
        options={hints || []}
        value={value ?? ''}
        onChange={(_, value) => handleChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            multiline
            label={_label}
            onChange={(e) => handleChange(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
            disabled={disabled}
            sx={{
              mb: placeholder ? '12px' : '32px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: !!value ? HOVER_COLOR : 'inherit',
              },
            }}
          />
        )}
      />

      <SampleAnswer placeholder={placeholder} />
    </>
  );
}
