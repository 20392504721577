import { Message } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import LightTooltip from 'src/components/LightTooltip';
import themeColors from 'src/theme/colors';

interface Props {
  comment: string | null;
}

export default function CommentTooltip({ comment }: Props) {
  if (!comment) {
    return null;
  }

  return (
    <LightTooltip title={comment}>
      <Box
        sx={{
          p: '6px',
          display: 'flex',
          alignItems: 'center',
          translate: '40% -40%',
          ':hover': { bgcolor: themeColors.pressed },
        }}
      >
        <Message color="primary" fontSize="small" />
      </Box>
    </LightTooltip>
  );
}
