import {
  PerfluorocarbonsOvervoltageTechnologies,
  PerfluorocarbonsSlopeTechnologies,
} from 'src/Cbam/types';

export const SlopeEmissionFactors = {
  [PerfluorocarbonsSlopeTechnologies.PFPB_L]: 0.122,
  [PerfluorocarbonsSlopeTechnologies.PFPB_M]: 0.104,
  [PerfluorocarbonsSlopeTechnologies.CWPB]: 0.143,
  [PerfluorocarbonsSlopeTechnologies.SWPB]: 0.233,
  [PerfluorocarbonsSlopeTechnologies.VSS]: 0.058,
  [PerfluorocarbonsSlopeTechnologies.HSS]: 0.165,
};

export const SlopeC2F6ToCF4Ratios = {
  [PerfluorocarbonsSlopeTechnologies.PFPB_L]: 0.097,
  [PerfluorocarbonsSlopeTechnologies.PFPB_M]: 0.057,
  [PerfluorocarbonsSlopeTechnologies.CWPB]: 0.121,
  [PerfluorocarbonsSlopeTechnologies.SWPB]: 0.28,
  [PerfluorocarbonsSlopeTechnologies.VSS]: 0.086,
  [PerfluorocarbonsSlopeTechnologies.HSS]: 0.077,
};

export const OvervoltagEmissionFactors = {
  [PerfluorocarbonsOvervoltageTechnologies.CWPB]: 1.16,
  [PerfluorocarbonsOvervoltageTechnologies.SWPB]: 3.65,
};

export const OvervoltageC2F6ToCF4Ratios = {
  [PerfluorocarbonsOvervoltageTechnologies.CWPB]: 0.121,
  [PerfluorocarbonsOvervoltageTechnologies.SWPB]: 0.252,
};
