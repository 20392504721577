import { useQuery } from 'react-query';
import {
  FieldAnswerCommentsInSchema,
  FieldAnswerCommentsOutSchema,
  Qv3EnumSchema,
} from './types';
import { client } from 'src/utils/api-client';
import { QueryOptions, useResourceController } from 'src/common/hooks';

// eslint-disable-next-line import/prefer-default-export
export const useQv3Enums = (id?: number) => {
  const queryKey = ['qv3Enums', { id }];
  const url = `/api-questionnaires/enums/${id}`;
  const query = useQuery<Qv3EnumSchema>(
    queryKey,
    () => client.get(url).then((res) => res.data),
    { enabled: !!id }
  );
  const qv3Enum = query.data || { name: null, default: null, options: [] };
  return { qv3Enum, ...query };
};

export const useFieldAnswerComments = (
  queryParams: Partial<FieldAnswerCommentsOutSchema> = {},
  queryOptions:
    | QueryOptions<FieldAnswerCommentsOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    FieldAnswerCommentsInSchema,
    FieldAnswerCommentsOutSchema
  >(
    `/api-questionnaires/field-answer-comments`,
    ['qv3FieldAnswerComments', queryParams],
    queryParams,
    queryOptions
  );
