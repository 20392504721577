import { t } from 'i18next';
import {
  useEsgStakeholdersAnswersAnalytics,
  useEsgSubTopic,
} from 'src/Esg/Dmav2/hooks';
import { useEsgReports } from 'src/Esg/hooks';

//eslint-disable-next-line import/prefer-default-export
export const useBarChartData = (reportId: number) => {
  const { _instance: report } = useEsgReports({ id: reportId });
  const { analytics } = useEsgStakeholdersAnswersAnalytics(report?.esg_dma_id);
  const { subTopicsMap } = useEsgSubTopic();

  const labels: string[] = [];
  const positiveScores: number[] = [];
  const negativeScores: number[] = [];

  if (!analytics)
    return {
      labels,
      positiveScores,
      negativeScores,
      chartData: null,
      chartOptions: null,
    };

  analytics.summary.forEach((surveySummary) => {
    labels.push(subTopicsMap[surveySummary.esg_dma_sub_topic_id]?.description);
    positiveScores.push(surveySummary.positive_avg ?? 0);
    negativeScores.push(surveySummary.negative_avg ?? 0);
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: t(
          'esg.dma.stakeholderAnalytics.dashboard.barchart.positiveImpact'
        ),
        data: positiveScores,
        backgroundColor: '#A1E4C8',
      },
      {
        label: t(
          'esg.dma.stakeholderAnalytics.dashboard.barchart.negativeImpact'
        ),
        data: negativeScores,
        backgroundColor: '#FF9292',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          maxRotation: 40,
          font: {
            size: 12,
            weight: 'bold',
          },
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
      },
    },
  };

  return { labels, positiveScores, negativeScores, chartData, chartOptions };
};
