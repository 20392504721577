import { useMemo } from 'react';
import {
  EsgDmaStakeholdersAnswersAnalyticsSchema,
  EsgDmaSubtopicOutSchema,
} from 'src/Esg/Dmav2/types';

export type SortType = 'positive' | 'negative';

//eslint-disable-next-line import/prefer-default-export
export const useSortedAnalyticsData = (
  subTopics: EsgDmaSubtopicOutSchema[] | undefined,
  analytics: EsgDmaStakeholdersAnswersAnalyticsSchema,
  sort: SortType
) =>
  useMemo(() => {
    const newData = [...analytics.summary];
    if (!analytics || !subTopics) return [];
    if (sort === 'positive') {
      newData.sort((a, b) => (b.positive_avg ?? 0) - (a.positive_avg ?? 0));
    } else if (sort === 'negative') {
      newData.sort((a, b) => (b.negative_avg ?? 0) - (a.negative_avg ?? 0));
    }
    return newData;
  }, [sort, analytics, subTopics]);
