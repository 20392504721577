import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';
import { ImageSlider } from '../components/ImageSlider';
import { generateImageUrls } from '../utils';
import LightTooltip from 'src/components/LightTooltip';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Analytics } from '@mui/icons-material';

export default function Stage6() {
  const { t, language: l } = useLanguage({ keyPrefix: 'esg.dma' });
  return (
    <DmaStage title={t('stagesV2.dualRelevanceMatrix.title')}>
      <DmaStep
        title={t('stagesV2.dualRelevanceMatrix.steps.1')}
        videoComponent={<ImageSlider images={generateImageUrls(l, 8, 3)} />}
      />
      <DmaStep
        title={t('stagesV2.dualRelevanceMatrix.steps.2')}
        navigateToComponent={
          <LightTooltip title={t('step.analyticsButton.toggle')}>
            <Link to="./matrix">
              <IconButton>
                <Analytics />
              </IconButton>
            </Link>
          </LightTooltip>
        }
      />
      <DmaStep title={t('stagesV2.dualRelevanceMatrix.steps.3')} />
      <DmaStep
        title={t('stagesV2.dualRelevanceMatrix.steps.4')}
        navigateTo="../../questionnaires/BP-2"
        substep
      />
      <DmaStep
        title={t('stagesV2.dualRelevanceMatrix.steps.5')}
        navigateTo="../../questionnaires/GOV-4"
        substep
      />
      <DmaStep
        title={t('stagesV2.dualRelevanceMatrix.steps.6')}
        navigateTo="../../questionnaires/IRO-1"
        substep
      />
      <DmaStep
        title={t('stagesV2.dualRelevanceMatrix.steps.7')}
        navigateTo="../../questionnaires/IRO-2"
        substep
      />
      <DmaStep
        title={t('stagesV2.dualRelevanceMatrix.steps.8')}
        navigateTo="../../questionnaires/SBM-3"
        substep
      />
    </DmaStage>
  );
}
