import { timeConverter } from 'src/utils/timeConverter';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from './interfaces';
import { useAppSelector } from 'src/redux-file/hooks';
import { ReportingFreq } from 'src/redux-file/interfaces';

// eslint-disable-next-line import/prefer-default-export
export const GHG_TABLE_ROW_COLORSCALE = [
  '#79B52C',
  '#944D29',
  '#81CdFD',
  '#3C88B5',
  '#D7A7A9',
  '#FDC342',
  '#AD7A50',
  '#5C5E6F',
  '#C9B620',
  '#276E6D',
  '#E6C7AE',
  '#647A91',
  '#91E0D2',
  '#823E40',
  '#9ECA64',
  '#CF6367',
  '#7D6020',
  '#8DA247',
];

export const getColorFromNumber = (number: number) => {
  const index = number % GHG_TABLE_ROW_COLORSCALE.length;
  return GHG_TABLE_ROW_COLORSCALE[index];
};
export const getRandomColor = () =>
  GHG_TABLE_ROW_COLORSCALE[
    Math.floor(Math.random() * GHG_TABLE_ROW_COLORSCALE.length)
  ];

export function getPeriodDate(reportingFrequency: ReportingFreq) {
  const { selectedDate } = useAppSelector((state) => state.app);
  return timeConverter(selectedDate || new Date(), reportingFrequency);
}

export const questionForGoodType: Record<
  GOODS_AND_SERVICES_OR_CAPITAL_GOODS,
  string
> = {
  [GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES]: 'question1',
  [GOODS_AND_SERVICES_OR_CAPITAL_GOODS.CAPITAL_GOODS]: 'question2',
};
