import { ProductPlatform } from '../utils/api.interfaces';

export const helpCenterPdfs: { [key in ProductPlatform]: string } = {
  [ProductPlatform.Co2]: 'GHG-123.pdf',
  [ProductPlatform.Esg]: 'ESG.pdf',
  [ProductPlatform.Lca]: 'LCA.pdf',
  [ProductPlatform.Cbam]: 'CBAM.pdf', // TODO: Add CBAM pdf
};

export const dateFormat = 'yyyy-MM-dd';
export const dateTimeFormat = 'yyyy-MM-dd HH:mm';

export enum LocalStorageKeys {
  LOCAL_LANGUAGE = 'localLanguage', // language cached by i18next
  LANGUAGE_SYNC_USER_ID = 'languageSyncUserId', // user id of last language sync
}
