import React from 'react';
import { useEsgStakeholderSurveyMetadata } from '../hooks';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';

export default function StakeholderSurvey() {
  const { metadata, isError } = useEsgStakeholderSurveyMetadata();
  React.useEffect(() => {
    if (!!metadata.token)
      window.location.href = `/stakeholders/${metadata.token}/intro`;
  }, [metadata.token]);
  if (isError) return <SomethingWentWrong />;
  return <PageLoading />;
}
