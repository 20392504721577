import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

import { UniversalField } from '../types';
import { getThemeColors } from '../../../helpers';
import { StyledErrorMessage } from '../../index.styles';
import ImprovedCheckbox from '../shared/ImprovedCheckbox';

export default function CheckboxMultiselect(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const { required, fieldoption_set: options, theme_color: themeColor } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: string[]) => {
    // required field - validation error on false
    if (required && !_value?.length) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  const arrayValue = Array.isArray(value) ? value : [];

  // local handle change injects frontend validation
  const handleChange = (optionKey: string) => {
    const newValue = arrayValue.includes(optionKey)
      ? arrayValue.filter((optKey) => optKey !== optionKey)
      : [optionKey, ...arrayValue];

    _validate(newValue);
    onChange(newValue);
  };

  const themeColors = getThemeColors(themeColor);

  return (
    <div style={{ marginBottom: '16px' }}>
      {options?.map((option) => {
        const checked: boolean = arrayValue.includes(String(option.key));
        return (
          <FormGroup key={option.id} sx={{ position: 'relative' }}>
            <FormControl error={!!errorMessage}>
              <FormControlLabel
                disabled={!!disabled}
                control={
                  <ImprovedCheckbox
                    checked={checked}
                    handleChange={() => handleChange(String(option.key))}
                    error={!!errorMessage}
                    themeColors={themeColors}
                    disabled={disabled}
                  />
                }
                label={<Typography>{option.label}</Typography>}
              />
            </FormControl>
          </FormGroup>
        );
      })}
      {!!errorMessage && (
        <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
      )}
    </div>
  );
}
