import React from 'react';
import { TableCell, Typography } from '@mui/material';

import colors from 'src/theme/colors';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { StyledScore } from './styles';
import { EsgDmaRiskOpportunityOutSchema } from 'src/Esg/Dmav2/types';
import { formatScore } from '../utils';

interface Props {
  riskOpportunity: EsgDmaRiskOpportunityOutSchema;
  color?: string;
}

export default function FinancialScore(props: Props) {
  const { color, riskOpportunity } = props;

  return (
    <>
      <TableCell
        rowSpan={3}
        style={{ backgroundColor: colors.backgroundInput }}
        align="center"
      >
        <StyledScore
          style={{ backgroundColor: color ?? colors.backgroundGreyDark }}
        >
          <Typography variant="subtitle2">
            {formatScore(riskOpportunity.financial_score)}
          </Typography>
        </StyledScore>
      </TableCell>
      <TableCell
        rowSpan={3}
        style={{ backgroundColor: colors.backgroundInput }}
        align="center"
      >
        {riskOpportunity.is_important ? (
          <CheckCircle style={{ color: color ?? colors.esgDark }} />
        ) : (
          <Cancel style={{ color: colors.error }} />
        )}
      </TableCell>
    </>
  );
}
