import * as Sentry from '@sentry/react';

import EmptyField from './EmptyField';
import SimpleTextField from './SimpleTextField';
import DecimalTextField from './DecimalTextField';
import DecimalWithUnitHorizontal from './DecimalWithUnitHorizontal';
import SimpleSelectField from './SimpleSelect';
import CheckboxMultiselect from './CheckboxMultiselect';
import RadioGroup from './RadioGroup';
import Rating from './Rating';
import ToggleButtonGroupField from './ToggleButtonGroup';
import SimpleCheckbox from './SimpleCheckbox';
import File from './File';

// eslint-disable-next-line import/prefer-default-export
export const getFieldComponent = (type: string) => {
  switch (type) {
    case 'empty':
      return EmptyField;
    case 'simple-text-field':
      return SimpleTextField;
    case 'decimal-text-field':
      return DecimalTextField;
    case 'decimal-with-unit-select-horizontal':
      return DecimalWithUnitHorizontal;
    case 'simple-checkbox':
      return SimpleCheckbox;
    case 'simple-select':
      return SimpleSelectField;
    case 'checkbox-multiselect':
      return CheckboxMultiselect;
    case 'radio-group':
      return RadioGroup;
    case 'rating':
      return Rating;
    case 'toggle-button-group':
      return ToggleButtonGroupField;
    case 'simple-file-picker':
      return File;
    case 'multi-file-picker':
      return File;

    default:
      Sentry.captureException(`${type} is unknown field type.`);
      return SimpleTextField;
  }
};
