import { Close } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import React from 'react';

interface Props {
  value: any;
  onDelete: () => void;
}

export default function EntityStage(props: Props) {
  const { value, onDelete } = props;
  return (
    <TextField
      InputProps={{
        readOnly: true,
        endAdornment: (
          <IconButton size="small" sx={{ mr: '-10px' }} onClick={onDelete}>
            <Close fontSize="small" />
          </IconButton>
        ),
      }}
      fullWidth
      size="small"
      value={value ?? ''}
      sx={{ mb: '10px' }}
    />
  );
}
