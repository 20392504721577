import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { EsgDmaTopicHierarchySchema } from '../../types';
import SubTopicRow from './SubTopicRow';

interface Props {
  topic: EsgDmaTopicHierarchySchema;
}

export default function TopicRow(props: Props) {
  return (
    <>
      <TableRow>
        <TableCell rowSpan={props.topic.subtopics.length + 1}>
          {props.topic.name}
        </TableCell>
      </TableRow>
      {props.topic.subtopics.map((subTopic) => (
        <SubTopicRow subTopic={subTopic} key={subTopic.id} />
      ))}
    </>
  );
}
