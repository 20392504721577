import { Box, Button, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { toast } from 'react-toastify';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import { useAllCountries, useCbamOrganization } from 'src/common/hooks';
import { CbamActorInSchema, CbamActorOutSchema } from '../types';
import { useTranslation } from 'react-i18next';
import { useActors } from '../hooks';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import {
  StyledFormControl,
  StyledSectionHeader,
  StyledSectionDivider,
  StyledTextField,
} from '../styles';
import _ from 'lodash';

interface Props {
  id?: Number;
  onSaved?: () => void;
}

export default function ActorForm(props: Props) {
  const { id, onSaved } = props;
  const { id: activeOrganizationId } = useCbamOrganization();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.actors',
  });

  const { data, create, update } = useActors();
  const { data: countries } = useAllCountries();

  const actor = data?.find((i) => i.id === id);

  const [form, setForm] = React.useState<
    CbamActorOutSchema | CbamActorInSchema
  >({
    cbam_organization_id: activeOrganizationId,
    identifier: '',
    name: '',

    country: '',
    sub_division: '',
    city: '',
    street_name: '',
    street_name_additional_line: '',
    street_number: '',
    post_code: '',
    po_box: '',
  });

  const [errors, setErrors] = React.useState<FormErrors>({});

  const [isModified, setIsModified] = React.useState(false);

  const handleFormChange = (key: string, value: string | null) => {
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  React.useEffect(() => {
    if (actor) setForm(actor);
  }, [actor]);

  const save = !actor ? create.mutateAsync : update.mutateAsync;

  const isError = (fieldName: keyof CbamActorInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamActorInSchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  const handleSaveClick = () => {
    setIsModified(false);
    save(form)
      .then(() => onSaved?.())
      .catch((err: any) => {
        setIsModified(true);
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });
  };

  return (
    <Box>
      <NavigationBlockerModal shouldBlock={isModified} />
      <StyledPanel>
        <StyledSectionHeader>{t('generalInformation')}</StyledSectionHeader>
        {['identifier', 'name'].map((field) => (
          <StyledTextField
            key={field}
            label={t(`form.${field}`)}
            value={form[field as keyof CbamActorInSchema]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange(field, e.currentTarget.value);
            }}
            required={requiredFields.includes(field as keyof CbamActorInSchema)}
            error={isError(field as keyof CbamActorInSchema)}
            helperText={errorText(field as keyof CbamActorInSchema)}
          />
        ))}
        <StyledSectionDivider />
        <StyledSectionHeader>{t('address')}</StyledSectionHeader>
        <StyledFormControl required>
          <InputLabel id="country">{t('form.country')}</InputLabel>
          <Select
            key={form.country}
            labelId="country"
            label={t('form.country')}
            value={form.country}
            onChange={(e) => handleFormChange('country', e.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.iso_code} value={country.iso_code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {[
          'sub_division',
          'city',
          'street_name',
          'street_name_additional_line',
          'street_number',
          'post_code',
          'po_box',
        ].map((field) => (
          <StyledTextField
            key={field}
            label={t(`form.${field}`)}
            value={form[field as keyof CbamActorInSchema]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFormChange(field, e.currentTarget.value);
            }}
            required={requiredFields.includes(field as keyof CbamActorInSchema)}
            error={isError(field as keyof CbamActorInSchema)}
            helperText={errorText(field as keyof CbamActorInSchema)}
          />
        ))}
      </StyledPanel>
      <br />
      <br />
      <Button
        sx={{ float: 'right' }}
        onClick={handleSaveClick}
        disabled={!isModified || Object.keys(validateForm(form, t)).length > 0}
      >
        {t('save')}
      </Button>
    </Box>
  );
}
