import React from 'react';
import { RowQuestionField, RowType } from '../../types';
import { Li } from '../styles';

import Row from './Row';
import { Trans } from 'react-i18next';
import { hasValue } from 'src/views/components/QuestionnaireV3/Summary/SummaryTable/utils';

type FieldFormatOptions = {
  showBooleanAsYesNo?: boolean;
  prependLabel?: boolean;
};

const mapFieldAnswer = (
  field: RowQuestionField,
  options: FieldFormatOptions = {}
) => {
  const fieldValue = Object.values(field.answer)[0];
  // no answer
  if (!hasValue(fieldValue)) return null;

  let value: React.ReactNode = `${fieldValue}`;
  let prependLabel = false;

  if (field.type === 'boolean') {
    if (options.showBooleanAsYesNo) {
      value = (
        <Trans
          i18nKey={
            fieldValue
              ? 'questionnaireV3.field.yes'
              : 'questionnaireV3.field.no'
          }
        />
      );
    } else {
      value = field.label;
    }
  } else if (field.type === 'multiselect') {
    prependLabel = true;
    value = fieldValue.join(', ') || '-';
  } else if (['text', 'decimal'].includes(field.type)) {
    prependLabel = true;
  }

  const showLabel = (options.prependLabel || prependLabel) && field.label;

  return (
    <Li key={field.key}>
      {showLabel ? <span>{field.label}: </span> : null}
      {value}
    </Li>
  );
};

export const parseAllowMany = (fields: RowQuestionField[]) => {
  // if there is no allow_many, return the fields as is
  // if there is allow_many, parse the fields into individual rows

  const result: RowQuestionField[] = [];

  // find the max length of the answer
  const maxLength = Math.max(
    ...fields.map((field) => Object.keys(field.answer).length)
  );

  // for each answer index, create a new row
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < maxLength; i++) {
    fields.forEach((field) => {
      const idx: number = +Object.keys(field.answer)[i];
      if (field.answer[idx] !== undefined) {
        result.push({ ...field, answer: { [+idx]: field.answer[+idx] } });
      }
    });
  }
  return result;
};

// each question is mapped into an individual row
export const mapRowsByQuestion = (data: RowType[]) => {
  return data.map((question) => {
    const fields = parseAllowMany(question.fields).map((field) =>
      mapFieldAnswer(field, { prependLabel: true, showBooleanAsYesNo: true })
    );
    return (
      <Row
        key={`${question.esg_questionnaire_key}-${question.question_key}`}
        keyText={question.esg_questionnaire_key}
        title={question.question_title}
        answer={fields}
      />
    );
  });
};

// each field is mapped into an individual row
export const mapRowsByField = (data: RowType[]) => {
  return data.flatMap((question) => {
    return parseAllowMany(question.fields).map((field) => {
      const answer = mapFieldAnswer(field, {
        prependLabel: false,
        showBooleanAsYesNo: true,
      });
      return (
        <Row
          key={`${question.question_key}-${field.key}`}
          keyText={question.question_title}
          title={field.title}
          answer={answer}
        />
      );
    });
  });
};
