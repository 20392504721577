import { TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialogTitle = styled(Typography)`
  color: #38414f;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
  padding: 24px;
  padding-bottom: 12px;
`;

export const StyledDialogSubtitle = styled(Typography)`
  color: #38414f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.48px;
  padding: 24px;
  padding-top: 12px;
`;

export const StyledTableCell = styled(TableCell)`
  padding: 14px;
`;
