import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import theme from '../theme';
import { themeColors as _themeColors } from '../views/components/QuestionnaireV3/helpers';
import themeColors from 'src/theme/colors';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { dateFormat } from 'src/common/constants';

const esgTheme = createTheme(
  deepmerge(theme, {
    palette: {
      primary: {
        main: themeColors.esgMain,
        contrastText: themeColors.pureWhite,
      },
      secondary: {
        main: themeColors.pureWhite,
        contrastText: themeColors.esgMain,
      },
      text: {
        primary: themeColors.esgMain,
        secondary: themeColors.esgDarkGrey,
      },
      main: themeColors.esgMain,
      mainDark: themeColors.esgMainDark,
      mainLight: themeColors.esgMainLight,
      mainLighter: themeColors.esgMainLighter,
    },
    components: {
      // move to main theme: src/theme/index.ts
      MuiDatePicker: {
        defaultProps: {
          format: dateFormat,
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.32px',
            color: '#38414F',
            borderRadius: '16px',
            backgroundColor: '#FFF',
            textTransform: 'capitalize',

            '&.Mui-selected': {
              backgroundColor: '#38414F',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#49525F',
              },
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: '16px',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            gap: '10px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15px',
            textTransform: 'none',
            padding: '16px',
          },
          primary: {
            backgroundColor: themeColors.esgMain,
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15px',
            textTransform: 'none',
            padding: '10px 30px',
            color: themeColors.pureWhite,
            '&:hover': {
              backgroundColor: themeColors.esgMainLight,
            },
            '&:pressed': {
              backgroundColor: themeColors.esgMainLighter,
            },
          },
          secondary: {
            border: `1px solid ${themeColors.disabledGraphics}`,
            backgroundColor: themeColors.pureWhite,
            alignSelf: 'center',
            color: themeColors.esgDark,
            '&:hover': {
              backgroundColor: themeColors.hover,
            },
            '&:pressed': {
              backgroundColor: themeColors.pressed,
            },
            '& .MuiButton-endIcon': {
              color: themeColors.esgMain,
              fontSize: '18px',
              marginLeft: '3px',
            },
          },
          sizeSmall: {
            height: '32px',
            fontSize: '13px',
          },
          sizeMedium: {
            height: '40px',
            fontSize: '15px',
          },
          sizeLarge: {
            height: '48px',
            fontSize: '16px',
          },
          textSecondary: {
            color: themeColors.esgMain,
            border: `1px solid ${themeColors.strokeInputs}`,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
            '&:hover': {
              backgroundColor: themeColors.esgMainLight,
            },
            '&.active': {
              backgroundColor: themeColors.pureWhite,
              color: themeColors.esgDark,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: themeColors.esgDark,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '8px',
          },
        },
      },
    },
  })
);

export default esgTheme;
