import React from 'react';

import { UniversalField } from '../types';
import { useTranslation } from 'react-i18next';
import { HOVER_COLOR } from '../../../helpers';
import NumberField from 'src/components/NumberField';

export default function SimpleTextField(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const { label, placeholder, required } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: string) => {
    // required field
    if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (
    _: string | number | null,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: _value } = e.target;
    _validate(_value);
    onChange(!!_value ? _value : null);
  };

  return (
    <NumberField
      fullWidth
      value={value ?? ''}
      label={label}
      onChange={handleChange}
      error={!!errorMessage}
      helperText={errorMessage}
      disabled={disabled}
      placeholder={placeholder}
      sx={{
        mb: '32px',
        '& .MuiOutlinedInput-root': {
          backgroundColor: !!value ? HOVER_COLOR : 'inherit',
        },
      }}
    />
  );
}
