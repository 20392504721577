import React from 'react';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

export default function Autocomplete({
  options,
  value,
  onChange,
  handleClearClick = () => {},
  label,
  placeholder,
  sx,
  multiple,
  errorText,
  freeSolo = false,
  disableClearable = false,
}: {
  options: any[] | undefined;
  value: string | string[] | null;
  onChange: (value: string | null) => void;
  handleClearClick?: () => void;
  label?: string;
  placeholder?: string;
  sx?: { [key: string]: any };
  multiple?: boolean;
  errorText?: React.ReactNode | null;
  freeSolo?: boolean;
  disableClearable?: boolean;
}) {
  const { t } = useTranslation();
  const _options = (options || []).map((item: any) => item.value);
  const isError = errorText !== undefined;
  const helperText = typeof errorText === 'string' ? t(errorText) : undefined;
  return (
    <MuiAutocomplete
      key={options?.length || 'autocomplete'}
      freeSolo={freeSolo}
      multiple={multiple || false}
      fullWidth
      loading={options === undefined}
      options={_options}
      value={value}
      getOptionLabel={(value) =>
        options?.find((item: any) => item.value === value)?.label || ''
      }
      onChange={(_, newValue) => onChange(newValue || null)}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={isError}
          helperText={helperText}
        />
      )}
      sx={sx}
    />
  );
}
