import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Step5Table from './Step5Table';
import { hideSidebarOnMount } from '../utils';

export default function Step5() {
  const navigate = useNavigate();
  hideSidebarOnMount();

  return (
    <Box>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#38414f' }} />
        </IconButton>
        <Typography variant="h1">
          <Trans i18nKey="esg.dma.step5TopicEvaluationV2.title" />
        </Typography>
      </div>
      <br />
      <Typography variant="body2">
        <Trans i18nKey="esg.dma.step5TopicEvaluationV2.subtitle01" />
        <br />
        <Trans i18nKey="esg.dma.step5TopicEvaluationV2.subtitle02" />
        <br />
        <Trans i18nKey="esg.dma.step5TopicEvaluationV2.subtitle03" />
        <br />
        <br />
        <Trans i18nKey="esg.dma.step5TopicEvaluationV2.attention" />
      </Typography>
      <br />
      <Step5Table />
    </Box>
  );
}
