import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { CbamReportInSchema } from '../types';
import { TFunction } from 'react-i18next';

export type FormErrors = SchemaErrors<CbamReportInSchema>;

export const requiredFields: (keyof CbamReportInSchema)[] = [
  'name',
  'period',
  'signature_place',
  'signature_name',
  'signature_position',
  'signature_global_data_confirmation',
  'signature_use_of_data_confirmation',
  'declarant_id',
];

export const validateForm = (
  data: CbamReportInSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder.checkTruthy(requiredFields, t('validation.required')).build();
};
