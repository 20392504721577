import React from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { useEsgConsolidationTableData } from '../../hooks';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { ConsolidationTableDimensionSchema } from '../../types';
import CustomSelect from 'src/components/CustomSelect';

interface Props {
  tableId: number;
  period: string;
  selectedDimensions: Array<string | number | null>;
  setSelectedDimensions: (_value: Array<string | number | null>) => void;
}

export default function DimensionsSelectors(props: Props) {
  const { tableId, period, selectedDimensions, setSelectedDimensions } = props;
  const {
    data: consolidationTableData,
    isError,
    isSuccess,
  } = useEsgConsolidationTableData(tableId, period);

  const updateDimensionValue = (
    newValue: string | number | null,
    index: number
  ) => {
    const newDim = [...selectedDimensions];
    newDim[index] = newValue;
    setSelectedDimensions(newDim);
  };

  const dimensionPossibleOptions = (index: number) => {
    const possibleValues = new Set<string | number | null>();
    consolidationTableData?.rows.forEach((row) => {
      possibleValues.add(row.table_dimensions_values[index]);
    });
    return Array.from(possibleValues);
  };

  if (isError) {
    return <SomethingWentWrong />;
  }
  if (!isSuccess || !consolidationTableData) {
    return <PageLoading />;
  }

  const menuItemLabel = (
    dimension: ConsolidationTableDimensionSchema,
    value: string | number | null
  ) => {
    return (
      dimension.options.find((option) => option.key === value)?.label ??
      value ??
      '-'
    );
  };

  if (!consolidationTableData?.table_dimensions?.length) return null;

  return (
    <TableRow sx={{ width: '100%' }}>
      <TableCell colSpan={2}>
        <Box display="flex" justifyContent="flex-end" alignContent="center">
          {consolidationTableData.table_dimensions.map((dimension, index) => (
            <Box mt="4px" mb="4px" ml="16px" width="250px">
              <CustomSelect
                key={`dimension_form_control_key_${index}`}
                options={dimensionPossibleOptions(index).map((value) => ({
                  value,
                  label: String(menuItemLabel(dimension, value)),
                  selectedLabel: `${dimension.label}: ${menuItemLabel(
                    dimension,
                    value
                  )}`,
                }))}
                value={selectedDimensions[index]}
                onChange={(value) => updateDimensionValue(value, index)}
                fullWidth
              />
            </Box>
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
}
