import { Delete, Mail, MoreVert } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useIndicatorsResponsibilites } from 'src/Esg/hooks';
import { IndicatorResponsibility } from 'src/Esg/types';
import { useOrganizationMembers } from 'src/Superadmin/hooks';

interface Props {
  responsibility: IndicatorResponsibility;
}

export default function ResponsibilityMenu(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.administrationPanel',
  });
  const { responsibility } = props;
  const reportId = responsibility.report_id;
  const responsibilities = useIndicatorsResponsibilites(reportId);
  const { organizationMembers } = useOrganizationMembers();
  const responsibilityUser = organizationMembers.find(
    (member) => member.id === responsibility.app_user_id
  );
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchor(e.currentTarget);

  const handleDeleteClick = () => {
    setAnchor(null);
    responsibilities.delete.mutate(responsibility.id);
  };

  const handleNotifyClick = () => {
    setAnchor(null);

    if (!responsibilityUser?.email)
      return toast.error(t('toasts.emailNotProvided') as string);

    return responsibilities.notify.mutateAsync(responsibility.id).then(() =>
      toast.success(
        t('toasts.responsibilityNotified', {
          email: responsibilityUser.email,
        }) as string
      )
    );
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchor} open={!!anchor} onClose={() => setAnchor(null)}>
        <MenuItem onClick={handleNotifyClick}>
          <ListItemIcon>
            <Mail />
          </ListItemIcon>
          <ListItemText>{t('common.notify')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>{t('common.delete')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
