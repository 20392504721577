import React from 'react';

import { Checkbox, TableCell, TableRow } from '@mui/material';
import {
  EsgDmaStakeholderQualityAnswerInSchema,
  EsgDmaSubTopicHierarchySchema,
} from '../../types';
import { useParams } from 'react-router-dom';
import { useDmaStakeholderQualityAnswers } from '../../hooks';
import EditModal from 'src/Esg/Dma/Stakeholders/SelectingStakeholders/SelectingStakeholdersTable/EditModal';
import RadioField from 'src/Cbam/ProductionProcess/Form/components/RadioField';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@mui/icons-material';
import { StyledFlex } from 'src/components/StyledFlex';
import { useEsgReports } from 'src/Esg/hooks';

interface Props {
  subTopic: EsgDmaSubTopicHierarchySchema;
}

export default function SubTopicRow(props: Props) {
  const { t } = useTranslation();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _data, create, update } = useDmaStakeholderQualityAnswers(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  if (!report) return null;

  const row = _data.find((r) => r.subtopic_id === props.subTopic.id);

  const initialAnswer: EsgDmaStakeholderQualityAnswerInSchema = {
    esg_dma_id: report?.esg_dma_id,
    subtopic_id: props.subTopic.id,
    issue_raised: true,
    impact_materiality: false,
    financial_materiality: false,
    comment: '',
  };

  const qualityAnswer = row ?? initialAnswer;

  const handleChange = (key: string, value: number | string | boolean) => {
    if (!!row) {
      update.mutate({ ...qualityAnswer, [key]: value, id: row.id });
    } else {
      create.mutate({ ...qualityAnswer, [key]: value });
    }
  };

  return (
    <TableRow>
      <TableCell>{props.subTopic.description}</TableCell>
      <TableCell>
        <RadioField
          label={t('yes')}
          checked={!!row && qualityAnswer.issue_raised}
          onClick={() => handleChange('issue_raised', true)}
        />
        <RadioField
          label={t('no')}
          checked={!qualityAnswer.issue_raised}
          onClick={() => handleChange('issue_raised', false)}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={qualityAnswer.impact_materiality}
          onChange={(e) => handleChange('impact_materiality', e.target.checked)}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={qualityAnswer.financial_materiality}
          onChange={(e) =>
            handleChange('financial_materiality', e.target.checked)
          }
        />
      </TableCell>
      <TableCell>
        <StyledFlex style={{ justifyContent: 'start' }}>
          <EditModal
            title={t('esg.dma.qualityAnswers.editModal.title')}
            subtitleTranslationKey="esg.dma.qualityAnswers.editModal.subtitle"
            value={qualityAnswer.comment}
            onChange={(value) => handleChange('comment', value)}
          />
          {!!qualityAnswer.comment && <CheckCircle color="success" />}
        </StyledFlex>
      </TableCell>
    </TableRow>
  );
}
