import React from 'react';
import { useParams } from 'react-router-dom';

import { EsgDmaValueChainEntityInSchema } from 'src/Esg/Dmav2/types';
import SaveButton from '../components/SaveButton';
import EntityForm from './Form';
import { useEsgDmas } from 'src/Esg/Dmav2/hooks';
import PageLoading from 'src/components/PageLoading';
import { useEsgReports } from 'src/Esg/hooks';

interface Props {
  //eslint-disable-next-line no-unused-vars
  onSave: (data: EsgDmaValueChainEntityInSchema) => void;
}

export default function NewValueChainEntity(props: Props) {
  const { onSave } = props;
  const { reportId } = useParams();
  const { _instance: esgReport } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma } = useEsgDmas(
    { id: esgReport?.esg_dma_id },
    { enabled: !!esgReport }
  );

  const [form, setForm] = React.useState<
    Partial<EsgDmaValueChainEntityInSchema>
  >({
    name: undefined,
    essential_products: undefined,
    essential_services: undefined,
    collaboration_details: undefined,
  });

  if (!dma) return <PageLoading />;

  const handleFormChange = (fieldName: string, value: any) =>
    setForm({ ...form, [fieldName]: value });

  const handleSaveClick = () => onSave({ ...form, esg_dma_id: dma.id });

  const isValid = form.name;

  return (
    <>
      <EntityForm form={form} onFieldChange={handleFormChange} />
      <SaveButton disabled={!isValid} onSave={handleSaveClick} />
    </>
  );
}
