import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { LcaDiagramSchema, LcaProductOutSchema, Pagination } from './types';
import { ProductImpactOutSchema } from 'src/Lca/LCADiagram/Diagram/index.types';
import { useAppSelector } from 'src/redux-file/hooks';
import { useImage, useLcaOrganization } from 'src/common/hooks';

// eslint-disable-next-line import/prefer-default-export
export const useLcaDiagram = (productId?: number) => {
  const query = useQuery(
    ['lca-diagram', { productId }],
    () =>
      client
        .get<LcaDiagramSchema>(`/web/lca/flowchart/${productId}`)
        .then((response) => response.data),
    { enabled: !!productId }
  );
  const stagesAsOptions = (query.data?.stages || []).map(({ key, name }) => ({
    value: key,
    label: name,
  }));

  return { stagesAsOptions, ...query };
};

export const useProductImpact = (
  productId: number,
  areaId: number | undefined
) => {
  const url = `/web/lca/products/${productId}/impact/${areaId}`;
  const query = useQuery(
    ['lca-diagram', 'calculations', { productId, areaId }],
    () =>
      client.get<ProductImpactOutSchema>(url).then((response) => response.data),
    { enabled: !!areaId }
  );
  return query;
};

export const useProductImpacts = (productId: number) => {
  const url = `/web/lca/products/${productId}/impact`;
  const query = useQuery(
    ['lca-diagram', 'calculations', { productId }],
    () =>
      client
        .get<ProductImpactOutSchema[]>(url)
        .then((response) => response.data),
    { enabled: !!productId }
  );
  return query;
};

export const useEcoinventProLicenceValid = () => {
  const isEcoinventLicenceValid = useAppSelector(
    (state) => state.platform.user?.ecoinventProLicenceIsValid
  );
  return { isEcoinventLicenceValid };
};

export const useLcaProducts = (
  organizationId: number,
  page: number,
  elementsPerPage: number,
  name: string | undefined
) => {
  const url = '/web/lca/products';
  const params = {
    organization_id: organizationId,
    offset: (page - 1) * elementsPerPage,
    limit: elementsPerPage,
    name,
  };
  const queryKey = ['lca', 'products', params];

  const query = useQuery(
    queryKey,
    () =>
      client
        .get<Pagination<LcaProductOutSchema>>(url, { params })
        .then((response) => response.data),
    { enabled: !!organizationId }
  );

  return {
    products: query.data?.items || [],
    count: query.data?.count,
    ...query,
  };
};

export const useLcaImage = (imageId: string) => {
  const organization = useLcaOrganization();
  return useImage(organization.id, imageId);
};
