import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { StyledNumberField } from '../../../styles';
import { usePerfluorocarbons } from 'src/Cbam/hooks';
import {
  CbamPerfluorocarbonsSchema,
  PerfluorocarbonsCalculationMethods,
  PerfluorocarbonsOvervoltageTechnologies,
  PerfluorocarbonsSlopeTechnologies,
} from 'src/Cbam/types';
import RadioField from '../components/RadioField';
import { useChangeTracker } from '../components/CounterProvider';
import { useTranslation } from 'react-i18next';
import { FormErrors, isCustomFactor, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { toast } from 'react-toastify';
import InfoPanel from 'src/Cbam/components/InfoPanel';
import {
  OvervoltageC2F6ToCF4Ratios,
  OvervoltagEmissionFactors,
  SlopeC2F6ToCF4Ratios,
  SlopeEmissionFactors,
} from './consts';

interface Props {
  id: number;
}

export default function Perfluorocarbons(props: Props) {
  const { id } = props;
  const { isModified, setIsModified } = useChangeTracker();
  const { data: perfluorocarbons, update } = usePerfluorocarbons(id);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.productionProcess.perfluorocarbons',
  });

  const initialForm = {
    production_process_id: id,
    method: null,

    slope_technology: null,
    anode_effect_minutes: null,

    overvoltage_technology: null,
    anode_effect_overvoltage: null,
    average_current_efficiency_percentage: null,

    collection_efficiency_percentage: null,
    emission_factor: null,
    c2f6_to_cf4_ratio: null,
  };

  const [form, setForm] =
    React.useState<CbamPerfluorocarbonsSchema>(initialForm);

  const [errors, setErrors] = React.useState<FormErrors>({});

  const handleFormChange = (key: string, value: any) => {
    setIsModified(true);
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const handleMethodChange = (value: PerfluorocarbonsCalculationMethods) => {
    setIsModified(true);
    const newForm = {
      ...initialForm,
      method: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const handleSlopeTechnologyChange = (
    value: PerfluorocarbonsSlopeTechnologies
  ) => {
    setIsModified(true);
    const emissionFactor =
      value === PerfluorocarbonsSlopeTechnologies.CUSTOM
        ? form.emission_factor
        : SlopeEmissionFactors[value];
    const c2f6ToCf4Ratio =
      value === PerfluorocarbonsSlopeTechnologies.CUSTOM
        ? form.c2f6_to_cf4_ratio
        : SlopeC2F6ToCF4Ratios[value];
    const newForm = {
      ...form,
      slope_technology: value,
      emission_factor: emissionFactor,
      c2f6_to_cf4_ratio: c2f6ToCf4Ratio,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  const handleOvervoltageTechnologyChange = (
    value: PerfluorocarbonsOvervoltageTechnologies
  ) => {
    setIsModified(true);
    const emissionFactor =
      value === PerfluorocarbonsOvervoltageTechnologies.CUSTOM
        ? form.emission_factor
        : OvervoltagEmissionFactors[value];
    const c2f6ToCf4Ratio =
      value === PerfluorocarbonsOvervoltageTechnologies.CUSTOM
        ? form.c2f6_to_cf4_ratio
        : OvervoltageC2F6ToCF4Ratios[value];
    const newForm = {
      ...form,
      overvoltage_technology: value,
      emission_factor: emissionFactor,
      c2f6_to_cf4_ratio: c2f6ToCf4Ratio,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
  };

  React.useEffect(() => {
    if (perfluorocarbons) setForm(perfluorocarbons);
  }, [perfluorocarbons]);

  const handleSaveClick = () =>
    update
      .mutateAsync(form)
      .then(() => {
        setIsModified(false);
      })
      .catch((err: any) => {
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });

  const isError = (fieldName: keyof CbamPerfluorocarbonsSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamPerfluorocarbonsSchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  return (
    <>
      <Typography variant="h3" sx={{ mb: '24px' }}>
        {t('title')}
      </Typography>
      <InfoPanel>
        <Typography>{t('description')}</Typography>
      </InfoPanel>

      <StyledPanel sx={{ mt: '24px' }}>
        <Typography variant="h5" sx={{ mb: '12px' }}>
          {t('method')}
        </Typography>
        <Box>
          <RadioField
            label={t(`methods.slope`)}
            checked={form.method === PerfluorocarbonsCalculationMethods.SLOPE}
            onClick={() =>
              handleMethodChange(PerfluorocarbonsCalculationMethods.SLOPE)
            }
          />
        </Box>
        <Box>
          <RadioField
            label={t(`methods.overvoltage`)}
            checked={
              form.method === PerfluorocarbonsCalculationMethods.OVERVOLTAGE
            }
            onClick={() =>
              handleFormChange(
                'method',
                PerfluorocarbonsCalculationMethods.OVERVOLTAGE
              )
            }
          />
        </Box>
        {form.method === PerfluorocarbonsCalculationMethods.SLOPE && (
          <>
            <Typography variant="h5" sx={{ mt: '24px', mb: '12px' }}>
              {t('anodeEffectMinutes')}
            </Typography>
            <StyledNumberField
              label={t('form.anode_effect_minutes')}
              value={form.anode_effect_minutes}
              onChange={(e) => handleFormChange('anode_effect_minutes', e)}
              required
              error={isError('anode_effect_minutes')}
              helperText={errorText('anode_effect_minutes')}
            />
            <Typography variant="h5" sx={{ mt: '24px', mb: '12px' }}>
              {t('technology')}
            </Typography>
            {Object.values(PerfluorocarbonsSlopeTechnologies).map(
              (tech: PerfluorocarbonsSlopeTechnologies) => (
                <Box key={tech}>
                  <RadioField
                    label={t(`slopeTechnologies.${tech}`)}
                    checked={form.slope_technology === tech}
                    onClick={() => handleSlopeTechnologyChange(tech)}
                  />
                </Box>
              )
            )}
            <StyledNumberField
              sx={{ mt: '24px' }}
              label={t('slopeCoefficient')}
              disabled={!isCustomFactor(form)}
              value={form.emission_factor}
              onChange={(e) => handleFormChange('emission_factor', e)}
              required
              error={isError('emission_factor')}
              helperText={errorText('emission_factor')}
            />
            <StyledNumberField
              label={t('C2F6ToCF4Ratio')}
              disabled={!isCustomFactor(form)}
              value={form.c2f6_to_cf4_ratio}
              onChange={(e) => handleFormChange('c2f6_to_cf4_ratio', e)}
              required
              error={isError('c2f6_to_cf4_ratio')}
              helperText={errorText('c2f6_to_cf4_ratio')}
            />
          </>
        )}

        {form.method === PerfluorocarbonsCalculationMethods.OVERVOLTAGE && (
          <>
            <Typography variant="h5" sx={{ mt: '24px', mb: '12px' }}>
              {t('anodeEffectOvervoltage')}
            </Typography>
            <StyledNumberField
              label={t('form.anode_effect_overvoltage')}
              value={form.anode_effect_overvoltage}
              onChange={(e) => handleFormChange('anode_effect_overvoltage', e)}
              required
              error={isError('anode_effect_overvoltage')}
              helperText={errorText('anode_effect_overvoltage')}
            />
            <Typography variant="h5" sx={{ mt: '24px', mb: '12px' }}>
              {t('averageCurrentEfficiencyPercentage')}
            </Typography>
            <StyledNumberField
              label={t('form.average_current_efficiency_percentage')}
              value={form.average_current_efficiency_percentage}
              onChange={(e) =>
                handleFormChange('average_current_efficiency_percentage', e)
              }
              required
              error={isError('average_current_efficiency_percentage')}
              helperText={errorText('average_current_efficiency_percentage')}
            />
            <Typography variant="h5" sx={{ mt: '24px', mb: '12px' }}>
              {t('technology')}
            </Typography>
            {Object.values(PerfluorocarbonsOvervoltageTechnologies).map(
              (tech: PerfluorocarbonsOvervoltageTechnologies) => (
                <Box key={tech}>
                  <RadioField
                    key={tech}
                    label={t(`overvoltageTechnologies.${tech}`)}
                    checked={form.overvoltage_technology === tech}
                    onClick={() => handleOvervoltageTechnologyChange(tech)}
                  />
                </Box>
              )
            )}
            <StyledNumberField
              sx={{ mt: '24px' }}
              label={t('overvoltageCoefficient')}
              disabled={!isCustomFactor(form)}
              value={form.emission_factor}
              onChange={(e) => handleFormChange('emission_factor', e)}
              required
              error={isError('emission_factor')}
              helperText={errorText('emission_factor')}
            />
            <StyledNumberField
              label={t('C2F6ToCF4Ratio')}
              disabled={!isCustomFactor(form)}
              value={form.c2f6_to_cf4_ratio}
              onChange={(e) => handleFormChange('c2f6_to_cf4_ratio', e)}
              required
              error={isError('c2f6_to_cf4_ratio')}
              helperText={errorText('c2f6_to_cf4_ratio')}
            />
          </>
        )}
        {isCustomFactor(form) && (
          <>
            <Typography variant="h5" sx={{ mt: '24px', mb: '12px' }}>
              {t('collectionEfficiencyPercentage')}
            </Typography>
            <StyledNumberField
              sx={{ mb: '24px' }}
              label={t('form.collection_efficiency_percentage')}
              value={form.collection_efficiency_percentage}
              onChange={(e) =>
                handleFormChange('collection_efficiency_percentage', e)
              }
              required
              error={isError('collection_efficiency_percentage')}
              helperText={errorText('collection_efficiency_percentage')}
            />
          </>
        )}
        {isModified && (
          <Box sx={{ width: '100%', textAlign: 'right', mt: '12px' }}>
            <Button
              color="primary"
              onClick={handleSaveClick}
              sx={{ minWidth: '200px' }}
              disabled={Object.keys(validateForm(form, t)).length > 0}
            >
              {id ? 'Save' : 'Create'}
            </Button>
          </Box>
        )}
      </StyledPanel>
    </>
  );
}
