import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import { EcoinventMethodGoodData } from '../../types';

export type FormErrors = SchemaErrors<EcoinventMethodGoodData>;

export const requiredFields: (keyof EcoinventMethodGoodData)[] = [
  'quantity',
  'factor_id',
];

export const validateForm = (
  data: EcoinventMethodGoodData,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder.checkTruthy(requiredFields, t('validation.required')).build();
};
