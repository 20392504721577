import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEsgOrganization } from 'src/common/hooks';
import CustomSelect from 'src/components/CustomSelect';
import { NestedRows, TableRow } from './index.types';
import {
  StyledCell,
  StyledHeader,
  StyledRow,
  StyledRowSection,
} from './index.styles';
import { useTopicsTableData } from '../../hooks';
import { getNumericOptions, getProbabilityNumericOptions } from '../../utils';
import EditModal from '../../Stakeholders/SelectingStakeholders/SelectingStakeholdersTable/EditModal';

interface Props {
  reportId: string;
  preliminary: boolean;
}

const ROW_KEY_DELIMETER = ':::';

export default function FinancialTopicsEvaluationTable({
  reportId,
  preliminary,
}: Props) {
  const { t } = useTranslation();
  const { id: organizationId } = useEsgOrganization();

  const { data, updateTableRow } = useTopicsTableData(
    organizationId,
    reportId,
    'financial',
    preliminary
  );

  const rows: NestedRows = (data || []).reduce(
    (rows: NestedRows, item: TableRow) => {
      const uniqueKey = `${item.esg_topic_key}${ROW_KEY_DELIMETER}${item.esg_topic_title}`;

      if (!rows[uniqueKey]) rows[uniqueKey] = [];
      rows[uniqueKey].push(item);
      return rows;
    },
    []
  );

  const NUMERIC_OPTIONS = getNumericOptions(t);
  const PROBABILITY_OPTIONS = getProbabilityNumericOptions(t);

  return (
    <div style={{ width: '100%' }}>
      <StyledRow
        sx={{
          height: '40px',
          marginBottom: '-2px',
          borderBottom: '0px solid #eee',
        }}
      >
        <StyledHeader>&nbsp;</StyledHeader>
        <StyledHeader sx={{ width: '40%', backgroundColor: '#EEE' }}>
          {t('esg.dma.financialTopicsEvaluationTable.columns.risks')}
        </StyledHeader>
        <StyledHeader sx={{ width: '40%', backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.financialTopicsEvaluationTable.columns.possibilities')}
        </StyledHeader>
        {!preliminary && <StyledHeader />}
      </StyledRow>
      <StyledRow sx={{ borderWidth: '2px' }}>
        <StyledCell>
          {t('esg.dma.financialTopicsEvaluationTable.columns.perspecitve')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#EEE' }}>
          {t('esg.dma.financialTopicsEvaluationTable.columns.scale')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#EEE' }}>
          {t('esg.dma.financialTopicsEvaluationTable.columns.probability')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.financialTopicsEvaluationTable.columns.scale')}
        </StyledCell>
        <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
          {t('esg.dma.financialTopicsEvaluationTable.columns.probability')}
        </StyledCell>
        {!preliminary && (
          <StyledCell sx={{ fontWeight: 'bold' }}>
            {t('esg.dma.nonFinancialTopicsEvaluationTable.columns.reasoning')}
          </StyledCell>
        )}
      </StyledRow>

      <div
        style={{
          height: '650px',
          overflowY: 'scroll',
          paddingRight: '10px',
          marginRight: '-10px',
        }}
      >
        {Object.keys(rows).map((uniqueKey: string) => {
          const [key, title] = uniqueKey.split(ROW_KEY_DELIMETER);
          const subrows = rows[uniqueKey];
          return (
            <>
              <StyledRow key={key} sx={{ height: '45px;' }}>
                <StyledRowSection>{title}</StyledRowSection>
              </StyledRow>

              {subrows.map((row: TableRow) => {
                const {
                  time_horizon: timeHorizon,
                  possibility_probability: possibilityProbability,
                  possibility_scale: possibilityScale,
                  risk_probability: riskProbability,
                  risk_scale: riskScale,
                  reasoning,
                } = row;
                return (
                  <StyledRow key={`${key}-${timeHorizon}`}>
                    <StyledCell>
                      {t(
                        `esg.dma.financialTopicsEvaluationTable.columns.${timeHorizon}`
                      )}
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#EEE' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-risk-scale`}
                        value={riskScale}
                        options={NUMERIC_OPTIONS}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            risk_scale: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#EEE' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-risk-probability`}
                        value={riskProbability}
                        options={PROBABILITY_OPTIONS}
                        disabled={riskScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            risk_probability: value,
                          })
                        }
                      />
                    </StyledCell>
                    <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-possibility-scale`}
                        value={possibilityScale}
                        options={NUMERIC_OPTIONS}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            possibility_scale: value,
                          })
                        }
                      />
                    </StyledCell>

                    <StyledCell sx={{ backgroundColor: '#F8F8F8' }}>
                      <CustomSelect
                        key={`${key}-${timeHorizon}-possibility-probability`}
                        value={possibilityProbability}
                        options={PROBABILITY_OPTIONS}
                        disabled={possibilityScale === 0}
                        onChange={(value) =>
                          updateTableRow({
                            ...row,
                            possibility_probability: value,
                          })
                        }
                      />
                    </StyledCell>
                    {!preliminary && (
                      <StyledCell>
                        <EditModal
                          title={t(
                            'esg.dma.nonFinancialTopicsEvaluationTable.columns.reasoning'
                          )}
                          value={reasoning}
                          disabled
                          onChange={(value) => {
                            updateTableRow({ ...row, reasoning: value });
                          }}
                        />
                      </StyledCell>
                    )}
                  </StyledRow>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
}
