import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import {
  EsgDmaSubtopicOutSchema,
  EsgDmaTopicOutSchema,
  StakeholdersSurveySummarySchema,
} from 'src/Esg/Dmav2/types';
import SubTopicRow from './SubTopicRow';

interface TopicRowProps {
  topic: EsgDmaTopicOutSchema;
  subTopics: EsgDmaSubtopicOutSchema[];
  summary: StakeholdersSurveySummarySchema[];
}

export default function TopicRow(props: TopicRowProps) {
  return (
    <>
      <TableRow>
        <TableCell rowSpan={props.subTopics.length + 1}>
          {props.topic.name}
        </TableCell>
      </TableRow>
      {props.subTopics.map((subTopic) => (
        <SubTopicRow
          subTopic={subTopic}
          summary={props.summary}
          key={subTopic.id}
        />
      ))}
    </>
  );
}
