import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContactUsPrompt, useGhgOrganization } from 'src/common/hooks';
import { useGhgFactorEntries } from 'src/Ghg/hooks';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from 'src/Ghg/interfaces';
import { useGoodsAndServices } from '../../hooks';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { EcoinventMethodGoodData, GoodsAndServicesData } from '../../types';
import { toast } from 'react-toastify';
import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import { AddCircle } from '@mui/icons-material';
import FormModal from './FormModal';
import MethodDescriptionPanel from '../../components/MethodDescriptionPanel';
import { getPeriodDate, questionForGoodType } from 'src/Ghg/utils';
import TableHeader from 'src/Ghg/components/TableV2/TableHeader';
import ButtonsCell from 'src/Ghg/components/TableV2/ButtonsCell';
import { StyledFlex } from 'src/components/StyledFlex';
import CommentTooltip from 'src/Ghg/components/TableV2/CommentTooltip';
import { StyledTableV2Cell } from 'src/Ghg/components/TableV2/styles';

interface Props {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

function modifyArray(
  array: EcoinventMethodGoodData[],
  index: number | null,
  value: EcoinventMethodGoodData | null
) {
  if (value === null) {
    return array.filter((_, i) => i !== index);
  } else if (index === null) {
    return [...array, value];
  } else {
    return array.map((item, i) => (i === index ? value : item));
  }
}

function formatShare(value: number | null) {
  return value === null ? '' : (100 * value).toFixed(2);
}

export default function EcoinventMethodBox({ type }: Props) {
  const organization = useGhgOrganization();
  const period = getPeriodDate(organization.co2.reporting_freq);
  const { navigateMailTo } = useContactUsPrompt();
  const { t } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}`,
  });
  const { t: methodTranslation } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}.method3`,
  });

  const factors = useGhgFactorEntries();

  const data = useGoodsAndServices({
    organization_id: organization.id,
    period,
    type,
  });

  const [editedEntryIndex, setEditedEntryIndex] = useState<number | null>(null);
  const [formActive, setFormActive] = useState<boolean>(false);

  const openModal = (editIndex?: number) => {
    setEditedEntryIndex(editIndex === undefined ? null : editIndex);
    setFormActive(true);
  };

  const handleFormSave = (
    index: number | null,
    entry: EcoinventMethodGoodData | null
  ) => {
    if (index === null && entry === null) {
      // this should not happen
      toast.error('Error occured while saving data');
      return;
    }
    const oldData = data.query.data!;
    const newData: GoodsAndServicesData = {
      data: oldData.data,
      average_method_data: oldData.average_method_data,
      ecoinvent_method_data: modifyArray(
        oldData.ecoinvent_method_data,
        index,
        entry
      ),
    };
    data.update.mutate(newData, {
      onSuccess: () => {
        setFormActive(false);
      },
      onError(error: any) {
        toast.error(error?.message || 'Error');
      },
    });
  };

  if (data.isLoading || factors.isLoading) return <PageLoading />;
  if (data.isError || factors.isError) return <SomethingWentWrong />;

  if (factors.data.length === 0) {
    return (
      <MethodDescriptionPanel
        t={methodTranslation}
        buttonTextKey="buttonAskForAccess"
        onButtonClick={navigateMailTo}
      />
    );
  }

  const methodData = data.query.data!.ecoinvent_method_data;
  const emissionShares = data.queryEmissions.data!.ecoinvent_method_data;

  if (methodData.length === 0) {
    return (
      <>
        <FormModal
          type={type}
          index={editedEntryIndex}
          open={formActive}
          onClose={() => setFormActive(false)}
          onSave={handleFormSave}
        />
        <MethodDescriptionPanel
          t={methodTranslation}
          buttonTextKey="buttonAddData"
          onButtonClick={openModal}
        />
      </>
    );
  }

  return (
    <>
      <FormModal
        type={type}
        index={editedEntryIndex}
        open={formActive}
        onClose={() => setFormActive(false)}
        onSave={handleFormSave}
      />
      <Typography variant="h3" sx={{ mb: '24px' }}>
        {t('method3.title')}
      </Typography>
      <StyledPanel>
        <TableContainer>
          <Table>
            <TableHeader
              headerKeys={[`productName.${type}`, 'addedBy', 'emissionShare']}
            />
            <TableBody>
              {methodData.map((elem, id) => (
                <TableRow key={id}>
                  <StyledTableV2Cell number={id}>
                    <StyledFlex>
                      <Typography>{elem.name_label}</Typography>
                      <CommentTooltip comment={elem.comment} />
                    </StyledFlex>
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={id}>
                    <Typography>{t('table.company')}</Typography>
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={id}>
                    <Typography>
                      {formatShare(emissionShares[id]?.share)}
                    </Typography>
                  </StyledTableV2Cell>
                  <ButtonsCell
                    id={id}
                    onEdit={() => openModal(id)}
                    onCopy={() => handleFormSave(null, elem)}
                    onDelete={() => handleFormSave(id, null)}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPanel>
      <Button
        sx={{ mt: '24px', mb: '24px' }}
        variant="text"
        startIcon={<AddCircle />}
        onClick={() => openModal()}
      >
        <Typography variant="subtitle1">
          {t('method3.buttonAddAnother')}
        </Typography>
      </Button>
    </>
  );
}
