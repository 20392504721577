import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';
import { ImageSlider } from '../components/ImageSlider';
import { generateImageUrls } from '../utils';
import LightTooltip from 'src/components/LightTooltip';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { FactCheck } from '@mui/icons-material';

export default function Stage3() {
  const { t, language: l } = useLanguage({ keyPrefix: 'esg.dma' });
  return (
    <DmaStage
      title={t('stagesV2.preliminaryAssessmentOfFinancialMateriality.title')}
    >
      <DmaStep
        title={t(
          'stagesV2.preliminaryAssessmentOfFinancialMateriality.steps.1'
        )}
        videoComponent={<ImageSlider images={generateImageUrls(l, 5, 10)} />}
      />
      <DmaStep
        title={t(
          'stagesV2.preliminaryAssessmentOfFinancialMateriality.steps.2'
        )}
        navigateToComponent={
          <LightTooltip title={t('step.step3TopicEvaluationButton.toggle')}>
            <Link to="./step-3">
              <IconButton>
                <FactCheck />
              </IconButton>
            </Link>
          </LightTooltip>
        }
      />
    </DmaStage>
  );
}
