import React from 'react';
import { Trans } from 'react-i18next';

import { Typography } from '@mui/material';

import { StyledDescription, StyledPanel } from 'src/Esg/styles';
import SelectingStakeholdersTable from './SelectingStakeholdersTable';
import { useSidebar } from 'src/views/components/Sidebar/hooks';

export default function SelectingStakeHolders() {
  const { closeSidebar, openSidebar } = useSidebar();

  React.useEffect(() => {
    closeSidebar();
    return () => openSidebar();
  }, []);

  return (
    <StyledDescription>
      <Typography whiteSpace="pre-line" sx={{ mb: '10px' }}>
        <Trans i18nKey="esg.dma.selectingStakeholdersV2.subtitle" />
      </Typography>
      <StyledPanel style={{ minWidth: '1350px' }}>
        <SelectingStakeholdersTable />
      </StyledPanel>
    </StyledDescription>
  );
}
