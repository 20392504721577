import React from 'react';

import { Box, Typography } from '@mui/material';
import { useEsgDmaValueChainStakeholderTypes } from 'src/Esg/Dmav2/hooks';
import { useEsgStakeholderTypes } from 'src/Esg/hooks';
import EntityStakeholderTypeForm from '../Forms/EntityStakeholderType';
import NewStakeholderTypeForm from '../Forms/EntityStakeholderType/NewStakeholderType';
import { useLanguage } from 'src/common/hooks';

interface Props {
  entityId: number;
}

export default function EntityStakeholderTypes(props: Props) {
  const { entityId } = props;
  const { t } = useLanguage({ keyPrefix: 'esg.dma.step1.valueChain' });

  const { _data, create, _delete } =
    useEsgDmaValueChainStakeholderTypes(entityId);

  const { data: stakeholders, dataMap: stakeholdersMap } =
    useEsgStakeholderTypes();

  const allStakeholdersCovered = stakeholders.length <= _data.length;
  const noData = _data.length === 0;

  return (
    <Box position="relative" width="100%" mb="10px" minHeight="100px">
      <Typography position="absolute" p="10px" width="25%" variant="subtitle2">
        {t('esgStakeholderType')}
      </Typography>

      {_data.map((entityStakeholderType) => {
        const stakeholder =
          stakeholdersMap[entityStakeholderType.esg_stakeholder_type_id];
        return (
          <EntityStakeholderTypeForm
            key={entityStakeholderType.id}
            value={stakeholder?.name ?? ''}
            // TODO: confirmation modal ??
            onDelete={() => _delete.mutate(entityStakeholderType.id)}
          />
        );
      })}
      {!allStakeholdersCovered && (
        <NewStakeholderTypeForm
          key={_data.length}
          entityId={entityId}
          onSave={(data) => create.mutate(data)}
          isActive={noData}
          buttonSx={{ ml: '25%' }}
        />
      )}
    </Box>
  );
}
