import React from 'react';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { client } from 'src/utils/api-client';
import { useParams } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export default function QueryParamTokenAuthInterceptor(props: Props) {
  const { token } = useParams();
  const [interceptorReady, setInterceptorReady] = React.useState(false);
  React.useEffect(() => {
    const interceptor = client.interceptors.request.use((config) => {
      const newParams = { ...config.params, token };
      config.params = newParams;
      return config;
    });
    setInterceptorReady(true);
    return () => {
      client.interceptors.request.eject(interceptor);
    };
  }, []);
  if (!token) return <SomethingWentWrong />;
  if (!interceptorReady) return <PageLoading />;

  return props.children as React.ReactElement;
}
