import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteFreesolo, Select } from '../fields';
import TextFieldNumber from '../../../../components/TextFieldNumber';
import { useAppDispatch, useAppSelector } from '../../../../redux-file/hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { Overlay } from '../Title/index.styles';
import { CheckboxContainer, Form, FormBox } from './index.styles';
import {
  getTransportationAndDistributionData,
  getTransportationAndDistributionDataEmissionShares,
  putTransportationAndDistributionData,
} from '../../../../redux-file/questionnaires/thunks';
import { timeConverter } from '../../../../utils/timeConverter';
import FormButtons from '../FormButtons';
import OneValueCheckboxField from '../../../../components/CheckboxField';
import { transport_units, unit_of_weight } from './data';

export default function TransportUpstreamForm({
  state,
  setState,
  id,
  active,
  setActive,
  combinedData,
  variant,
  setNewForm,
}: {
  setNewForm: Function;
  state: any;
  setState: Function;
  id: number;
  active: undefined | number;
  setActive: Function;
  combinedData: any;
  variant: 'u' | 'd';
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector(
    (redux_state) => redux_state.questionnaire
  );
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const isUnknownWithManualFactor =
    state[id].transportation_data_factor_unknown?.type_of_transportation ===
    'other.manual_factor';

  const [
    type_of_transportation_error_state,
    set_type_of_transportation_error_state,
  ] = useState(false);
  let type_of_transportation_error = false;
  const type_of_transportation_validation = (value: string) => {
    if (value === '') type_of_transportation_error = true;
    return type_of_transportation_error;
  };

  const [manual_factor_error_state, set_manual_factor_error_state] =
    useState(false);
  let manual_factor_error = false;
  const manual_factor_validation = (value: string) => {
    if (value === '') manual_factor_error = true;
    return manual_factor_error;
  };

  const [distance_km_error_state, set_distance_km_error_state] =
    useState(false);
  let distance_km_error = false;
  const distance_km_validation = (value: string) => {
    if (value === '') distance_km_error = true;
    return distance_km_error;
  };

  const [weight_error_state, set_weight_error_state] = useState(false);
  let weight_error = false;
  const weight_validation = (value: string) => {
    if (value === '') weight_error = true;
    return weight_error;
  };

  const [weight_unit_error_state, set_weight_unit_error_state] =
    useState(false);
  let weight_unit_error = false;
  const weight_unit_validation = (value: string) => {
    if (value === '') weight_unit_error = true;
    return weight_unit_error;
  };

  const [factor_error_state, set_factor_error_state] = useState(false);
  let factor_error = false;
  const factor_validation = (value: string) => {
    if (value === '') factor_error = true;
    return factor_error;
  };

  const [amount_error_state, set_amount_error_state] = useState(false);
  let amount_error = false;
  const amount_validation = (value: string) => {
    if (value === '') amount_error = true;
    return amount_error;
  };

  const setKnowEmission = () => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      name_label: '',
      emission_factor_is_known: !state[id].emission_factor_is_known,
      transportation_data_factor_unknown: state[id].emission_factor_is_known
        ? {
            type_of_transportation: '',
            distance_km: '',
            weight: '',
            weight_unit: '',
            manual_factor: '',
          }
        : commutingType.transportation_data_factor_unknown,
      transportation_data_factor_known: state[id].emission_factor_is_known
        ? commutingType.transportation_data_factor_known
        : {
            factor: '',
            amount: '',
            unit_label: '',
          },
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const setTypeOfTransport = (value: string) => {
    type_of_transportation_validation(value);
    set_type_of_transportation_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_unknown: {
        ...commutingType.transportation_data_factor_unknown,
        type_of_transportation: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_type_of_transportation_error_state(true);
  };

  const handleManualFactor = (value: string) => {
    manual_factor_validation(value);
    set_manual_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_unknown: {
        ...commutingType.transportation_data_factor_unknown,
        manual_factor: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '' && isUnknownWithManualFactor)
      set_manual_factor_error_state(true);
  };

  const handleDistance = (value: string) => {
    distance_km_validation(value);
    set_distance_km_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_unknown: {
        ...commutingType.transportation_data_factor_unknown,
        distance_km: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_distance_km_error_state(true);
  };

  const handleWeight = (value: string) => {
    weight_validation(value);
    set_weight_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_unknown: {
        ...commutingType.transportation_data_factor_unknown,
        weight: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_weight_error_state(true);
  };

  const handleWeightUnit = (value: string) => {
    weight_unit_validation(value);
    set_weight_unit_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_unknown: {
        ...commutingType.transportation_data_factor_unknown,
        weight_unit: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_weight_unit_error_state(true);
  };

  const handleNameLabel = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      name_label: value,
    };
    newForms[id] = commutingType;
    setState(newForms);
  };

  const handleKnownUnitLabel = (value: string) => {
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_known: {
        ...commutingType.transportation_data_factor_known,
        unit_label: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
  };
  const handleFactor = (value: string) => {
    factor_validation(value);
    set_factor_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_known: {
        ...commutingType.transportation_data_factor_known,
        factor: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_factor_error_state(true);
  };
  const handleAmount = (value: string) => {
    amount_validation(value);
    set_amount_error_state(false);
    const newForms = [...state];
    let commutingType = newForms[id];

    commutingType = {
      ...commutingType,
      transportation_data_factor_known: {
        ...commutingType.transportation_data_factor_known,
        amount: value,
      },
    };
    newForms[id] = commutingType;
    setState(newForms);
    if (value === '') set_amount_error_state(true);
  };

  const handleSend = (data: any) => {
    if (
      !type_of_transportation_error &&
      !manual_factor_error &&
      !distance_km_error &&
      !weight_error &&
      !weight_unit_error &&
      !factor_error &&
      !amount_error
    ) {
      dispatch(
        putTransportationAndDistributionData({
          data,
          period: timeConverter(selectedDate, reportingPeriod),
        })
      ).then(() => {
        dispatch(
          getTransportationAndDistributionData({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
        dispatch(
          getTransportationAndDistributionDataEmissionShares({
            orgId: organization.id,
            period: timeConverter(selectedDate, reportingPeriod),
          })
        );
      });
      setActive(undefined);
    }
  };

  const defineErrors = async () => {
    if (state[id].emission_factor_is_known === false) {
      const data = state[id].transportation_data_factor_unknown;
      if (data.type_of_transportation === '') {
        set_type_of_transportation_error_state(true);
        type_of_transportation_error = true;
      }

      if (data.manual_factor === '' && isUnknownWithManualFactor) {
        set_manual_factor_error_state(true);
        manual_factor_error = true;
      }

      if (data.distance_km === '') {
        set_distance_km_error_state(true);
        distance_km_error = true;
      }

      if (data.weight === '') {
        set_weight_error_state(true);
        weight_error = true;
      }
      if (data.weight_unit === '') {
        set_weight_unit_error_state(true);
        weight_unit_error = true;
      }
    }

    if (state[id].emission_factor_is_known === true) {
      if (state[id].transportation_data_factor_known.factor === '') {
        set_factor_error_state(true);
        factor_error = true;
      }

      if (state[id].transportation_data_factor_known.amount === '') {
        set_amount_error_state(true);
        amount_error = true;
      }
    }
  };

  const handleRemove = async () => {
    const tempState = [...state];
    tempState.splice(id, 1);
    setState(tempState);

    const tempcombinedData = {
      transportation: {
        upstream:
          variant === 'u' ? tempState : combinedData.transportation.upstream,
        downstream:
          variant === 'u' ? combinedData.transportation.downstream : tempState,
      },
      distribution: combinedData.distribution,
    };

    handleSend(tempcombinedData);
  };

  return (
    <>
      {active === id && (
        <Overlay
          onClick={() =>
            defineErrors().then(() => {
              handleSend(combinedData);
            })
          }
        />
      )}
      <Form active={active === id}>
        <div onClick={() => setActive(id)}>
          <CheckboxContainer>
            <OneValueCheckboxField
              label={t('transportation.upstream-transportation.check')}
              value={state[id].emission_factor_is_known}
              setValue={() => setKnowEmission()}
            />
          </CheckboxContainer>
          <FormBox variant="100">
            <AutocompleteFreesolo
              active
              max={4}
              index={2}
              options={[]}
              error={false}
              label={t('transportation.upstream-transportation.nameLabel')}
              value={state[id].name_label}
              setValue={(e: string) => handleNameLabel(e)}
            />
          </FormBox>
          {state[id].emission_factor_is_known === false ? (
            <>
              <FormBox variant="100">
                <Select
                  active
                  error={type_of_transportation_error_state}
                  index={1}
                  label={t(
                    'transportation.upstream-transportation.type-of-transport'
                  )}
                  value={
                    state[id].transportation_data_factor_unknown
                      .type_of_transportation || ''
                  }
                  setValue={(value: string) => setTypeOfTransport(value)}
                  max={123}
                  options={transport_units}
                />
              </FormBox>
              {isUnknownWithManualFactor && (
                <FormBox variant="100">
                  <TextFieldNumber
                    type="factor"
                    label={t('transportation.upstream-transportation.factor')}
                    active
                    error={manual_factor_error_state}
                    index={1}
                    value={
                      state[id].transportation_data_factor_unknown
                        ?.manual_factor ?? ''
                    }
                    setValue={(e: string) => handleManualFactor(e)}
                  />
                </FormBox>
              )}
              <FormBox variant="100">
                <TextFieldNumber
                  label={t('transportation.upstream-transportation.distance')}
                  active
                  error={distance_km_error_state}
                  index={1}
                  value={
                    state[id].transportation_data_factor_unknown
                      ? state[id].transportation_data_factor_unknown.distance_km
                      : ''
                  }
                  setValue={(e: string) => handleDistance(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  type="mass"
                  label={t('transportation.upstream-transportation.weight')}
                  active
                  error={weight_error_state}
                  index={1}
                  value={
                    state[id].transportation_data_factor_unknown
                      ? state[id].transportation_data_factor_unknown.weight
                      : ''
                  }
                  setValue={(e: string) => handleWeight(e)}
                />
              </FormBox>
              <FormBox variant="50">
                <Select
                  active
                  label={t(
                    'transportation.upstream-transportation.weight-unit'
                  )}
                  error={weight_unit_error_state}
                  index={1}
                  max={123}
                  value={
                    state[id].transportation_data_factor_unknown?.weight_unit ||
                    ''
                  }
                  setValue={(e: string) => handleWeightUnit(e)}
                  options={unit_of_weight}
                />
              </FormBox>
            </>
          ) : (
            <>
              <FormBox variant="50">
                <AutocompleteFreesolo
                  active
                  error={false}
                  index={1}
                  label={t('transportation.upstream-transportation.unit')}
                  value={
                    state[id].transportation_data_factor_known
                      ? state[id].transportation_data_factor_known.unit_label
                      : ''
                  }
                  setValue={(value: string) => handleKnownUnitLabel(value)}
                  max={123}
                  options={[]}
                />
              </FormBox>
              <FormBox variant="50">
                <TextFieldNumber
                  type="factor"
                  label={t('transportation.upstream-transportation.factor')}
                  active
                  error={factor_error_state}
                  index={1}
                  value={
                    state[id].transportation_data_factor_known
                      ? state[id].transportation_data_factor_known.factor
                      : ''
                  }
                  setValue={(e: string) => handleFactor(e)}
                />
              </FormBox>
              <FormBox variant="100">
                <TextFieldNumber
                  label={t('transportation.upstream-transportation.amount')}
                  active
                  error={amount_error_state}
                  index={1}
                  value={
                    state[id].transportation_data_factor_known
                      ? state[id].transportation_data_factor_known.amount
                      : ''
                  }
                  setValue={(e: string) => handleAmount(e)}
                />
              </FormBox>
            </>
          )}
        </div>
        {active === id && (
          <FormButtons
            setTable={setNewForm}
            canCopy={
              (!state[id].emission_factor_is_known &&
                state[id].transportation_data_factor_unknown
                  .type_of_transportation !== '' &&
                state[id].transportation_data_factor_unknown.distance_km !==
                  '' &&
                state[id].transportation_data_factor_unknown.weight !== '' &&
                state[id].transportation_data_factor_unknown.weight_unit !==
                  '') ||
              (state[id].emission_factor_is_known &&
                state[id].transportation_data_factor_known.factor !== '' &&
                state[id].transportation_data_factor_known.amount !== '')
            }
            setActive={setActive}
            state={state}
            setState={setState}
            id={id}
            onClickRemove={() => handleRemove()}
          />
        )}
      </Form>
    </>
  );
}
