import React from 'react';

import { EsgDmaValueChainEntityOutSchema } from 'src/Esg/Dmav2/types';
import SaveButton from '../components/SaveButton';
import EntityForm from './Form';

interface Props {
  entity: EsgDmaValueChainEntityOutSchema;
  // eslint-disable-next-line no-unused-vars
  onSave: (entity: EsgDmaValueChainEntityOutSchema) => void;
}

export default function Entity(props: Props) {
  const { entity, onSave } = props;

  const [form, setForm] =
    React.useState<EsgDmaValueChainEntityOutSchema>(entity);

  const handleFormChange = (fieldName: string, value: any) =>
    setForm({ ...form, [fieldName]: value });

  const isModified = JSON.stringify(form) !== JSON.stringify(entity);

  return (
    <>
      <EntityForm form={form} onFieldChange={handleFormChange} />
      <SaveButton disabled={!isModified} onSave={() => onSave(form)} />
    </>
  );
}
