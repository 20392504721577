import React from 'react';
import Container from '../components/Container';
import assets from '../assets';
import Title from '../components/Title';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useEsgStakeholderSurveyMetadata } from '../../hooks';
import FormattedSurvey from '../components/FormattedSurvey';
import OrganizationalLogo from 'src/components/OrganizationalLogo';

export default function StakeholderSurveyIntro() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  const { metadata } = useEsgStakeholderSurveyMetadata();

  const nextButtonHandler = () => navigate(`/stakeholders/${token}/survey`);

  return (
    <Container
      header={<img src={assets.intro.image} />}
      nextLink={{
        onClick: nextButtonHandler,
        label: 'esg.stakeholderSurvey.next',
      }}
    >
      <div>
        <Title
          title={t('esg.stakeholderSurvey.title')}
          period={metadata.report_period}
        />
        <FormattedSurvey
          text={
            metadata.intro_text ||
            t('esg.stakeholderSurvey.intro.message', {
              organization_name: metadata.organization_name,
            })
          }
        />
        <OrganizationalLogo logo={metadata.logo} />
      </div>
    </Container>
  );
}
