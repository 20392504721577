import React from 'react';

import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import Field from '../components/Field';

interface Props {
  value: any;
  onDelete: () => void;
}

export default function EntityStakeholderType(props: Props) {
  const { value, onDelete } = props;

  return (
    <Field
      value={value ?? ''}
      textFieldProps={{
        InputProps: {
          readOnly: true,
          endAdornment: (
            <IconButton size="small" sx={{ mr: '-10px' }} onClick={onDelete}>
              <Close fontSize="small" />
            </IconButton>
          ),
        },
      }}
    />
  );
}
