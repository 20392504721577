import React from 'react';
import { Typography } from '@mui/material';

import { UniversalField } from '../types';

export default function SimpleTextField(props: UniversalField) {
  const { field } = props;
  const { title, label } = field;

  return (
    <center style={{ margin: '8px 0px 26px' }}>
      {!!title && <Typography variant="h4">{title}</Typography>}
      {!!label && <Typography variant="caption">{label}</Typography>}
    </center>
  );
}
