import React from 'react';
import { AddCircleOutline, CheckRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { StyledPanel } from 'src/components/StyledPanel';
import themeColors from 'src/theme/colors';

interface Props {
  t: (_key: string) => string;
  buttonTextKey?: string;
  onButtonClick: () => void;
}

export default function MethodDescriptionPanel({
  t,
  buttonTextKey,
  onButtonClick,
}: Props) {
  return (
    <StyledPanel>
      <Typography variant="h4" color={themeColors.ghgMain}>
        {t('title')}
      </Typography>
      <Typography variant="h3" marginTop="32px">
        {t('based')}
      </Typography>
      <Typography variant="subtitle1" marginTop="32px">
        {t('desc')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '32px' }}>
        <CheckRounded sx={{ color: themeColors.ghgMain, fontSize: '32px' }} />
        <Typography variant="h5" marginLeft="4px">
          {t('point1')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '32px' }}>
        <CheckRounded sx={{ color: themeColors.ghgMain, fontSize: '32px' }} />
        <Typography variant="h5" marginLeft="4px">
          {t('point2')}
        </Typography>
      </Box>
      <Button
        sx={{ mt: '32px' }}
        variant="primary"
        startIcon={<AddCircleOutline />}
        onClick={() => onButtonClick()}
        size="large"
      >
        <Typography variant="subtitle1">
          {t(buttonTextKey ?? 'button')}
        </Typography>
      </Button>
    </StyledPanel>
  );
}
