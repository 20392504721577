import React from 'react';

import { Box, Divider } from '@mui/material';
import Title from './Title';
import NewEntityForm from '../Forms/Entity/NewEntity';
import withActivationButton from '../Forms/components/withActivationButton';
import { useEsgDmaValueChainEntities } from 'src/Esg/Dmav2/hooks';
import { EsgDmaValueChainEntityInSchema } from 'src/Esg/Dmav2/types';
import { DisabledField } from '../Forms/components/Field';

interface Props {
  onClose?: () => void;
}

function AddNewValueChainEntity(props: Props) {
  const { onClose } = props;
  const { create } = useEsgDmaValueChainEntities({}, { enabled: false });
  const handleOnSave = (data: EsgDmaValueChainEntityInSchema) =>
    create.mutateAsync(data).then(onClose);

  return (
    <Box sx={{ width: '100%', bgcolor: '#f4f4f4', m: '10px 0px' }}>
      <Title sx={{ bgcolor: '#cfdf9d' }} onDeleteClick={onClose} />
      <Box width="95%" margin="auto" p="20px 0px">
        {/* New Value Chain Entity Form */}
        <NewEntityForm onSave={handleOnSave} />
        {/* Disabled Relations Fields */}
        <Divider sx={{ m: '20px 0px' }} />
        <DisabledField fieldName="employeeCount" />
        <Divider sx={{ m: '20px 0px' }} />
        <DisabledField fieldName="esgStakeholderType" />
        <Divider sx={{ m: '20px 0px' }} />
        <DisabledField fieldName="stage" />
      </Box>
    </Box>
  );
}

export default withActivationButton(AddNewValueChainEntity);
