import { useMutation, useQuery } from 'react-query';
import { client } from 'src/utils/api-client';

import { useLanguage } from 'src/common/hooks';
import {
  ConsolidationTableAnswerForEsgUpdateSchema,
  ConsolidationTableDataSchema,
} from './types';
import { toast } from 'react-toastify';

export const useEsgConsolidationTables = (esgQuestionnaireKey: string) => {
  const { language } = useLanguage();
  const url = '/web/esg/v3/consolidation-tables';
  const queryKey = ['esg_consolidation_tables', esgQuestionnaireKey, language];

  const { data } = useQuery(queryKey, () =>
    client
      .get<ConsolidationTableDataSchema[]>(url, {
        params: { esg_questionnaire_key: esgQuestionnaireKey },
      })
      .then((res) => res.data)
  );

  return {
    data: data || [],
  };
};

export const useEsgConsolidationTableData = (id: number, period: string) => {
  const { language } = useLanguage();
  const url = '/web/esg/v3/consolidation-tables';
  const queryKey = ['esg_consolidation_data', id, period, language];

  const query = useQuery(queryKey, () =>
    client
      .get<ConsolidationTableDataSchema>(`${url}/${id}/data`, {
        params: { period },
      })
      .then((res) => res.data)
  );

  const update = useMutation({
    mutationFn: (data: ConsolidationTableAnswerForEsgUpdateSchema) =>
      client.put(`${url}/${id}/answer`, data, { params: { period } }),
    onSuccess: () => {
      query.refetch();
      toast.success('Success');
    },
  });

  return {
    ...query,
    data: query.data,
    update,
  };
};
