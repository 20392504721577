import { useQuery } from 'react-query';
import { useLanguage } from 'src/common/hooks';
import { client } from 'src/utils/api-client';
import { GhgFactorTableEntrySchema } from './types';

// eslint-disable-next-line
export const useGhgSidebarPermissions = (
  organizationId: number,
  year: number
) => {
  const queryKey = ['ghgSidebarPermissions', { organizationId, year }];
  const query = useQuery(queryKey, () =>
    client
      .get<{ [key: string]: boolean }>(
        '/web/user-organization-permissions/ghg-sidebar',
        {
          params: { organization_id: organizationId, year },
        }
      )
      .then((res) => res.data)
  );

  const data = query.data || {};

  const hasAccess = (scope: string, section: string) =>
    data[`${scope}::${section}`] || false;

  return { data, hasAccess };
};

export const useGhgFactorEntries = () => {
  const { language } = useLanguage();
  const queryKey = ['ghgFactorEntries', language];

  const query = useQuery(queryKey, () =>
    client
      .get<GhgFactorTableEntrySchema[]>('/web/ghg-factors/factor-entries')
      .then((res) => res.data)
  );

  const data = query.data || [];

  return { ...query, data };
};
