import React from 'react';

import { Box, SxProps } from '@mui/material';
import { StyledPanel, StyledTitle } from 'src/Superadmin/index.styles';
import { useEsgUserPermissions } from '../hooks';
import EsgUserPermissionsTable from './Table';
import { t } from 'i18next';
import YearSelector from 'src/Superadmin/components/Panels/YearSelector';
import { min } from 'lodash';

interface Props {
  sx?: SxProps;
  userId: number;
}

export default function EsgUserPermissions(props: Props) {
  const { sx, userId } = props;

  const [reportYear, setReportYear] = React.useState<number | null>(null);
  const { options } = useEsgUserPermissions(userId);

  const maxYear = options.reports.reduce(
    (max, report) => Math.max(max, report.period),
    2000
  );
  const minYear = options.reports.reduce(
    (min, report) => Math.min(min, report.period),
    maxYear
  );

  React.useEffect(() => {
    if (options.reports.length > 0) {
      setReportYear(maxYear);
    }
  }, [minYear, maxYear, options]);

  return (
    <Box sx={sx}>
      <StyledTitle sx={{ fontSize: '28px' }}>
        {t('superadmin.settings.tabs.users.usersTable.permissions.title.esg')}
      </StyledTitle>

      {reportYear && (
        <YearSelector
          startYear={minYear}
          endYear={maxYear}
          selectedYear={reportYear}
          onYearChange={(year) => setReportYear(year)}
        />
      )}

      <StyledPanel sx={{ mt: '32px' }}>
        <EsgUserPermissionsTable userId={userId} reportYear={reportYear} />
      </StyledPanel>
    </Box>
  );
}
