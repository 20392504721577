import React from 'react';

import PageLoading from 'src/components/PageLoading';
import { QuestionnaireV3 } from '../types';
import { AxiosHeaders } from 'axios';
import { client } from 'src/utils/api-client';

interface Props extends Readonly<QuestionnaireV3> {
  readonly children: React.ReactNode;
}

export default function QuestionnaireV3Auth(props: Props) {
  const { tokenAuth } = props;
  const [interceptorReady, setInterceptorReady] = React.useState(false);

  React.useEffect(() => {
    // Add a request interceptor to add the token to the headers
    const interceptor = client.interceptors.request.use(async (config) => {
      // Add the token to the headers if the URL contains a api-questionnaires
      if (config.url?.includes('api-questionnaires'))
        config.headers = new AxiosHeaders({
          ...config.headers,
          'X-Questionnaires-Token': await tokenAuth(),
        });
      return config;
    });
    setInterceptorReady(true);

    return () => {
      client.interceptors.request.eject(interceptor);
    };
  }, []);

  if (!interceptorReady) return <PageLoading />;
  return props.children as React.ReactElement;
}
