import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { useLanguage } from 'src/common/hooks';
import ValueChainEntities from './ValueChainEntities';
import { StyledFlex } from 'src/components/StyledFlex';

export default function Step1() {
  const { t } = useLanguage();
  const navigate = useNavigate();
  return (
    <Box>
      {/* Title and subtitle  */}
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardArrowLeft fontSize="large" sx={{ color: '#38414f' }} />
        </IconButton>
        <Typography variant="h1">
          {t('esg.dma.step1.valueChain.title')}
        </Typography>
      </StyledFlex>
      <Typography variant="body2" sx={{ p: '20px' }}>
        {t('esg.dma.step1.valueChain.subtitle')}
      </Typography>

      {/* Value chain entities */}
      <ValueChainEntities />
    </Box>
  );
}
