import mainImage from 'src/images/surveys/main.png';
import summary from 'src/images/surveys/Photo_3.png';
import end from 'src/images/surveys/Photo_2.png';

export default {
  intro: {
    image: mainImage,
  },
  summary: { image: summary },
  outro: {
    image: end,
  },
};
