import React, { useEffect, useState } from 'react';
import { useGhgOrganization } from 'src/common/hooks';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from 'src/Ghg/interfaces';
import { useGhgFactorEntries } from 'src/Ghg/hooks';
import { useGoodsAndServices } from '../../hooks';
import { EcoinventMethodGoodData } from '../../types';
import { validateForm } from './FormValidation';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import NumberField from 'src/components/NumberField';
import { Box } from '@mui/system';
import { Check, DeleteOutline } from '@mui/icons-material';
import { getPeriodDate, questionForGoodType } from 'src/Ghg/utils';

interface Props {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
  index: number | null;
  open: boolean;
  onClose: () => void;
  onSave: (
    _index: number | null,
    _data: EcoinventMethodGoodData | null
  ) => void;
}

const initialFormState = {
  name_label: null,
  quantity: null,
  factor_id: null,
  comment: null,
};

export default function FormModal({
  type,
  index,
  open,
  onClose,
  onSave,
}: Props) {
  const organization = useGhgOrganization();
  const period = getPeriodDate(organization.co2.reporting_freq);
  const factors = useGhgFactorEntries();

  const { t } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}.form`,
  });

  const data = useGoodsAndServices({
    organization_id: organization.id,
    period,
    type,
  });

  const [form, setForm] = useState<EcoinventMethodGoodData>(initialFormState);
  const [isModified, setIsModified] = useState<boolean>(false);

  const handleChange = (key: keyof EcoinventMethodGoodData, value: any) => {
    const newForm = { ...form, [key]: value };
    setForm(newForm);
    setIsModified(true);
  };

  useEffect(() => {
    if (index !== null) {
      const entry = data.query.data?.ecoinvent_method_data[index];
      if (entry) setForm(entry);
    } else {
      setForm(initialFormState);
    }
  }, [index, data.query.data]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Typography sx={{ textAlign: 'center' }}>
          {index === null ? t('add') : t('edit')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            autoFocus
            fullWidth
            label={t('name')}
            value={form.name_label ?? ''}
            onChange={(e) => handleChange('name_label', e.target.value)}
            sx={{ mb: '24px' }}
          />
          <FormControl required fullWidth sx={{ mb: '24px' }}>
            <InputLabel id="factor">{t('factor')}</InputLabel>
            <Select
              labelId="factor"
              label={t('factor')}
              value={form.factor_id || ''}
              onChange={(e) => handleChange('factor_id', e.target.value)}
            >
              {factors.data.map((factor) => (
                <MenuItem key={factor.id} value={factor.id}>
                  {factor.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <NumberField
            fullWidth
            label={t('quantity')}
            value={form.quantity ?? ''}
            onChange={(value) => handleChange('quantity', value)}
            required
            sx={{ mb: '24px' }}
          />
          <TextField
            fullWidth
            label={t('comment')}
            value={form.comment ?? ''}
            onChange={(e) => handleChange('comment', e.target.value)}
            minRows={3}
            multiline
            sx={{ mb: '24px' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              disabled={
                !isModified || Object.keys(validateForm(form, t)).length > 0
              }
              onClick={() => onSave(index, form)}
            >
              <Check />
            </IconButton>
            <IconButton
              disabled={index === null}
              onClick={() => onSave(index, null)}
            >
              <DeleteOutline />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
