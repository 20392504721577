import React from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
} from '@mui/material';
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { useLanguage } from 'src/common/hooks';

interface Props {
  collapsed?: boolean;
  onClick?: () => void;
  onDeleteClick?: () => void;
  sx?: SxProps;
}
export default function Title(props: Props) {
  const { collapsed, onClick, onDeleteClick, sx } = props;
  const { t } = useLanguage();
  const CollapseIcon = collapsed ? KeyboardArrowRight : KeyboardArrowDown;

  return (
    <MenuItem onClick={onClick} sx={sx}>
      {!!onClick && (
        <ListItemIcon>
          <CollapseIcon />
        </ListItemIcon>
      )}
      <ListItemText>{t('esg.dma.step1.valueChain.entity')}</ListItemText>
      <IconButton
        // TODO: add confirmation dialog
        onClick={onDeleteClick}
        sx={{ position: 'absolute', right: '4px' }}
      >
        <Delete />
      </IconButton>
    </MenuItem>
  );
}
