import React from 'react';

import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { CheckCircle, Close } from '@mui/icons-material';
import { StyledFlex } from '../StyledFlex';

interface Props {
  maxDigits?: number;
  decimalPlaces?: number;
  onAccept: (
    // eslint-disable-next-line no-unused-vars
    value: unknown
  ) => void;
  onReject?: () => void;
  buttonsAlwaysVisible?: boolean;
  submitOnEnter?: boolean;
}

export default function AcceptableTextField(props: TextFieldProps & Props) {
  const {
    onAccept,
    onReject,
    buttonsAlwaysVisible = false,
    submitOnEnter = true,
    ..._props
  } = props;
  const [value, setValue] = React.useState(props.value);
  const [acceptTimeout, setAcceptTimeout] = React.useState<any>(null);
  const isEditing = value !== props.value;

  const handleAcceptClick = () => {
    clearTimeout(acceptTimeout);
    return isEditing && onAccept(value);
  };

  const handleRejectClick = () => {
    clearTimeout(acceptTimeout);
    setValue(props.value);
    if (onReject) onReject();
  };

  const handleBlur = () =>
    setAcceptTimeout(setTimeout(() => setValue(props.value), 300));

  return (
    <StyledFlex>
      <TextField
        {..._props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) =>
          submitOnEnter && e.key === 'Enter' && handleAcceptClick()
        }
        InputProps={{
          onBlur: handleBlur,
        }}
      />
      <Box
        sx={{ display: isEditing || buttonsAlwaysVisible ? 'flex' : 'none' }}
      >
        <IconButton
          size="small"
          onClick={handleAcceptClick}
          disabled={!isEditing}
          sx={{ mr: '-10px', '&:hover': { bgcolor: 'transparent' } }}
        >
          <CheckCircle />
        </IconButton>
        <IconButton
          onClick={handleRejectClick}
          sx={{ '&:hover': { bgcolor: 'transparent' } }}
        >
          <Close />
        </IconButton>
      </Box>
    </StyledFlex>
  );
}
