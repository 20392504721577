import React from 'react';
import {
  MenuItem,
  Select,
  Pagination,
  Typography,
  SxProps,
  SelectChangeEvent,
} from '@mui/material';
import { useLanguage } from 'src/common/hooks';
import { StyledFlex } from '../StyledFlex';

interface Props {
  totalElements: number;
  elementsPerPage: number;
  setElementsPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  elementsPerPageOptions?: number[];
  sx?: SxProps;
}

export const defaultElementsPerPageOptions = [10, 20, 50];

export default function ElementsPagination(props: Props) {
  const {
    totalElements,
    elementsPerPage,
    setElementsPerPage,
    page,
    setPage,
    elementsPerPageOptions = defaultElementsPerPageOptions,
    sx,
  } = props;

  const { t } = useLanguage();
  const totalPages = Math.ceil(totalElements / elementsPerPage);

  const changeElementsPerPage = (e: SelectChangeEvent<number>) => {
    setElementsPerPage(Number(e.target.value));
    setPage(1);
  };

  return (
    <StyledFlex sx={{ justifyContent: 'flex-start', ...sx }}>
      <Typography variant="caption" color="text.secondary">
        {t('pagination.resultsPerPage')}
      </Typography>
      <Select
        value={elementsPerPage}
        onChange={changeElementsPerPage}
        sx={{
          ml: '12px',
          mr: '24px',
          width: '80px',
          height: '36px',
        }}
      >
        {elementsPerPageOptions.map((el) => (
          <MenuItem key={el} value={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
      <Pagination
        count={totalPages}
        page={page}
        onChange={(_: any, value: number) => setPage(value)}
      />
    </StyledFlex>
  );
}
