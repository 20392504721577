import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { FieldValue, UniversalField } from '../types';
import { StyledErrorMessage } from '../../index.styles';
import { FieldOption } from '../../index.types';

export default function SimpleSelectField(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const { label, required, fieldoption_set: fieldOptions } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: FieldValue) => {
    // required field
    if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (e: SelectChangeEvent<any>) => {
    const { value: newValue } = e.target;
    const _value: FieldValue = newValue || null;
    _validate(_value);
    if (field.type === 'boolean') {
      const parsedBooleanValue: boolean = JSON.parse(String(_value));
      onChange(!!_value ? parsedBooleanValue : null);
    } else {
      onChange(!!_value ? _value : null);
    }
  };

  const booleanOptions: FieldOption[] = [
    {
      id: `${field.id}-true`,
      key: 'true',
      label: t('questionnaireV3.field.yes'),
    },
    {
      id: `${field.id}-false`,
      key: 'false',
      label: t('questionnaireV3.field.no'),
    },
  ];

  const options = field.type === 'boolean' ? booleanOptions : fieldOptions;

  return (
    <FormControl
      error={!!errorMessage}
      variant="outlined"
      fullWidth
      disabled={disabled}
      sx={{ mb: '32px' }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        label={label}
        value={value ?? ''}
        onChange={handleChange}
        disabled={!!disabled}
        fullWidth
        error={!!errorMessage}
      >
        {!required && (
          <MenuItem value="">
            <em>{t('questionnaireV3.field.none')}</em>
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.key}>
            {option.label || option.key}
          </MenuItem>
        ))}
      </Select>
      <StyledErrorMessage sx={{ p: '5px' }}>{errorMessage}</StyledErrorMessage>
    </FormControl>
  );
}
