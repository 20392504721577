import React, { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux-file/hooks';
import PageLoading from '../components/PageLoading';
import { setActiveReport } from '../redux-file/esg/slice';
import { useEsgReports } from './hooks';

export default function EsgReportRoute() {
  const dispatch = useAppDispatch();
  const { reportId } = useParams();
  const { _instance: report, isError } = useEsgReports({
    id: Number(reportId),
  });

  const { activeReport } = useAppSelector((state) => state.esg);
  useEffect(() => {
    if (!!report) dispatch(setActiveReport(report));
    return () => {
      dispatch(setActiveReport(undefined));
    };
  }, [report]);

  if (isError) return <Navigate to=".." />;
  if (!activeReport) return <PageLoading />;
  return <Outlet />;
}
