import { ErrorBuilder, SchemaErrors } from 'src/utils/validation';
import { TFunction } from 'react-i18next';
import {
  CbamPerfluorocarbonsSchema,
  PerfluorocarbonsCalculationMethods,
  PerfluorocarbonsOvervoltageTechnologies,
  PerfluorocarbonsSlopeTechnologies,
} from 'src/Cbam/types';

export type FormErrors = SchemaErrors<CbamPerfluorocarbonsSchema>;

export const requiredFields: (keyof CbamPerfluorocarbonsSchema)[] = ['method'];

export const isCustomFactor = (form: CbamPerfluorocarbonsSchema) =>
  (form.method === PerfluorocarbonsCalculationMethods.SLOPE &&
    form.slope_technology === PerfluorocarbonsSlopeTechnologies.CUSTOM) ||
  (form.method === PerfluorocarbonsCalculationMethods.OVERVOLTAGE &&
    form.overvoltage_technology ===
      PerfluorocarbonsOvervoltageTechnologies.CUSTOM);

export const validateForm = (
  data: CbamPerfluorocarbonsSchema,
  t: TFunction
): FormErrors => {
  const builder = new ErrorBuilder(data);
  return builder
    .checkTruthy(requiredFields, t('validation.required'))
    .checkRule(
      [
        'anode_effect_minutes',
        'slope_technology',
        'emission_factor',
        'c2f6_to_cf4_ratio',
      ],
      (field, schema) => {
        if (schema.method === PerfluorocarbonsCalculationMethods.SLOPE) {
          return field !== null;
        }
        return true;
      },
      t('validation.required')
    )
    .checkRule(
      [
        'anode_effect_overvoltage',
        'average_current_efficiency_percentage',
        'overvoltage_technology',
        'emission_factor',
        'c2f6_to_cf4_ratio',
      ],
      (field, schema) => {
        if (schema.method === PerfluorocarbonsCalculationMethods.OVERVOLTAGE) {
          return field !== null;
        }
        return true;
      },
      t('validation.required')
    )
    .checkRule(
      ['collection_efficiency_percentage'],
      (field, schema) => {
        if (isCustomFactor(schema)) {
          return field !== null;
        }
        return true;
      },
      t('validation.required')
    )
    .build();
};
