import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import { getColorFromNumber } from 'src/Ghg/utils';

// eslint-disable-next-line import/prefer-default-export
export const StyledTableV2Cell = styled(TableCell)(
  ({ number }: { number: number }) => ({
    borderLeft: `6px solid ${getColorFromNumber(number)}`,
  })
);
