import React from 'react';
import LcaLayout from 'src/Lca/components/layout';
import Breadcrumbs from 'src/Lca/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import {
  ProductsItemsCreated,
  ProductsItemsEmpty,
} from 'src/Lca/components/productsItems';
import PageLoading from 'src/components/PageLoading';
import { BigLink } from './steps/index.styles';
import { useElementsPagination, useLcaOrganization } from 'src/common/hooks';
import { LcaProductMaterial } from '../types';
import { Typography, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import ElementsPagination from 'src/components/Pagination';
import { useLcaProducts } from '../hooks';
import DelayedTextField from 'src/components/DelayedTextField';

type Product = {
  data_collection_period: string;
  id: number;
  is_draft: boolean;
  last_updated: string;
  materials: LcaProductMaterial[];
  name: string;
  organization_id: number;
};

export default function LCAPageIndexProducts() {
  const data = [{ name: 'Products List', url: '/lca/products' }];
  const { t } = useTranslation();

  const organization = useLcaOrganization();
  const canWrite = organization.can_write_data;

  const [search, setSearch] = React.useState('');

  const { page, setPage, elementsPerPage, setElementsPerPage } =
    useElementsPagination();

  const { products, count, isLoading } = useLcaProducts(
    organization.id,
    page,
    elementsPerPage,
    search
  );

  return (
    <LcaLayout>
      <Breadcrumbs data={data} />
      <div
        style={{
          marginTop: '44px',
          marginBottom: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1">{t('lca.products')}</Typography>
        {canWrite && (
          <BigLink to="/lca/products/create">Create a new product</BigLink>
        )}
      </div>
      {canWrite && (
        <Typography variant="subtitle1" color="text.secondary">
          Click on ’Create new product’ to create a life cycle assessment of
          your product and count your product environment footprint. We will
          guide you through the process step by step.
        </Typography>
      )}
      <DelayedTextField
        onDelayedChange={setSearch}
        placeholder={t('lca.product.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{ width: '360px', my: '24px' }}
      />
      {isLoading ? (
        <PageLoading />
      ) : (
        <>
          {!count && !search && <ProductsItemsEmpty canCreate={canWrite} />}
          {!count && search && (
            <Typography variant="h5" color="text.secondary">
              {t('lca.product.noResults')}
            </Typography>
          )}
          {!!count && (
            <div>
              <div
                style={{
                  display: 'flex',
                  gap: '24px',
                  flexWrap: 'wrap',
                  wordBreak: 'break-word',
                }}
              >
                {products.map((el: Product) => (
                  <ProductsItemsCreated
                    key={el.id}
                    id={el.id}
                    name={el.name}
                    period={el.data_collection_period}
                    materials={el.materials}
                    last_edited={el.last_updated}
                    url={`/lca/products/${el.id}`}
                    canDelete={canWrite}
                  />
                ))}
              </div>
              <ElementsPagination
                totalElements={count}
                elementsPerPage={elementsPerPage}
                setElementsPerPage={setElementsPerPage}
                page={page}
                setPage={setPage}
                sx={{ mt: '24px' }}
              />
            </div>
          )}
        </>
      )}
    </LcaLayout>
  );
}
