import React from 'react';

import Field from '../components/Field';
import { ValueChainEmployeesByCountryInSchema } from 'src/Esg/Dmav2/types';
import { useAllCountries } from 'src/common/hooks';
import SaveButton from '../components/SaveButton';
import withActivationButton from '../components/withActivationButton';

interface Props {
  entityId: number;
  // eslint-disable-next-line no-unused-vars
  onSave: (data: ValueChainEmployeesByCountryInSchema) => void;
}

function NewEntityEmpolyeesByCountry(props: Props) {
  const { entityId, onSave } = props;
  const { data: countries } = useAllCountries();
  const countiresOptions = countries.map((country) => ({
    value: country.iso_code,
    label: country.name,
  }));

  const [form, setForm] = React.useState<ValueChainEmployeesByCountryInSchema>({
    esg_dma_value_chain_entity_id: entityId,
    country: null,
    count: null,
  });

  const handleFormChange = (fieldName: string, value: any) =>
    setForm({ ...form, [fieldName]: value });

  const isValid = form.country && form.count;

  return (
    <>
      <Field
        value={form.country ?? ''}
        onChange={(e) => handleFormChange('country', e.target.value)}
        options={countiresOptions}
      />
      <Field
        sx={{ mt: '-10px' }}
        isNumber
        value={form.count ?? ''}
        onChange={(e) => handleFormChange('count', e.target.value)}
      />
      <SaveButton disabled={!isValid} onSave={() => onSave(form)} />
    </>
  );
}

export default withActivationButton(NewEntityEmpolyeesByCountry);
