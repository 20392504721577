import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const StyledScore = styled(Box)`
  width: 56px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
