import React from 'react';
import { useParams } from 'react-router-dom';
import { useLanguage } from 'src/common/hooks';
import {
  useEsgSubTopic,
  useEsgStakeholderAnswer,
  useEsgTopic,
} from 'src/Esg/Dmav2/hooks';
import CustomSelect from 'src/components/CustomSelect';

import { Divider, Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { StyledCell, StyledRow, StyledSubTopicCell } from './styles';
import {
  EsgStakeholderAnswerInSchema,
  EsgStakeholderAnswerOutSchema,
} from '../../types';
import { StyledFlex } from 'src/components/StyledFlex';
import { HelpOutline } from '@mui/icons-material';
import LightTooltip from 'src/components/LightTooltip';

export default function QuestionnaireTable() {
  const { t } = useLanguage();
  const { stakeholderSurveySubtopics: esgSubTopics } = useEsgSubTopic();
  const { data: esgTopics } = useEsgTopic();

  const { token } = useParams();
  const token_data = jwtDecode<any>(token || '');

  const {
    data: answers,
    update,
    create,
    delete: deleteAnswer,
  } = useEsgStakeholderAnswer();

  const changeAnswer = (
    data: EsgStakeholderAnswerInSchema | EsgStakeholderAnswerOutSchema
  ) => {
    if ('id' in data && data.id) {
      if (data.positive === null && data.negative === null) {
        deleteAnswer.mutate(data.id);
      } else {
        update.mutate(data);
      }
    } else {
      create.mutate(data);
    }
  };

  const answerMap = new Map(
    answers.map((answer) => [answer.esg_dma_sub_topic_id, answer])
  );

  if (!esgTopics || !esgSubTopics) {
    return <div>Loading...</div>;
  }

  let topicsWithSubTopics = esgTopics?.map((topic) => ({
    ...topic,
    subtopics: esgSubTopics?.filter(
      (subtopic) => subtopic.topic_id.toString() === topic.id.toString()
    ),
  }));

  topicsWithSubTopics = topicsWithSubTopics.filter(
    (topic) => topic.subtopics.length > 0
  );

  const translatedColumn = (key: string) =>
    t(`esg.dma.survey.table.columns.${key}`);

  const getImpactOptions = [
    { value: 0, label: t('esg.dma.survey.table.selectOptions.0') },
    { value: 1, label: t('esg.dma.survey.table.selectOptions.1') },
    { value: 2, label: t('esg.dma.survey.table.selectOptions.2') },
    { value: 3, label: t('esg.dma.survey.table.selectOptions.3') },
    { value: null, label: t('esg.dma.survey.table.selectOptions.null') },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Divider />
      {/* Header */}
      <StyledRow>
        <StyledSubTopicCell>
          <Typography variant="captionText">
            {translatedColumn('subtopic')}
          </Typography>
        </StyledSubTopicCell>
        <StyledCell>
          <Typography variant="captionText">
            {translatedColumn('positiveImpact')}
          </Typography>
        </StyledCell>
        <StyledCell>
          <Typography variant="captionText">
            {translatedColumn('negativeImpact')}
          </Typography>
        </StyledCell>
      </StyledRow>

      {/* Data */}
      {topicsWithSubTopics?.map((topic) => (
        <React.Fragment key={topic.id}>
          {/* Topic Row */}
          <div
            style={{
              backgroundColor: topic.color,
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '24px',
            }}
          >
            <Typography variant="h6">
              {topic.key}: {topic.name}
            </Typography>
          </div>

          {/* Subtopic Rows */}
          {topic.subtopics?.map((subtopic) => {
            const answer = answerMap.get(subtopic.id) || {
              positive: null,
              negative: null,
              id: null,
            };

            const row = {
              positive: answer.positive,
              negative: answer.negative,
              esg_dma_sub_topic_id: subtopic.id,
              esg_dma_topic_id: subtopic.topic_id,
              esg_stakeholder_survey_id:
                token_data.data.esg_stakeholder_survey_id,
              ...(answer.id !== null && { id: answer.id }), // Include 'id' only if it's not null
            };

            const tPath = `esg.stakeholderSurvey.subtopicHelpTextByFixtureId.${subtopic.id}`;
            const helpText = t(tPath, '');

            return (
              <>
                <Divider />
                <StyledRow key={subtopic.id}>
                  <StyledSubTopicCell>
                    <StyledFlex>
                      <Typography variant="subtitle2">
                        {subtopic.description}
                      </Typography>
                      {helpText && (
                        <LightTooltip
                          title={
                            <Typography variant="captionText">
                              {helpText}
                            </Typography>
                          }
                          placement="right-end"
                        >
                          <HelpOutline fontSize="small" sx={{ mx: 1 }} />
                        </LightTooltip>
                      )}
                    </StyledFlex>
                  </StyledSubTopicCell>
                  <StyledCell>
                    <CustomSelect
                      key={`${row.esg_dma_topic_id}-positive`}
                      value={row.positive}
                      options={getImpactOptions}
                      onChange={(value) =>
                        changeAnswer({ ...row, positive: value })
                      }
                    />
                  </StyledCell>
                  <StyledCell>
                    <CustomSelect
                      key={`${row.esg_dma_topic_id}-positive`}
                      value={row.negative}
                      options={getImpactOptions}
                      onChange={(value) =>
                        changeAnswer({ ...row, negative: value })
                      }
                    />
                  </StyledCell>
                </StyledRow>
              </>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
}
