import React from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FieldValue, UniversalField } from '../types';
import { StyledErrorMessage } from '../../index.styles';

export default function ToggleButtonGroupField(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const { required, fieldoption_set: fieldOptions } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* Backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* frontend side validation */
  const _validate = (_value: FieldValue) => {
    // required field
    if (required && _value === null) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  const handleToggle = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: FieldValue
  ) => {
    const _value = newValue === '' ? null : newValue;
    _validate(_value);
    onChange(_value);
  };

  const booleanOptions = [
    {
      id: `${field.id}-true`,
      key: true,
      label: t('questionnaireV3.field.yes'),
    },
    {
      id: `${field.id}-false`,
      key: false,
      label: t('questionnaireV3.field.no'),
    },
  ];

  const options = field.type === 'boolean' ? booleanOptions : fieldOptions;

  return (
    <div style={{ marginBottom: '16px' }}>
      <ToggleButtonGroup
        fullWidth
        exclusive
        value={value}
        onChange={handleToggle}
        disabled={disabled}
      >
        {options?.map((option) => (
          <ToggleButton
            key={option.id}
            // @ts-ignore
            value={option.key}
            disabled={disabled}
            className={!!errorMessage ? 'Mui-error' : ''}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <div style={{ height: '5px' }} />
      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
    </div>
  );
}
