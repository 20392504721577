import { styled } from '@mui/material';
import themeColors from 'src/theme/colors';

export const StyledContainer = styled('div')``;

export const StyledFieldReference = styled('div')`
  position: absolute;
  top: 0;
  right: -96px;
  border: 1px solid ${themeColors.grayInactive};
  border-radius: 10px;
  padding: 2px 6px;
  text-align: left;
`;
