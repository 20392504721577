import {
  ContentCopy,
  DeleteOutline,
  Edit,
  MoreTime,
  Preview,
  Send,
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LightTooltip from 'src/components/LightTooltip';
import { StyledTableV2Cell } from '../styles';

interface Props {
  id: number;
  onEdit?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  onSendAgain?: () => void;
  onAddToReportingPeriod?: null | (() => void);
  onPreview?: null | (() => void);
}

export default function ButtonsCell({
  id,
  onEdit,
  onCopy,
  onDelete,
  onSendAgain,
  onAddToReportingPeriod,
  onPreview,
}: Props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'ghg.tableV2.buttons' });
  return (
    <StyledTableV2Cell number={id}>
      <Box display="flex" flexDirection="row">
        {onEdit && (
          <LightTooltip title={t('edit')}>
            <IconButton onClick={onEdit}>
              <Edit />
            </IconButton>
          </LightTooltip>
        )}
        {onCopy && (
          <LightTooltip title={t('copy')}>
            <IconButton onClick={onCopy}>
              <ContentCopy />
            </IconButton>
          </LightTooltip>
        )}
        {onDelete && (
          <LightTooltip title={t('delete')}>
            <IconButton onClick={onDelete}>
              <DeleteOutline />
            </IconButton>
          </LightTooltip>
        )}
        {onSendAgain && (
          <LightTooltip title={t('sendAgain')}>
            <IconButton onClick={onSendAgain}>
              <Send />
            </IconButton>
          </LightTooltip>
        )}
        {onAddToReportingPeriod !== undefined && (
          <LightTooltip title={t('addToReportingPeriod')}>
            <IconButton
              disabled={!onAddToReportingPeriod}
              onClick={onAddToReportingPeriod || (() => {})}
            >
              <MoreTime />
            </IconButton>
          </LightTooltip>
        )}
        {onPreview !== undefined && (
          <LightTooltip title={t('preview')}>
            <IconButton disabled={!onPreview} onClick={onPreview || (() => {})}>
              <Preview />
            </IconButton>
          </LightTooltip>
        )}
      </Box>
    </StyledTableV2Cell>
  );
}
