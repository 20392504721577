import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface AddButtonHolderProps {
  firstChild: React.ReactNode;
  secondChild?: React.ReactNode;
}

const AddButtonHolderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;

  & > p {
    height: 100%;
    margin: 0 10px;
  }

  & > div {
    margin: 0 0;
  }
`;

export default function AddButtonHolder({
  firstChild,
  secondChild,
}: AddButtonHolderProps) {
  const { t } = useTranslation();
  return (
    <AddButtonHolderDiv>
      {firstChild}
      {!!secondChild && (
        <>
          <p>{t('popup.excel.or')}</p>
          {secondChild}
        </>
      )}
    </AddButtonHolderDiv>
  );
}
