import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import AddProductButton from 'src/components/AddProductButton';
import { toast } from 'react-toastify';
import ConfirmationModal from 'src/components/ConfirmationModal';
import ProductPeriodModal from '../components/ProductPeriodModal';
import {
  GOODS_AND_SERVICES_OR_CAPITAL_GOODS,
  GhgSupplierGoods,
} from '../../interfaces';
import { useSupplierGoods } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useGhgOrganization } from '../../../common/hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import TableHeader from 'src/Ghg/components/TableV2/TableHeader';
import ButtonsCell from 'src/Ghg/components/TableV2/ButtonsCell';
import { StyledTableV2Cell } from 'src/Ghg/components/TableV2/styles';

interface Props {
  data: GhgSupplierGoods[];
  onAddNewRow: () => void;
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

const tKey = 'ghg.scope3GoodsAndServices.goodsFromSuppliersTable';

export default function ProductTable({ data, onAddNewRow, type }: Props) {
  const { t } = useTranslation();
  const organization = useGhgOrganization();
  const { remove } = useSupplierGoods(organization.id);

  const [deleteRow, setDeleteRow] = React.useState<GhgSupplierGoods | null>(
    null
  );

  const [editRow, setEditRow] = React.useState<GhgSupplierGoods | undefined>(
    undefined
  );

  const handleDeleteClick = (row: GhgSupplierGoods) => setDeleteRow(row);

  const handleProductPeriodDelete = () => {
    if (deleteRow)
      remove
        .mutateAsync(deleteRow.id)
        .then(() =>
          toast.success(
            t(
              'ghg.scope3GoodsAndServices.toasts.productPeriodDeleted'
            ) as string
          )
        );
    setDeleteRow(null);
  };

  const handleEditClick = (row: GhgSupplierGoods) => setEditRow(row);

  const title =
    type === GOODS_AND_SERVICES_OR_CAPITAL_GOODS.GOODS_AND_SERVICES
      ? t('ghg.scope3GoodsAndServices.goodsAnsServicesFromSuppliersTableTitle')
      : t('ghg.scope3GoodsAndServices.capitalGoodsFromSuppliersTableTitle');

  return (
    <>
      <Typography variant="h3" sx={{ mt: '32px', mb: '32px' }}>
        {title}
      </Typography>
      <StyledPanel>
        <TableContainer>
          <Table>
            <TableHeader
              headerKeys={[
                `quantity.${type}`,
                `productName.${type}`,
                'supplierName',
                'emissionShare',
              ]}
            />
            <TableBody>
              {(data || []).map((row) => (
                <TableRow key={row.id}>
                  <StyledTableV2Cell number={row.id}>
                    <Typography>{row.amount}</Typography>
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={row.id}>
                    <Typography>{row.survey.product_name}</Typography>
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={row.id}>
                    <Typography>{row.survey.supplier_name}</Typography>
                  </StyledTableV2Cell>
                  <StyledTableV2Cell number={row.id}>
                    <Typography>-</Typography>
                  </StyledTableV2Cell>
                  <ButtonsCell
                    id={row.id}
                    onEdit={() => handleEditClick(row)}
                    onDelete={() => handleDeleteClick(row)}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPanel>

      <AddProductButton onClick={onAddNewRow} />
      <br />

      <ProductPeriodModal
        key={editRow?.id}
        surveyAnswerId={editRow?.survey_answer_id as number}
        open={!!editRow}
        onClose={() => setEditRow(undefined)}
        instance={editRow}
      />

      <ConfirmationModal
        open={!!deleteRow}
        onClose={() => setDeleteRow(null)}
        onFalse={() => setDeleteRow(null)}
        onTrue={handleProductPeriodDelete}
        titleKey={`${tKey}.deleteConfirmation.title`}
        textKey={`${tKey}.deleteConfirmation.text`}
      />
    </>
  );
}
