import React from 'react';

import { Box, TableHead, TableRow, Typography } from '@mui/material';

import { useEsgUserPermissions } from '../hooks';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import PageLoading from 'src/components/PageLoading';
import TableCollapsable from './TableCollapsable';
import { useTranslation } from 'react-i18next';
import TableBody from './TableBody';
import { QuestionnaireMethodology } from 'src/Esg/types';
import QuestionnaireMethodologySelector from 'src/Superadmin/components/Panels/QuestionnaireMethodologySelector';

interface Props {
  userId: number;
  reportYear: number | null;
}

export default function EsgUserPermissionTable(props: Props) {
  const { userId, reportYear } = props;
  const { t } = useTranslation();
  const { isLoading, options, userOrganizations } =
    useEsgUserPermissions(userId);
  const reports = options.reports.filter((r) => r.period === reportYear);

  const [questionnaireMethodology, setQuestionnaireMethodology] =
    React.useState<QuestionnaireMethodology>(
      QuestionnaireMethodology.ORIGINAL_QV3_2023_2024
    );

  if (isLoading) return <PageLoading />;
  if (reports.length === 0)
    return (
      <Typography>
        {t(
          'superadmin.settings.tabs.users.usersTable.permissions.other.noReportsAvailable'
        )}
      </Typography>
    );
  if (!userOrganizations.length)
    return (
      <Typography>
        {t(
          'superadmin.settings.tabs.users.usersTable.permissions.other.noOrganizationsReportAccess'
        )}
      </Typography>
    );

  return (
    <>
      <QuestionnaireMethodologySelector
        value={questionnaireMethodology}
        onChange={setQuestionnaireMethodology}
      />
      <Box sx={{ overflowX: 'scroll' }}>
        <TableCollapsable isExpanded>
          <TableHead>
            <TableRow>
              <StyledTableStickyCell>
                {t(
                  'superadmin.settings.tabs.users.usersTable.permissions.headers.subtopic'
                )}
              </StyledTableStickyCell>
              {userOrganizations.map((organization) => (
                <StyledTableCell key={`head-${organization.id}`}>
                  {organization.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            reportYear={reportYear}
            userId={userId}
            questionnaireMethodology={questionnaireMethodology}
          />
        </TableCollapsable>
      </Box>
    </>
  );
}
