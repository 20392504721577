import React from 'react';
import { format } from 'date-fns';

import { Button, IconButton, TableCell, TableRow } from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import ResponsilityUserSelect from './ResponsilityUserSelect';
import { useIndicatorsResponsibilites } from 'src/Esg/hooks';
import { EsgReportSchemaOut } from 'src/Esg/api.types';
import { IndicatorSchema } from 'src/Esg/types';
import { useTranslation } from 'react-i18next';

interface Props {
  report: EsgReportSchemaOut;
  indicator: IndicatorSchema;
}

export default function AddNewIndicatorResponsibility(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.administrationPanel',
  });
  const { report, indicator } = props;
  const responsibilities = useIndicatorsResponsibilites(report.id);

  const [active, setActive] = React.useState(false);
  const [date, setDate] = React.useState<Date>(new Date());

  const handleClose = () => {
    setDate(new Date());
    setActive(false);
  };

  const handleAppUserClick = (appUserId: number) =>
    responsibilities.create
      .mutateAsync({
        app_user_id: appUserId,
        deadline: format(date, 'yyyy-MM-dd'),
        report_id: report.id,
        esg_questionnaire_id: indicator.id,
      })
      .then(handleClose);

  if (!active)
    return (
      <TableCell size="small" colSpan={3} sx={{ pl: 0, verticalAlign: 'top' }}>
        <Button
          startIcon={<Add />}
          size="small"
          variant="secondary"
          onClick={() => setActive(true)}
        >
          {t('table.addResponsibleUsers')}
        </Button>
      </TableCell>
    );

  return (
    <>
      <TableCell size="small" sx={{ verticalAlign: 'top' }}>
        <DatePicker
          value={date}
          onChange={(val) => setDate(val || new Date())}
          slotProps={{
            textField: { size: 'small', inputProps: { readOnly: true } },
          }}
        />
      </TableCell>
      <TableCell size="small" sx={{ verticalAlign: 'top' }}>
        <ResponsilityUserSelect
          report={report}
          indicator={indicator}
          onChange={handleAppUserClick}
        />
      </TableCell>
      <TableCell size="small" sx={{ pl: 0, pr: 0, verticalAlign: 'top' }}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </TableCell>
    </>
  );
}
