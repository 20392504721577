import React from 'react';
import { TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';

import { EsgDmaSubTopicHierarchySchema } from '../../../types';
import { useDmaImpacts, useEsgDmas } from '../../../hooks';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import ImpactRow from './ImpactRow';
import AddImpactRow from './AddImpactRow';
import { useEsgReports } from 'src/Esg/hooks';

interface Props {
  subtopic: EsgDmaSubTopicHierarchySchema;
}

export default function SubTopicRow(props: Props) {
  const { subtopic } = props;
  const [collapsed, setCollapsed] = React.useState(false);
  const { reportId } = useParams();
  const { _instance: esgReport } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma } = useEsgDmas(
    { id: esgReport?.esg_dma_id },
    { enabled: !!esgReport }
  );
  const { _data: impacts } = useDmaImpacts(
    { esg_dma_id: dma?.id },
    { enabled: !!dma }
  );

  // subtopic should have at least 1 virtual subsubtopic
  const subSubTopicId = subtopic.subsubtopics[0]?.id;
  const data = impacts.filter(
    (i) => i.esg_dma_sub_sub_topic_id === subSubTopicId
  );
  return (
    <>
      <TableRow>
        <CollapsableButtonCell
          colSpan={11}
          text={subtopic.description}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sx={{ padding: '8px 8px 8px 32px' }}
        />
      </TableRow>

      {!collapsed &&
        data.map((impact) => <ImpactRow key={impact.id} impact={impact} />)}

      {!collapsed && <AddImpactRow subSubTopicId={subSubTopicId} />}
    </>
  );
}
