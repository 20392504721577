import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeSurveyData } from '../../../redux-file/questionnaires/interfaces';
import { companiesColors } from '../SuperDashboard/data';
import {
  StyledTh,
  StyledTd,
  StyledHeader,
  PreviewBox,
  Title,
} from './index.styles';
import { EmployeeSurveyHideSectionTags } from './index.interfaces';

export default function FormPreview({
  forms,
  sectionsToHide,
}: {
  forms: EmployeeSurveyData;
  sectionsToHide: EmployeeSurveyHideSectionTags[];
}) {
  const { t } = useTranslation();
  return (
    <div>
      {!sectionsToHide.includes(
        EmployeeSurveyHideSectionTags.HIDE_SECTION_1
      ) && (
        <div>
          <Title>
            {t('employee-survey.question1')}
            <span className="important-title">{' *'}</span>
          </Title>
          <PreviewBox>
            <StyledHeader>
              <StyledTh width={33}>
                {t('employee-survey.preview-typeof-transport')}
              </StyledTh>
              <StyledTh width={33}>
                {t('employee-survey.preview-times_per_week')}
              </StyledTh>
              <StyledTh width={33}>
                {t('employee-survey.preview-average_distance_one_way_km')}
              </StyledTh>
            </StyledHeader>

            <div>
              {forms.commute.map((el, i) => (
                <StyledHeader key={i}>
                  <StyledTd width={33} color={companiesColors[i]}>
                    {t(`employee-survey.${el.type_of_commute_transport}`)}
                  </StyledTd>
                  <StyledTd width={33} color={companiesColors[i]}>
                    {el.times_per_week}
                  </StyledTd>
                  <StyledTd width={33} color={companiesColors[i]}>
                    {el.average_distance_one_way_km}
                  </StyledTd>
                </StyledHeader>
              ))}
            </div>
          </PreviewBox>
        </div>
      )}

      {!sectionsToHide.includes(
        EmployeeSurveyHideSectionTags.HIDE_SECTION_2
      ) && (
        <>
          <Title>
            {t('employee-survey.question2')}
            <span className="important-title">{' *'}</span>
          </Title>
          <PreviewBox>
            <StyledHeader>
              <StyledTh width={100}>
                {t('employee-survey.preview-home-office')}
              </StyledTh>
            </StyledHeader>
            <div>
              <StyledHeader>
                <StyledTd width={100} color={companiesColors[0]}>
                  {forms.average_number_of_days_on_home_office_per_week}
                </StyledTd>
              </StyledHeader>
            </div>
          </PreviewBox>
        </>
      )}
      {!sectionsToHide.includes(
        EmployeeSurveyHideSectionTags.HIDE_SECTION_3
      ) && (
        <>
          <Title>
            {t('employee-survey.question3')}
            <span className="important-title">{' *'}</span>
          </Title>
          <PreviewBox>
            <StyledHeader>
              <StyledTh width={33}>
                {t('employee-survey.preview-typeof-transport')}
              </StyledTh>
              <StyledTh width={33}>
                {t('employee-survey.preview-number-of-trips')}
              </StyledTh>
              <StyledTh width={33}>
                {t('employee-survey.preview-average_distance_per_trip_km')}
              </StyledTh>
            </StyledHeader>

            <div>
              {forms.business_trips.map((el, i) => (
                <StyledHeader key={i}>
                  <StyledTd width={33} color={companiesColors[i]}>
                    {t(`employee-survey.${el.type_of_trip_transport}`)}
                  </StyledTd>
                  <StyledTd width={33} color={companiesColors[i]}>
                    {el.times_in_period}
                  </StyledTd>
                  <StyledTd width={33} color={companiesColors[i]}>
                    {el.average_distance_per_trip_km}
                  </StyledTd>
                </StyledHeader>
              ))}
            </div>
          </PreviewBox>
        </>
      )}

      {!sectionsToHide.includes(
        EmployeeSurveyHideSectionTags.HIDE_SECTION_4
      ) && (
        <>
          <Title>{t('employee-survey.question4')}</Title>
          <PreviewBox>
            <StyledHeader>
              <StyledTh width={66}>{t(`employee-survey.country`)}</StyledTh>
              <StyledTh width={33}>
                {t(`employee-survey.number-of-nights`)}
              </StyledTh>
            </StyledHeader>
            <div>
              {forms.stays.length === 0 ? (
                <StyledHeader>
                  <StyledTd width={66} color={companiesColors[0]}>
                    {t(`employee-survey.preview-country-no-data`)}
                  </StyledTd>
                  <StyledTd width={33} color={companiesColors[0]}>
                    {' '}
                  </StyledTd>
                </StyledHeader>
              ) : (
                <>
                  {forms.stays.map((el, i) => (
                    <StyledHeader key={i}>
                      <StyledTd width={66} color={companiesColors[i]}>
                        {el.country}
                      </StyledTd>
                      <StyledTd width={33} color={companiesColors[i]}>
                        {el.number_of_nights}
                      </StyledTd>
                    </StyledHeader>
                  ))}
                </>
              )}
            </div>
          </PreviewBox>
        </>
      )}
    </div>
  );
}
