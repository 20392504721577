import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import {
  EsgDmaSubtopicOutSchema,
  StakeholdersSurveySummarySchema,
} from 'src/Esg/Dmav2/types';

interface SubTopicRowProps {
  subTopic: EsgDmaSubtopicOutSchema;
  summary: StakeholdersSurveySummarySchema[];
}

export default function SubTopicRow(props: SubTopicRowProps) {
  const row = props.summary.find(
    (s) => s.esg_dma_sub_topic_id === props.subTopic.id
  );

  const formatPercentage = (value: number | null) => {
    if (value === null) {
      return '-';
    }
    return `${(100 * value).toFixed(0)}%`;
  };

  const formatScore = (value: number | null) => {
    if (value === null) {
      return '-';
    }
    return value.toFixed(2);
  };

  if (!row) {
    return (
      <TableRow key={props.subTopic.id}>
        <TableCell>{props.subTopic.description}</TableCell>
        {Array.from({ length: 11 }).map((_, i) => (
          <TableCell key={i}>-</TableCell>
        ))}
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{props.subTopic.description}</TableCell>
      <TableCell>{formatPercentage(row.positive_0_avg)}</TableCell>
      <TableCell>{formatPercentage(row.positive_1_avg)}</TableCell>
      <TableCell>{formatPercentage(row.positive_2_avg)}</TableCell>
      <TableCell>{formatPercentage(row.positive_3_avg)}</TableCell>
      <TableCell>{formatScore(row.positive_avg)}</TableCell>
      <TableCell>{formatPercentage(row.negative_0_avg)}</TableCell>
      <TableCell>{formatPercentage(row.negative_1_avg)}</TableCell>
      <TableCell>{formatPercentage(row.negative_2_avg)}</TableCell>
      <TableCell>{formatPercentage(row.negative_3_avg)}</TableCell>
      <TableCell>{formatScore(row.negative_avg)}</TableCell>
      <TableCell>{formatScore(row.score)}</TableCell>
    </TableRow>
  );
}
