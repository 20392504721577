import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from 'src/Ghg/interfaces';
import { getPeriodDate, questionForGoodType } from 'src/Ghg/utils';
import { useGoodsAndServices } from '../../hooks';
import { useGhgOrganization } from 'src/common/hooks';
import { GoodsAndServicesData } from '../../types';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { Typography } from '@mui/material';
import MethodDescriptionPanel from '../../components/MethodDescriptionPanel';
import {
  PreviewBox,
  StyledHeader,
  StyledTh,
} from 'src/views/Pages/Questionnaire/index.styles';
import AddButtonHolder from 'src/views/components/Questionnaire/AddButtonHolder';
import GoodsAndServicesForm from 'src/views/components/Questionnaire/forms/goodsAndServicesForm';
import GoodsPreview from 'src/views/components/Questionnaire/preview/goodsPreview';
import AddProductButton from 'src/components/AddProductButton';
import UploadExcelButton from 'src/components/UploadExcelButton';

interface Props {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

export default function SpendingMethodBox({ type }: Props) {
  const organization = useGhgOrganization();
  const [newForm, setNewForm] = useState<boolean>(true);
  const [showForm, setShowForm] = useState<boolean>(false);

  const [active, setActive] = useState<undefined | number>(undefined);

  const pgInit = {
    emission_factor_is_known: false,
    good_data_factor_unknown: {
      spending_pln_per_period: '',
      product_industry: '',
    },
    good_data_factor_known: {
      factor: '',
      amount: '',
      unit_label: '',
    },
    comment: '',
    added_by: '',
    emission_kg_eq_co2: '',
    fraction: '',
    name_label: '',
  };

  const [state, setState] = useState([pgInit]);

  const header_ref = useRef(null);

  const {
    t,
    i18n: { t: tRoot },
  } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}`,
  });
  const { t: methodTranslation } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}.method4`,
  });

  const period = getPeriodDate(organization.co2.reporting_freq);

  const methodBoxVisible = newForm && !showForm;

  const api = useGoodsAndServices({
    organization_id: organization.id,
    period,
    type,
  });

  const data = api.query.data?.data;

  useEffect(() => {
    if (data === undefined) return;

    setNewForm(data.length === 0);
    setShowForm(false);

    setState(data.length !== 0 ? (data as any) : [pgInit]);
  }, [data]);

  const send = async (data: Partial<GoodsAndServicesData>) => {
    const baseData = api.query.data;
    if (baseData === undefined) return;
    return await api.update.mutateAsync({
      ...baseData,
      ...data,
    });
  };

  const combineGoodsData = (goodsData: any, newData: any) => {
    const validGoods =
      goodsData?.length === 1 && goodsData[0].emission_kg_eq_co2 === ''
        ? []
        : goodsData;
    return [...newData, ...validGoods];
  };

  if (api.isError) return <SomethingWentWrong />;
  if (api.isLoading) return <PageLoading />;

  return (
    <>
      <div ref={header_ref} />
      {!methodBoxVisible && (
        <Typography variant="h3" sx={{ mb: '24px' }}>
          {t('method4.title')}
        </Typography>
      )}
      {newForm && (
        <div>
          {!showForm ? (
            <MethodDescriptionPanel
              t={methodTranslation}
              onButtonClick={() => setShowForm(true)}
            />
          ) : (
            <PreviewBox>
              {state.map((_, id) => (
                <GoodsAndServicesForm
                  setNewForm={setNewForm}
                  variant={type}
                  active={active}
                  setActive={setActive}
                  key={id}
                  id={id}
                  state={state}
                  setState={setState}
                  update={(data) => send({ data })}
                />
              ))}
            </PreviewBox>
          )}
        </div>
      )}
      {!newForm && (
        <PreviewBox>
          <StyledHeader>
            <StyledTh width={33}>{t('table.column1')}</StyledTh>
            <StyledTh width={33}>{t('table.column4')}</StyledTh>
            <StyledTh width={33}>
              {tRoot(
                'direct-emission.stationary-combustion.table.emission_shared'
              )}
            </StyledTh>
            <StyledTh width={0}>{tRoot('sold-products.preview.edit')}</StyledTh>
          </StyledHeader>
          <div>
            {state.map((_, id) => {
              if (active === id) {
                return (
                  <GoodsAndServicesForm
                    setNewForm={setNewForm}
                    variant={type}
                    active={active}
                    key={id}
                    id={id}
                    state={state}
                    setState={setState}
                    setActive={setActive}
                    update={(data) => send({ data })}
                  />
                );
              } else {
                return (
                  <GoodsPreview
                    setState={setState}
                    key={id}
                    state={state}
                    id={id}
                    setActive={setActive}
                    emissionShares={api.queryEmissions.data?.data}
                  />
                );
              }
            })}
          </div>
        </PreviewBox>
      )}
      {!methodBoxVisible && (
        <AddButtonHolder
          firstChild={
            <AddProductButton
              onClick={() => {
                if (newForm && state.length > 0) return;
                setState([pgInit, ...state]);
                setActive(0);
                // @ts-ignore
                header_ref?.current?.scrollIntoView?.({
                  behavior: 'smooth',
                });
              }}
            />
          }
          secondChild={
            <UploadExcelButton
              name={t('title')}
              slug="GoodData"
              fullData={state}
              combineData={combineGoodsData}
              sendData={(data: any) => send({ data })}
              refresh={api.refetch}
            />
          }
        />
      )}
    </>
  );
}
