import React from 'react';
import Container from '../components/Container';
import { useTranslation } from 'react-i18next';

import assets from '../assets';
import FormattedSurvey from '../components/FormattedSurvey';
import Title from '../components/Title';
import { useEsgStakeholderSurveyMetadata } from '../../hooks';

export default function StakeholderSurveyEnd() {
  const { t } = useTranslation();
  const { metadata } = useEsgStakeholderSurveyMetadata();

  return (
    <Container header={<img src={assets.outro.image} />}>
      <div>
        <Title
          title={t('esg.stakeholderSurvey.title')}
          period={metadata.report_period}
        />
        <FormattedSurvey
          text={metadata.outro_text || t('esg.stakeholderSurvey.outro.message')}
        />
      </div>
    </Container>
  );
}
