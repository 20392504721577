import React from 'react';
import { useEsgDmaValueChainEmployeesByCountry } from 'src/Esg/Dmav2/hooks';
import EntityEmployeesByCountryForm from '../Forms/EntityEmployeesByCountry';
import NewEntityEmpolyeesByCountryForm from '../Forms/EntityEmployeesByCountry/NewEmplyeesByCountry';
import { Box, Typography } from '@mui/material';
import { useLanguage } from 'src/common/hooks';

interface Props {
  entityId: number;
}

export default function EntityEmployeesByCountries(props: Props) {
  const { entityId } = props;
  const { t } = useLanguage({ keyPrefix: 'esg.dma.step1.valueChain' });

  const { _data, create, update, _delete } =
    useEsgDmaValueChainEmployeesByCountry({
      entity_id: entityId,
    });

  const noData = _data.length === 0;

  return (
    <Box position="relative" width="100%" mb="10px">
      <Typography position="absolute" p="10px" width="25%" variant="subtitle2">
        {t('employeeCount')}
      </Typography>

      {_data.map((entityEmplyeesByCountry) => (
        <EntityEmployeesByCountryForm
          key={JSON.stringify(entityEmplyeesByCountry)}
          entityEmplyeesByCountry={entityEmplyeesByCountry}
          onSave={(data) => update.mutate(data)}
          onDelete={(id) => _delete.mutate(id)}
        />
      ))}
      <NewEntityEmpolyeesByCountryForm
        key={_data.length}
        entityId={entityId}
        isActive={noData}
        buttonSx={{ ml: '25%' }}
        onSave={(data) => create.mutate(data)}
      />
    </Box>
  );
}
