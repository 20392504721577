import React from 'react';
import { Table, TableBody } from '@mui/material';

import { useDmaHierarchy } from '../../hooks';
import Head from './Head';
import TitleRow from './Rows/TitleRow';
import FullScreenPanel from 'src/Esg/components/StyledPanelFullscreen';

export default function Step3Table() {
  const { stages23Hierarchy } = useDmaHierarchy();
  return (
    <FullScreenPanel>
      <Table stickyHeader sx={{ minWidth: '1330px' }}>
        <Head />
        <TableBody>
          {Object.entries(stages23Hierarchy).map(([title, data]) => (
            <TitleRow key={title} title={title} data={data} />
          ))}
        </TableBody>
      </Table>
    </FullScreenPanel>
  );
}
