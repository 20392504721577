import React, { useEffect, useState } from 'react';
import EmployeeSurveyHeader from '../../components/EmployeeSurvey/SurveyHeader';
import {
  CalendarBox,
  Container,
  EntryBox,
  ErrorTitle,
  TextBox,
  Wrapper,
} from './index.styles';
import forestImage from '../../../images/forest.png';
import { useAppSelector } from '../../../redux-file/hooks';
import Formset from './formset';
import decoder, { InvalidTokenError } from 'jwt-decode';
import { JWTToken } from './index.interfaces';
import PageLoading from '../../../components/PageLoading';
import themeColors from '../../../theme/colors';
import ChangeLanguage from '../../../components/TopBar/ChangeLanguage';
import { useLanguage } from 'src/common/hooks';
import { useSearchParams } from 'react-router-dom';
import SomethingWentWrong from 'src/components/SomethingWentWrong';

const surveyData = [
  {
    title: 'employee-survey.survey.1.title',
    text: 'employee-survey.survey.1.text',
  },
  {
    title: 'employee-survey.survey.2.title',
    text: 'employee-survey.survey.2.text',
  },
  {
    title: 'employee-survey.survey.3.title',
    text: 'employee-survey.survey.3.text',
  },
  {
    title: 'employee-survey.survey.4.title',
    text: 'employee-survey.survey.4.text',
  },
];

export default function EmployeeSurveyForm() {
  const { t, language } = useLanguage();
  const { sentEmployeeData, sentEmployeeDataLoading } = useAppSelector(
    (state) => state.questionnaire
  );
  const [page, setPage] = useState(0);
  const [token, setToken] = useState<JWTToken | undefined>();
  const [tokenError, setTokenError] = useState<boolean>(false);
  const [searchParams, _] = useSearchParams();
  const apiKey = searchParams.get('api-key') || '';

  useEffect(() => {
    try {
      setToken(decoder<JWTToken>(apiKey));
      setTokenError(false);
    } catch (e) {
      if (e instanceof InvalidTokenError) {
        setTokenError(true);
      } else {
        throw e;
      }
    }
  }, [apiKey]);

  useEffect(() => {
    if (token && token.exp < Date.now() / 1000) {
      setPage(3);
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  if (tokenError) {
    return <SomethingWentWrong />;
  }
  if (!token) {
    return <PageLoading />;
  }

  const companyName = token.organization_name || 'Your company name';
  const date = token.period.split('-');

  return (
    <Container>
      <EmployeeSurveyHeader companyName={companyName} />
      {page !== 2 && (
        <EntryBox>
          <img src={forestImage} alt="envirly forest" />
          <span>{t('employee-survey.page-title')}</span>
          <TextBox>
            <h1>{t(surveyData[page].title)}</h1>
            <Wrapper>
              <CalendarBox error={false}>
                <svg
                  style={{ marginRight: '12px' }}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1941_181802)">
                    <path
                      d="M13.334 2.0013H12.6673V0.667969H11.334V2.0013H4.66732V0.667969H3.33398V2.0013H2.66732C1.93398 2.0013 1.33398 2.6013 1.33398 3.33464V14.0013C1.33398 14.7346 1.93398 15.3346 2.66732 15.3346H13.334C14.0673 15.3346 14.6673 14.7346 14.6673 14.0013V3.33464C14.6673 2.6013 14.0673 2.0013 13.334 2.0013ZM13.334 14.0013H2.66732V5.33464H13.334V14.0013Z"
                      fill={themeColors.greenBrand}
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1941_181802">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>
                  {date[1]} {date[0]}
                </span>
              </CalendarBox>
              <div>
                <ChangeLanguage localOnly />
              </div>
            </Wrapper>
            <h5>{t(surveyData[page].text)}</h5>
          </TextBox>
        </EntryBox>
      )}
      {page === 2 && (
        <div>
          {sentEmployeeDataLoading ? (
            <PageLoading />
          ) : (
            <EntryBox>
              <img src={forestImage} alt="envirly forest" />
              <span>{t('employee-survey.page-title')}</span>
              <TextBox>
                {sentEmployeeData ? (
                  <h1>{t('employee-survey.survey.success.title')}</h1>
                ) : (
                  <ErrorTitle>
                    {t('employee-survey.survey.error.title')}
                  </ErrorTitle>
                )}
                <Wrapper>
                  <CalendarBox error={!sentEmployeeData}>
                    <svg
                      style={{ marginRight: '12px' }}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1941_181802)">
                        <path
                          d="M13.334 2.0013H12.6673V0.667969H11.334V2.0013H4.66732V0.667969H3.33398V2.0013H2.66732C1.93398 2.0013 1.33398 2.6013 1.33398 3.33464V14.0013C1.33398 14.7346 1.93398 15.3346 2.66732 15.3346H13.334C14.0673 15.3346 14.6673 14.7346 14.6673 14.0013V3.33464C14.6673 2.6013 14.0673 2.0013 13.334 2.0013ZM13.334 14.0013H2.66732V5.33464H13.334V14.0013Z"
                          fill={
                            sentEmployeeData
                              ? themeColors.greenBrand
                              : themeColors.redParagraphs
                          }
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1941_181802">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>
                      {date[1]} {date[0]}
                    </span>
                  </CalendarBox>
                  <div>
                    <ChangeLanguage localOnly />
                  </div>
                </Wrapper>
                {sentEmployeeData ? (
                  <h5>{t('employee-survey.survey.success.text')}</h5>
                ) : (
                  <h5>{t('employee-survey.survey.error.text')}</h5>
                )}
              </TextBox>
            </EntryBox>
          )}
        </div>
      )}
      <Formset token={apiKey} page={page} setPage={setPage} lang={language} />
    </Container>
  );
}
