import React from 'react';
import { TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  EsgDmaRiskOpportunityOutSchema,
  EsgDmaSubSubTopicOutSchema,
} from '../../../types';
import { useRiskOpportunities } from '../../../hooks';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import AddImpactRow from './AddRiskOpportunityRow';
import RiskOpportunityRow from './RiskOpportunityRow';
import { useEsgReports } from 'src/Esg/hooks';

interface Props {
  subsubtopic: EsgDmaSubSubTopicOutSchema;
  color?: string;
}

export default function SubSubTopicRow(props: Props) {
  const { subsubtopic, color } = props;
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _data: riskOpportunities } = useRiskOpportunities(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  const [collapsed, setCollapsed] = React.useState(false);

  // Filter risk opportunities by subsubtopic ID
  const filteredRiskOpportunities = riskOpportunities.filter(
    (i) => i.esg_dma_sub_sub_topic_id === subsubtopic.id
  );

  // Map through each riskOpportunity and find corresponding impact by impact_id
  return (
    <>
      <TableRow>
        <CollapsableButtonCell
          colSpan={11}
          text={subsubtopic.description}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sx={{ paddingLeft: '32px' }}
        />
      </TableRow>

      {!collapsed &&
        filteredRiskOpportunities.map(
          (item: EsgDmaRiskOpportunityOutSchema) => (
            <RiskOpportunityRow
              key={item.id}
              riskOpportunity={item}
              topicColor={color}
            />
          )
        )}

      {!collapsed && <AddImpactRow subSubTopicId={subsubtopic.id} />}
    </>
  );
}
