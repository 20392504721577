import React, { useEffect, useRef, useState } from 'react';
import Title from '../../../components/Title';
import AddButtonHolder from '../../components/Questionnaire/AddButtonHolder';
import {
  CopyWrapper,
  Description,
  HintBox,
  PageContainer,
  PreviewBox,
  Reminder,
  Section,
  SectionGroupContainer,
  StyledHeader,
  StyledTh,
} from './index.styles';
import { useAppDispatch, useAppSelector } from '../../../redux-file/hooks';
import { useGhgOrganization, useLanguage } from 'src/common/hooks';
import {
  getElecricityEmissionsData,
  getElecricityEmissionsDataEmissionShares,
  postElecricityEmissionsData,
  setSelectedDate,
} from '../../../redux-file/questionnaires/thunks';
import {
  timeConverter,
  getFirstDateOfPeriod,
} from '../../../utils/timeConverter';
import ElecricityEmissionForm from '../../components/Questionnaire/forms/elecricityEmissionForm';
import ElecricityPreview from '../../components/Questionnaire/preview/electricityPreview';
import QuestionnaireTitle from '../../components/Questionnaire/Title';
import AddProductButton from '../../../components/AddProductButton';
import QuestionnaireSectionTitle from '../../components/QuestionnaireSectionTitle';
import {
  electricity_cloud,
  heat_cloud,
  steam_cloud,
  cooling_cloud,
  ev_consumption_cloud,
  ev_distance_cloud,
} from './clouds_data';
import PageLoading from '../../../components/PageLoading';
import SomethingWentWrong from '../../../components/SomethingWentWrong';
import EvConsumptionForm from '../../components/Questionnaire/forms/ev-consumption-form';
import EvConsumptionPreview from '../../components/Questionnaire/preview/ev-consumption-preview';
import EvDistanceForm from '../../components/Questionnaire/forms/ev-distance-form';
import EvDistancePreview from '../../components/Questionnaire/preview/ev-distance-preview';
import UploadExcelButton from '../../../components/UploadExcelButton';
import { IntuitiveCalendarPeriod } from '../../components/IntuitiveCalendar';
import CopyForm from '../../components/CopyForm';
import { energy_heat_units } from '../../components/Questionnaire/forms/data';

const units = energy_heat_units;

export default function ElectricityEmission() {
  const { t, language } = useLanguage();

  const {
    selectedDate,
    electricityEmission,
    electricityEmissionError,
    electricityEmissionLoading,
    electricityEmissionEmissionShares,
  } = useAppSelector((state) => state.questionnaire);

  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;

  const dispatch = useAppDispatch();

  const [active1, setActive1] = useState<undefined | number>(undefined);
  const [active2, setActive2] = useState<undefined | number>(undefined);
  const [active3, setActive3] = useState<undefined | number>(undefined);
  const [active4, setActive4] = useState<undefined | number>(undefined);
  const [active5, setActive5] = useState<undefined | number>(undefined);
  const [active6, setActive6] = useState<undefined | number>(undefined);

  const [newFormEl, setNewFormEl] = useState(true);
  const [newFormSt, setNewFormSt] = useState(true);
  const [newFormHe, setNewFormHe] = useState(true);
  const [newFormCo, setNewFormCo] = useState(true);
  const [newFormEVConsumption, setNewFormEVConsumption] = useState(true);
  const [newFormEVDistance, setNewFormEVDistance] = useState(true);

  const initState = {
    emission_factor_is_known: false,
    energy_usage_data_factor_unknown: {
      spending_pln_or_kwh_per_period: '',
      unit: '',
    },
    energy_usage_data_factor_known: {
      factor: '',
      amount_per_period: '',
      unit_label: '',
    },
    certified_sources_are_utilized: false,
    certified_energy_usage_data: {
      certified_energy_type_label: '',
      certified_percentge: '',
      emission_factor: '',
    },
  };

  const consumptionInit = {
    emission_factor_is_known: false,
    electric_vehicle_energy_consumption_data_factor_unknown: {
      name_label: '',
      spending_pln_or_kwh_per_period: '',
      unit: '',
    },
    electric_vehicle_energy_consumption_data_factor_known: {
      emission_factor: '',
      amount: '',
      unit_label: '',
    },
    comment: '',
  };

  const distanceInit = {
    type_of_vehicle_label: '',
    comment: '',
    type_of_vehicle: '',
    unit_of_distance: '',
    distance_travelled: '',
    manual_factor: '',
  };

  const electricity_usage_ref = useRef(null);
  const steam_usage_ref = useRef(null);
  const heat_usage_ref = useRef(null);
  const cooling_usage_ref = useRef(null);
  const consumption_ref = useRef(null);
  const distance_ref = useRef(null);

  const [electricityUsageState, setElectricityUsageState] = useState([
    initState,
  ]);
  const [steamUsageState, setSteamUsageState] = useState([initState]);
  const [heatUsageState, setHeatUsageState] = useState([initState]);
  const [coolingUsageState, setCoolUsageState] = useState([initState]);
  const [evConspumptionState, setEvConspumptionState] = useState([
    consumptionInit,
  ]);
  const [evDistanceState, setEvDistanceState] = useState([distanceInit]);

  const combinedState = {
    electricity_usage:
      electricityUsageState.length === 1 &&
      electricityUsageState[0].emission_factor_is_known === false &&
      electricityUsageState[0].energy_usage_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : electricityUsageState,
    steam_usage:
      steamUsageState.length === 1 &&
      steamUsageState[0].emission_factor_is_known === false &&
      steamUsageState[0].energy_usage_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : steamUsageState,
    heat_usage:
      heatUsageState.length === 1 &&
      heatUsageState[0].emission_factor_is_known === false &&
      heatUsageState[0].energy_usage_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : heatUsageState,
    cooling_usage:
      coolingUsageState.length === 1 &&
      coolingUsageState[0].emission_factor_is_known === false &&
      coolingUsageState[0].energy_usage_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : coolingUsageState,
    electric_vehicles_consumptionwise_usage:
      evConspumptionState.length === 1 &&
      evConspumptionState[0].emission_factor_is_known === false &&
      evConspumptionState[0]
        .electric_vehicle_energy_consumption_data_factor_unknown
        .spending_pln_or_kwh_per_period === ''
        ? []
        : evConspumptionState,
    electric_vehicles_distancewise_usage:
      evDistanceState.length === 1 &&
      evDistanceState[0].distance_travelled === ''
        ? []
        : evDistanceState,
  };

  useEffect(() => {
    if (electricityEmission.electricity_usage.length === 0) {
      setNewFormEl(true);
    } else {
      setNewFormEl(false);
    }

    setElectricityUsageState(
      electricityEmission.electricity_usage.length !== 0 &&
        electricityEmissionEmissionShares.electricity_usage.length !== 0
        ? electricityEmission.electricity_usage.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              electricityEmissionEmissionShares.electricity_usage[i]?.share,
          }))
        : [initState]
    );

    if (electricityEmission.steam_usage.length === 0) {
      setNewFormSt(true);
    } else {
      setNewFormSt(false);
    }

    setSteamUsageState(
      electricityEmission.steam_usage.length !== 0 &&
        electricityEmissionEmissionShares.steam_usage.length !== 0
        ? electricityEmission.steam_usage.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              electricityEmissionEmissionShares.steam_usage[i]?.share,
          }))
        : [initState]
    );

    if (electricityEmission.heat_usage.length === 0) {
      setNewFormHe(true);
    } else {
      setNewFormHe(false);
    }

    setHeatUsageState(
      electricityEmission.heat_usage.length !== 0 &&
        electricityEmissionEmissionShares.heat_usage.length !== 0
        ? electricityEmission.heat_usage.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              electricityEmissionEmissionShares.heat_usage[i]?.share,
          }))
        : [initState]
    );

    if (electricityEmission.cooling_usage.length === 0) {
      setNewFormCo(true);
    } else {
      setNewFormCo(false);
    }

    setCoolUsageState(
      electricityEmission.cooling_usage.length !== 0 &&
        electricityEmissionEmissionShares.cooling_usage.length !== 0
        ? electricityEmission.cooling_usage.map((el: any, i: number) => ({
            ...el,
            emission_shared:
              electricityEmissionEmissionShares.cooling_usage[i]?.share,
          }))
        : [initState]
    );

    if (
      electricityEmission.electric_vehicles_consumptionwise_usage.length === 0
    ) {
      setNewFormEVConsumption(true);
    } else {
      setNewFormEVConsumption(false);
    }

    setEvConspumptionState(
      electricityEmission.electric_vehicles_consumptionwise_usage.length !==
        0 &&
        electricityEmissionEmissionShares
          .electric_vehicles_consumptionwise_usage.length !== 0
        ? electricityEmission.electric_vehicles_consumptionwise_usage.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                electricityEmissionEmissionShares
                  .electric_vehicles_consumptionwise_usage[i]?.share,
            })
          )
        : [consumptionInit]
    );

    if (electricityEmission.electric_vehicles_distancewise_usage.length === 0) {
      setNewFormEVDistance(true);
    } else {
      setNewFormEVDistance(false);
    }

    setEvDistanceState(
      electricityEmission.electric_vehicles_distancewise_usage.length !== 0 &&
        electricityEmissionEmissionShares.electric_vehicles_distancewise_usage
          .length !== 0
        ? electricityEmission.electric_vehicles_distancewise_usage.map(
            (el: any, i: number) => ({
              ...el,
              emission_shared:
                electricityEmissionEmissionShares
                  .electric_vehicles_distancewise_usage[i]?.share,
            })
          )
        : [distanceInit]
    );
  }, [electricityEmission, selectedDate, electricityEmissionEmissionShares]);

  const refreshForm = () => {
    dispatch(
      getElecricityEmissionsData({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
    dispatch(
      getElecricityEmissionsDataEmissionShares({
        orgId: organization.id,
        period: timeConverter(selectedDate, reportingPeriod),
      })
    );
  };
  useEffect(() => {
    if (!!reportingPeriod) {
      refreshForm();
    }
  }, [selectedDate, organization.id, reportingPeriod]);

  const sendData = (data: any) => {
    dispatch(
      postElecricityEmissionsData({
        period: timeConverter(selectedDate, reportingPeriod),
        data,
      })
    );
  };

  const combineElectricityData = (fullData: any, newData: any) => {
    return {
      ...fullData,
      electricity_usage: [...newData, ...fullData.electricity_usage],
    };
  };
  const combineSteamData = (fullData: any, newData: any) => {
    return {
      ...fullData,
      steam_usage: [...newData, ...fullData.steam_usage],
    };
  };
  const combineHeatData = (fullData: any, newData: any) => {
    return {
      ...fullData,
      heat_usage: [...newData, ...fullData.heat_usage],
    };
  };
  const combineCoolingData = (fullData: any, newData: any) => {
    return {
      ...fullData,
      cooling_usage: [...newData, ...fullData.cooling_usage],
    };
  };
  const combineElectricVehicleEnergyConsumptionwiseData = (
    fullData: any,
    newData: any
  ) => {
    return {
      ...fullData,
      electric_vehicles_consumptionwise_usage: [
        ...newData,
        ...fullData.electric_vehicles_consumptionwise_usage,
      ],
    };
  };
  const combineElectricVehicleEnergyDistancewiseData = (
    fullData: any,
    newData: any
  ) => {
    return {
      ...fullData,
      electric_vehicles_distancewise_usage: [
        ...newData,
        ...fullData.electric_vehicles_distancewise_usage,
      ],
    };
  };

  return (
    <PageContainer>
      <SectionGroupContainer>
        <QuestionnaireTitle info text={t('electricity-emission.title')} />

        <Description>{t('electricity-emission.description')}</Description>
        <Reminder>{t('electricity-emission.reminder1')}</Reminder>
        <Reminder>{t('electricity-emission.reminder2')}</Reminder>

        <CopyWrapper>
          <Title title={t('forms.select-period')} description={null} />
          <CopyForm
            form="ElectiricyEmissionsData"
            orgId={organization.id}
            refresh={() => refreshForm()}
          />
        </CopyWrapper>
        <IntuitiveCalendarPeriod
          reportingPeriod={reportingPeriod}
          selectedDate={getFirstDateOfPeriod(
            selectedDate ? new Date(selectedDate) : new Date(),
            reportingPeriod ?? 'M'
          )}
          setSelectedDate={(selDate: Date) =>
            dispatch(setSelectedDate(selDate))
          }
        />

        {electricityEmissionLoading && <PageLoading />}
        {electricityEmissionError && <SomethingWentWrong />}

        {!electricityEmissionLoading && !electricityEmissionError && (
          <>
            <Section>
              <div ref={electricity_usage_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('electricity-emission.electricity.title')}
                boxContent={electricity_cloud}
                active={active1}
              />
              {newFormEl && (
                <>
                  <PreviewBox>
                    {electricityUsageState.map((el: any, i: number) => (
                      <ElecricityEmissionForm
                        setNewForm={setNewFormEl}
                        variant="electricity"
                        combinedData={combinedState}
                        active={active1}
                        setActive={setActive1}
                        units={units}
                        id={i}
                        key={i}
                        state={electricityUsageState}
                        setState={setElectricityUsageState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('electricity-emission.electricity.title')}
                    slug="EnergyUsageData"
                    fullData={combinedState}
                    combineData={combineElectricityData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormEl && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t('electricity-emission.electricity.table.spending')}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t('electricity-emission.table.unit')}
                      </StyledTh>
                      <StyledTh width={25}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={25}>
                        {t('electricity-emission.table.cert-percent')}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {electricityUsageState.map((el: any, i: number) => {
                        if (active1 === i) {
                          return (
                            <ElecricityEmissionForm
                              setNewForm={setNewFormEl}
                              variant="electricity"
                              combinedData={combinedState}
                              active={active1}
                              setActive={setActive1}
                              units={units}
                              id={i}
                              key={i}
                              state={electricityUsageState}
                              setState={setElectricityUsageState}
                            />
                          );
                        } else {
                          return (
                            <ElecricityPreview
                              language={language}
                              setState={setElectricityUsageState}
                              id={i}
                              state={electricityUsageState}
                              key={i}
                              setActive={setActive1}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setElectricityUsageState([
                            initState,
                            ...electricityUsageState,
                          ]);
                          setActive1(0);

                          if (
                            electricity_usage_ref &&
                            electricity_usage_ref.current
                          ) {
                            // @ts-ignore
                            electricity_usage_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('electricity-emission.electricity.title')}
                        slug="EnergyUsageData"
                        fullData={combinedState}
                        combineData={combineElectricityData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={steam_usage_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('electricity-emission.steam.title')}
                boxContent={steam_cloud}
                active={active2}
              />
              {newFormSt && (
                <>
                  <PreviewBox>
                    {steamUsageState.map((el: any, i: number) => (
                      <ElecricityEmissionForm
                        setNewForm={setNewFormSt}
                        variant="steam"
                        combinedData={combinedState}
                        active={active2}
                        setActive={setActive2}
                        units={units}
                        id={i}
                        key={i}
                        state={steamUsageState}
                        setState={setSteamUsageState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('electricity-emission.steam.title')}
                    slug="EnergyUsageData"
                    fullData={combinedState}
                    combineData={combineSteamData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormSt && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t('electricity-emission.steam.table.spending')}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t('electricity-emission.table.unit')}
                      </StyledTh>
                      <StyledTh width={25}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>
                      <StyledTh width={25}>
                        {t('electricity-emission.table.cert-percent')}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {steamUsageState.map((el: any, i: number) => {
                        if (active2 === i) {
                          return (
                            <ElecricityEmissionForm
                              setNewForm={setNewFormSt}
                              variant="steam"
                              combinedData={combinedState}
                              active={active2}
                              setActive={setActive2}
                              units={units}
                              id={i}
                              key={i}
                              state={steamUsageState}
                              setState={setSteamUsageState}
                            />
                          );
                        } else {
                          return (
                            <ElecricityPreview
                              language={language}
                              setState={setSteamUsageState}
                              id={i}
                              state={steamUsageState}
                              key={i}
                              setActive={setActive2}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setSteamUsageState([initState, ...steamUsageState]);
                          setActive2(0);
                          if (steam_usage_ref && steam_usage_ref.current) {
                            // @ts-ignore
                            steam_usage_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('electricity-emission.steam.title')}
                        slug="EnergyUsageData"
                        fullData={combinedState}
                        combineData={combineSteamData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={heat_usage_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('electricity-emission.heat.title')}
                boxContent={heat_cloud}
                active={active3}
              />
              {newFormHe && (
                <>
                  <PreviewBox>
                    {heatUsageState.map((el: any, i: number) => (
                      <ElecricityEmissionForm
                        setNewForm={setNewFormHe}
                        variant="heat"
                        combinedData={combinedState}
                        active={active3}
                        setActive={setActive3}
                        units={units}
                        id={i}
                        key={i}
                        state={heatUsageState}
                        setState={setHeatUsageState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('electricity-emission.heat.title')}
                    slug="EnergyUsageData"
                    fullData={combinedState}
                    combineData={combineHeatData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormHe && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t('electricity-emission.heat.table.spending')}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t('electricity-emission.table.unit')}
                      </StyledTh>
                      <StyledTh width={25}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>

                      <StyledTh width={25}>
                        {t('electricity-emission.table.cert-percent')}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {heatUsageState.map((el: any, i: number) => {
                        if (active3 === i) {
                          return (
                            <ElecricityEmissionForm
                              setNewForm={setNewFormHe}
                              variant="heat"
                              combinedData={combinedState}
                              active={active3}
                              setActive={setActive3}
                              units={units}
                              id={i}
                              key={i}
                              state={heatUsageState}
                              setState={setHeatUsageState}
                            />
                          );
                        } else {
                          return (
                            <ElecricityPreview
                              language={language}
                              setState={setHeatUsageState}
                              id={i}
                              state={heatUsageState}
                              key={i}
                              setActive={setActive3}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setHeatUsageState([initState, ...heatUsageState]);
                          setActive3(0);
                          if (heat_usage_ref && heat_usage_ref.current) {
                            // @ts-ignore
                            heat_usage_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('electricity-emission.heat.title')}
                        slug="EnergyUsageData"
                        fullData={combinedState}
                        combineData={combineHeatData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>

            <Section>
              <div ref={cooling_usage_ref} />
              <QuestionnaireSectionTitle
                level="primary"
                text={t('electricity-emission.cooling.title')}
                boxContent={cooling_cloud}
                active={active4}
              />
              {newFormCo && (
                <>
                  <PreviewBox>
                    {coolingUsageState.map((el: any, i: number) => (
                      <ElecricityEmissionForm
                        setNewForm={setNewFormCo}
                        variant="cooling"
                        combinedData={combinedState}
                        active={active4}
                        setActive={setActive4}
                        units={units}
                        id={i}
                        key={i}
                        state={coolingUsageState}
                        setState={setCoolUsageState}
                      />
                    ))}
                  </PreviewBox>
                  <UploadExcelButton
                    name={t('electricity-emission.cooling.title')}
                    slug="EnergyUsageData"
                    fullData={combinedState}
                    combineData={combineCoolingData}
                    sendData={sendData}
                    refresh={refreshForm}
                  />
                </>
              )}
              {!newFormCo && (
                <>
                  <PreviewBox>
                    <StyledHeader>
                      <StyledTh width={30}>
                        {t('electricity-emission.cooling.table.spending')}
                      </StyledTh>
                      <StyledTh width={20}>
                        {t('electricity-emission.table.unit')}
                      </StyledTh>
                      <StyledTh width={25}>
                        {t(
                          'direct-emission.stationary-combustion.table.emission_shared'
                        )}
                      </StyledTh>

                      <StyledTh width={25}>
                        {t('electricity-emission.table.cert-percent')}
                      </StyledTh>
                      <StyledTh width={0}>
                        {t('sold-products.preview.edit')}
                      </StyledTh>
                    </StyledHeader>
                    <div>
                      {coolingUsageState.map((el: any, i: number) => {
                        if (active4 === i) {
                          return (
                            <ElecricityEmissionForm
                              setNewForm={setNewFormCo}
                              variant="cooling"
                              combinedData={combinedState}
                              active={active4}
                              setActive={setActive4}
                              units={units}
                              id={i}
                              key={i}
                              state={coolingUsageState}
                              setState={setCoolUsageState}
                            />
                          );
                        } else {
                          return (
                            <ElecricityPreview
                              language={language}
                              setState={setCoolUsageState}
                              id={i}
                              state={coolingUsageState}
                              key={i}
                              setActive={setActive4}
                            />
                          );
                        }
                      })}
                    </div>
                  </PreviewBox>
                  <AddButtonHolder
                    firstChild={
                      <AddProductButton
                        onClick={() => {
                          setCoolUsageState([initState, ...coolingUsageState]);
                          setActive4(0);
                          if (cooling_usage_ref && cooling_usage_ref.current) {
                            // @ts-ignore
                            cooling_usage_ref.current.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        }}
                      />
                    }
                    secondChild={
                      <UploadExcelButton
                        name={t('electricity-emission.cooling.title')}
                        slug="EnergyUsageData"
                        fullData={combinedState}
                        combineData={combineCoolingData}
                        sendData={sendData}
                        refresh={refreshForm}
                      />
                    }
                  />
                </>
              )}
            </Section>
            <Section>
              <QuestionnaireSectionTitle
                level="primary"
                text={t('electricity-emission.vehicles.title')}
                active={false}
              />
              <Section>
                <HintBox>
                  <span>{t('hint')}</span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('direct-emission.mobile-combustion.hint.line1'),
                    }}
                  />
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'direct-emission.mobile-combustion.hint.line2'
                        ),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'direct-emission.mobile-combustion.hint.line3'
                        ),
                      }}
                    />
                  </div>
                </HintBox>
              </Section>
              <Section>
                <div ref={consumption_ref} />
                <QuestionnaireSectionTitle
                  level="secondary"
                  text={t('electricity-emission.vehicles.consumption.title')}
                  boxContent={ev_consumption_cloud}
                  active={active5}
                />

                {newFormEVConsumption && (
                  <>
                    <PreviewBox>
                      {evConspumptionState.map((el: any, i: number) => (
                        <EvConsumptionForm
                          setNewForm={setNewFormEVConsumption}
                          combinedData={combinedState}
                          active={active5}
                          setActive={setActive5}
                          id={i}
                          key={i}
                          state={evConspumptionState}
                          setState={setEvConspumptionState}
                        />
                      ))}
                    </PreviewBox>
                    <UploadExcelButton
                      name={t('electricity-emission.vehicles.title')}
                      slug="ElectricVehicleEnergyConsumptionwiseData"
                      fullData={combinedState}
                      combineData={
                        combineElectricVehicleEnergyConsumptionwiseData
                      }
                      sendData={sendData}
                      refresh={refreshForm}
                    />
                  </>
                )}
                {!newFormEVConsumption && (
                  <>
                    <PreviewBox>
                      <StyledHeader>
                        <StyledTh width={25}>
                          {t(
                            'electricity-emission.vehicles.consumption.table.name'
                          )}
                        </StyledTh>
                        <StyledTh width={15}>
                          {t(
                            'electricity-emission.vehicles.consumption.table.amount'
                          )}
                        </StyledTh>
                        <StyledTh width={10}>
                          {t(
                            'electricity-emission.vehicles.consumption.table.unit'
                          )}
                        </StyledTh>
                        <StyledTh width={20}>
                          {t(
                            'direct-emission.stationary-combustion.table.emission_shared'
                          )}
                        </StyledTh>
                        <StyledTh width={20}>
                          {t('electricity-emission.table.cert-percent')}
                        </StyledTh>
                        <StyledTh width={0}>
                          {t('sold-products.preview.edit')}
                        </StyledTh>
                      </StyledHeader>
                      <div>
                        {evConspumptionState.map((el: any, i: number) => {
                          if (active5 === i) {
                            return (
                              <EvConsumptionForm
                                setNewForm={setNewFormEVConsumption}
                                combinedData={combinedState}
                                active={active5}
                                setActive={setActive5}
                                id={i}
                                key={i}
                                state={evConspumptionState}
                                setState={setEvConspumptionState}
                              />
                            );
                          } else {
                            return (
                              <EvConsumptionPreview
                                language={language}
                                setState={setEvConspumptionState}
                                id={i}
                                state={evConspumptionState}
                                key={i}
                                setActive={setActive5}
                              />
                            );
                          }
                        })}
                      </div>
                    </PreviewBox>
                    <AddButtonHolder
                      firstChild={
                        <AddProductButton
                          onClick={() => {
                            setEvConspumptionState([
                              consumptionInit,
                              ...evConspumptionState,
                            ]);
                            setActive5(0);
                            if (consumption_ref && consumption_ref.current) {
                              // @ts-ignore
                              consumption_ref.current.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }
                          }}
                        />
                      }
                      secondChild={
                        <UploadExcelButton
                          name={t('electricity-emission.vehicles.title')}
                          slug="ElectricVehicleEnergyConsumptionwiseData"
                          fullData={combinedState}
                          combineData={
                            combineElectricVehicleEnergyConsumptionwiseData
                          }
                          sendData={sendData}
                          refresh={refreshForm}
                        />
                      }
                    />
                  </>
                )}
              </Section>

              <Section>
                <div ref={distance_ref} />
                <QuestionnaireSectionTitle
                  level="secondary"
                  text={t('electricity-emission.vehicles.distance.title')}
                  boxContent={ev_distance_cloud}
                  active={active6}
                />
                {newFormEVDistance && (
                  <>
                    <PreviewBox>
                      {evDistanceState.map((el: any, i: number) => (
                        <EvDistanceForm
                          setNewForm={setNewFormEVDistance}
                          combinedData={combinedState}
                          active={active6}
                          setActive={setActive6}
                          id={i}
                          key={i}
                          state={evDistanceState}
                          setState={setEvDistanceState}
                        />
                      ))}
                    </PreviewBox>
                    <UploadExcelButton
                      name={t('electricity-emission.vehicles.distance.title')}
                      slug="ElectricVehicleEnergyDistancewiseData"
                      fullData={combinedState}
                      combineData={combineElectricVehicleEnergyDistancewiseData}
                      sendData={sendData}
                      refresh={refreshForm}
                    />
                  </>
                )}
                {!newFormEVDistance && (
                  <>
                    <PreviewBox>
                      <StyledHeader>
                        <StyledTh width={25}>
                          {t(
                            'electricity-emission.vehicles.distance.type_of_vehicle_label'
                          )}
                        </StyledTh>
                        <StyledTh width={20}>
                          {t(
                            'electricity-emission.vehicles.distance.table.distance_travelled'
                          )}
                        </StyledTh>
                        <StyledTh width={15}>
                          {t(
                            'electricity-emission.vehicles.distance.table.unit_of_distance'
                          )}
                        </StyledTh>
                        <StyledTh width={25}>
                          {t(
                            'electricity-emission.vehicles.distance.type_of_vehicle'
                          )}
                        </StyledTh>
                        <StyledTh width={15}>
                          {t(
                            'direct-emission.stationary-combustion.table.emission_shared'
                          )}
                        </StyledTh>

                        <StyledTh width={0}>
                          {t('sold-products.preview.edit')}
                        </StyledTh>
                      </StyledHeader>
                      <div>
                        {evDistanceState.map((el: any, i: number) => {
                          if (active6 === i) {
                            return (
                              <EvDistanceForm
                                setNewForm={setNewFormEVDistance}
                                combinedData={combinedState}
                                active={active6}
                                setActive={setActive6}
                                id={i}
                                key={i}
                                state={evDistanceState}
                                setState={setEvDistanceState}
                              />
                            );
                          } else {
                            return (
                              <EvDistancePreview
                                language={language}
                                setState={setEvDistanceState}
                                id={i}
                                state={evDistanceState}
                                key={i}
                                setActive={setActive6}
                              />
                            );
                          }
                        })}
                      </div>
                    </PreviewBox>
                    <AddButtonHolder
                      firstChild={
                        <AddProductButton
                          onClick={() => {
                            setEvDistanceState([
                              distanceInit,
                              ...evDistanceState,
                            ]);
                            setActive6(0);
                            if (distance_ref && distance_ref.current) {
                              // @ts-ignore
                              distance_ref.current.scrollIntoView({
                                behavior: 'smooth',
                              });
                            }
                          }}
                        />
                      }
                      secondChild={
                        <UploadExcelButton
                          name={t(
                            'electricity-emission.vehicles.distance.title'
                          )}
                          slug="ElectricVehicleEnergyDistancewiseData"
                          fullData={combinedState}
                          combineData={
                            combineElectricVehicleEnergyDistancewiseData
                          }
                          sendData={sendData}
                          refresh={refreshForm}
                        />
                      }
                    />
                  </>
                )}
              </Section>
            </Section>
          </>
        )}
      </SectionGroupContainer>
    </PageContainer>
  );
}
