import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from 'src/views/Pages/Home';
import Superadmin from 'src/Superadmin';
import Ghg from 'src/Ghg';
import Esg from 'src/Esg';
import RequireSuperUser from 'src/views/RequireSuperuser';
import PdfRaport from 'src/views/Pages/Pdf';
import Settings from 'src/Superadmin/Settings';
import Onboarding from 'src/views/Pages/Onboarding';
import QuestionnairePreview from 'src/views/components/QuestionnaireV3/Preview';
import NotFound from 'src/views/components/NotFound';
import { RouteLayout } from '../views/components/Layout';
import TopBar from '../components/TopBar';
import {
  setLastVisitedProduct,
  usePlatformNavigation,
} from '../utils/navigation';
import Lca from '../Lca';
import { useQueryClient } from 'react-query';
import Cbam from 'src/Cbam';
import { paths } from 'src/views/components/QuestionnaireV3/helpers';

const ProductRouter = () => {
  const { activeProduct } = usePlatformNavigation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (!activeProduct) {
      return;
    }
    // update the last visited product
    setLastVisitedProduct(activeProduct);
    // invalidate the platform state to make sure the product/org list is up-to-date
    queryClient.invalidateQueries(['platform']);
  }, [activeProduct]);

  return (
    <Routes>
      {/* Products */}
      <Route path="/cbam/*" element={<Cbam />} />
      <Route path="/superadmin/*" element={<Superadmin />} />
      <Route path="/ghg/*" element={<Ghg />} />
      <Route path="/esg/*" element={<Esg />} />
      <Route path="/lca/*" element={<Lca />} />

      {/* Authorized, non-product routes */}
      <Route path="/" element={<RouteLayout topbar={<TopBar />} />}>
        {/* Home Page - currently redirects */}
        <Route index element={<Home />} />

        {/* Routes to be alocated into seperate files in close future*/}
        <Route path="settings" element={<Settings />} />

        <Route path="respect-energy" element={<PdfRaport />} />
        <Route path="onboarding/:page" element={<Onboarding />} />

        {/* Questionnaires v3 */}
        <Route
          path="questionnaire-readonly/:questionnaireAnswerId"
          element={
            <QuestionnairePreview
              overrideFeedUrl={paths.questionnaireAnswersReadonly}
            />
          }
        />
        <Route
          path="questionnaire-preview"
          element={<QuestionnairePreview addManagementPanel />}
        />
        <Route
          path="questionnaire-preview/:questionnaireAnswerId"
          element={<QuestionnairePreview addManagementPanel />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default ProductRouter;
