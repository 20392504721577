import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  ChartData,
  ScatterDataPoint,
  ChartOptions,
  TooltipItem,
} from 'chart.js';
import { StyledPanel } from '../../../../../styles';
import { StyledText, StyledTextLight } from '../index.styles';
import { FlexContainer } from '../../../../components/DmaMatrixTable/index.styles';
import { adjustDataPoints } from '../../../../utils';
import ChartAxesLegend from '../../../../components/ChartAxiesLegend';

interface Summary {
  calculated_positive_score: number;
  calculated_negative_score: number;
  stakeholder_topic: {
    key: string;
    name: string;
    color?: string;
  };
}

interface ScatterChartProps {
  data: Summary[];
}

const ScatterChart: React.FC<ScatterChartProps> = ({ data }) => {
  const { t } = useTranslation();

  const adjustedSummaries = adjustDataPoints(data);

  const chartData: ChartData<'scatter', ScatterDataPoint[], string> = {
    datasets: adjustedSummaries.map((item: Summary, index) => ({
      label: item.stakeholder_topic.key,
      data: [
        {
          x: item.calculated_negative_score,
          y: item.calculated_positive_score,
          realNegativeScore: data[index].calculated_negative_score,
          realPositiveScore: data[index].calculated_positive_score,
        },
      ],
      backgroundColor: item.stakeholder_topic.color || '#D9D9D9',
      pointRadius: 20,
      pointHoverRadius: 25,
    })),
  };

  const options: ChartOptions<'scatter'> = {
    maintainAspectRatio: true,
    aspectRatio: 1,
    scales: {
      x: {
        type: 'linear',
        min: 0,
        max: 10,
        grid: {
          display: true,
          drawOnChartArea: true,
          lineWidth: 1,
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        min: 0,
        max: 10,
        grid: {
          display: true,
          drawOnChartArea: true,
          lineWidth: 1,
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(context: TooltipItem<'scatter'>) {
            const { realNegativeScore, realPositiveScore } = context.raw as {
              realNegativeScore: number;
              realPositiveScore: number;
            };
            return `${t(
              'esg.dma.stakeholderAnalytics.dashboard.scatterPlot.negativeScore'
            )}: ${realNegativeScore}, ${t(
              'esg.dma.stakeholderAnalytics.dashboard.scatterPlot.positiveScore'
            )}: ${realPositiveScore}`;
          },
        },
      },
    },
  };

  return (
    <StyledPanel>
      <StyledText>
        {t('esg.dma.stakeholderAnalytics.dashboard.barchart.title')}
      </StyledText>
      <StyledTextLight>
        {t('esg.dma.stakeholderAnalytics.dashboard.barchart.subtitle')}
      </StyledTextLight>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          marginTop: '26px',
          gap: '46px',
          minWidth: '500px',
          height: '500px',
        }}
      >
        <div
          style={{
            minWidth: '500px',
            height: '500px',
            position: 'relative',
          }}
        >
          <ChartAxesLegend />
          <div style={{ position: 'relative', zIndex: 999 }}>
            <Scatter data={chartData} options={options} />
          </div>
        </div>
        <div>
          {data.map((item: Summary) => (
            <div key={item.stakeholder_topic.key}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
                  color: '#38414f',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                }}
              >
                <FlexContainer
                  style={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: item.stakeholder_topic.color || '#D9D9D9',
                    borderRadius: '50%',
                    marginRight: '10px',
                  }}
                >
                  {item.stakeholder_topic.key}
                </FlexContainer>
                {item.stakeholder_topic.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </StyledPanel>
  );
};

export default ScatterChart;
