import React from 'react';

import Field from '../components/Field';
import { useEsgStakeholderTypes } from 'src/Esg/hooks';
import { useEsgDmaValueChainStakeholderTypes } from 'src/Esg/Dmav2/hooks';
import withButton from '../components/withActivationButton';
import { SelectChangeEvent } from '@mui/material';
import { ValueChainStakeholderTypeInSchema } from 'src/Esg/Dmav2/types';

interface Props {
  entityId: number;
  // eslint-disable-next-line no-unused-vars
  onSave: (data: ValueChainStakeholderTypeInSchema) => void;
}

function NewStakeholderType(props: Props) {
  const { entityId, onSave } = props;
  const { data: stakeholders } = useEsgStakeholderTypes();
  const { _data } = useEsgDmaValueChainStakeholderTypes(entityId);

  const existingStakeholderIds = _data.map((s) => s.esg_stakeholder_type_id);
  const stakehopderTypesOptions = stakeholders
    .filter(({ id }) => !existingStakeholderIds.includes(id))
    .map(({ id, name }) => ({ value: id, label: name }));

  const handleSave = (e: React.ChangeEvent<any> | SelectChangeEvent<any>) =>
    onSave({
      esg_stakeholder_type_id: e.target.value,
      esg_dma_value_chain_entity_id: entityId,
    });

  return (
    <Field value="" options={stakehopderTypesOptions} onChange={handleSave} />
  );
}

export default withButton(NewStakeholderType);
