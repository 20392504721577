import React from 'react';
import { TableCell, Typography } from '@mui/material';

import colors from 'src/theme/colors';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { StyledScore } from './styles';
import { EsgDmaImpactOutSchema } from 'src/Esg/Dmav2/types';
import { formatScore } from '../utils';

interface Props {
  impact: EsgDmaImpactOutSchema;
  color?: string;
}

export default function ImpactScore(props: Props) {
  const { color, impact } = props;

  if (!impact) return null;

  return (
    <>
      <TableCell
        rowSpan={3}
        style={{ backgroundColor: colors.backgroundInput }}
        align="center"
      >
        <Typography variant="subtitle2">
          {formatScore(impact.preliminary_score)}
        </Typography>
      </TableCell>
      <TableCell
        rowSpan={3}
        style={{ backgroundColor: colors.backgroundInput }}
        align="center"
      >
        <Typography variant="subtitle2">
          {impact.stakeholders_impact_score !== null
            ? formatScore(impact.stakeholders_impact_score)
            : '-'}
        </Typography>
      </TableCell>
      <TableCell
        rowSpan={3}
        style={{ backgroundColor: colors.backgroundInput }}
        align="center"
      >
        <StyledScore>
          <Typography variant="subtitle2">
            {formatScore(impact.final_score)}
          </Typography>
        </StyledScore>
      </TableCell>
      <TableCell
        rowSpan={3}
        style={{ backgroundColor: colors.backgroundInput }}
        align="center"
      >
        {impact.is_important ? (
          <CheckCircle style={{ color: color ?? colors.esgDark }} />
        ) : (
          <Cancel style={{ color: colors.error }} />
        )}
      </TableCell>
    </>
  );
}
