import React from 'react';
import { useParams } from 'react-router-dom';
import {
  StyledHeader,
  StyledCell,
  StyledRow,
  StyledDataCell,
  stakeholderTypesCellStyle,
} from './index.styles';
import CustomSelect from 'src/components/CustomSelect';
import SendFormModal from './SendFormModal';
import { useEsgOrganization, useLanguage } from 'src/common/hooks';

import {
  getBooleanOptions,
  getNumericOptions,
  getStakeholderNatureOptions,
} from 'src/Esg/Dma/utils';
import EditModal from './EditModal';
import { IconButton } from '@mui/material';
import RemoveIcon from 'src/images/svg/removeIcon';
import { useEsgReports, useEsgStakeholderTypes } from 'src/Esg/hooks';
import { useEsgStakeholderData } from 'src/Esg/Dmav2/hooks';

export default function SelectingStakeholdersTable() {
  const { t } = useLanguage();
  const organization = useEsgOrganization();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _data, create, update, _delete } = useEsgStakeholderData(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  const handleNewRowCreate = (value: number) =>
    create.mutate({
      esg_stakeholder_type_id: value,
      esg_dma_id: report?.esg_dma_id,
    });

  const { data: stakeholderTypes, dataMap: stakeholdersTypesMap } =
    useEsgStakeholderTypes();

  const _usedStakeholderTypes = _data.map((row) => row.esg_stakeholder_type_id);
  const availableStakeholderTypes = stakeholderTypes.filter(
    (stakeholderType) => !_usedStakeholderTypes.includes(stakeholderType.id)
  );

  const translatedColumn = (key: string) =>
    t(`esg.dma.selectingStakeholders.table.columns.${key}`);

  const NUMERIC_OPTIONS = getNumericOptions(t);
  const BOOLEAN_OPTIONS = getBooleanOptions(t);
  const STAKEHOLDER_NATURE_OPTIONS = getStakeholderNatureOptions(t);

  return (
    <div style={{ width: '100%', minWidth: '1210px' }}>
      <StyledRow sx={{ borderWidth: '2px' }}>
        <StyledHeader style={stakeholderTypesCellStyle}>
          {translatedColumn('stakeholderTypes')}
        </StyledHeader>
        <StyledHeader style={{ minWidth: '260px' }}>
          {translatedColumn('stakeholders_nature')}
        </StyledHeader>
        <StyledHeader>{translatedColumn('severity')}</StyledHeader>
        <StyledHeader>{translatedColumn('selected_for_survey')}</StyledHeader>
        <StyledHeader>{translatedColumn('introText')}</StyledHeader>
        <StyledHeader>{translatedColumn('outroText')}</StyledHeader>
        <StyledHeader>{translatedColumn('reasoning')}</StyledHeader>
        <StyledHeader sx={{ backgroundColor: '#F6F6F6' }}>
          {translatedColumn('remove')}
        </StyledHeader>
        <StyledHeader sx={{ backgroundColor: '#F6F6F6' }}>
          {translatedColumn('send')}
        </StyledHeader>
      </StyledRow>

      {_data.map((row) => {
        const {
          esg_stakeholder_type_id,
          stakeholder_nature,
          severity,
          selected_for_survey: selectedForSurvey,
          reasoning,
          intro_text,
          outro_text,
        } = row;

        const stakeholder = stakeholdersTypesMap[esg_stakeholder_type_id];
        if (!stakeholder) return null;

        return (
          <StyledRow key={row.id}>
            <StyledCell style={stakeholderTypesCellStyle}>
              {stakeholder.name}
            </StyledCell>
            <StyledDataCell style={{ minWidth: '260px' }}>
              <CustomSelect
                key={`${row.id}-nature`}
                fullWidth
                value={stakeholder_nature}
                options={STAKEHOLDER_NATURE_OPTIONS}
                onChange={(value) => {
                  update.mutate({ ...row, stakeholder_nature: value });
                }}
              />
            </StyledDataCell>
            <StyledDataCell>
              <CustomSelect
                key={`${row.id}-severity`}
                value={severity}
                options={NUMERIC_OPTIONS}
                onChange={(value) => {
                  update.mutate({ ...row, severity: value });
                }}
              />
            </StyledDataCell>
            <StyledDataCell>
              <CustomSelect
                key={`${row.id}-survey`}
                value={selectedForSurvey}
                options={BOOLEAN_OPTIONS}
                onChange={(value) => {
                  update.mutate({ ...row, selected_for_survey: value });
                }}
              />
            </StyledDataCell>
            <StyledDataCell>
              <EditModal
                title={t(
                  'esg.dma.selectingStakeholders.table.columns.introText'
                )}
                subtitleTranslationKey="esg.dma.selectingStakeholders.table.modals.intro.subtitle"
                value={intro_text}
                defaultValue={t('esg.stakeholderSurvey.intro.message', {
                  organization_name: organization.name,
                })}
                onChange={(value) => {
                  update.mutate({ ...row, intro_text: value });
                }}
              />
            </StyledDataCell>
            <StyledDataCell>
              <EditModal
                title={t(
                  'esg.dma.selectingStakeholders.table.columns.outroText'
                )}
                subtitleTranslationKey="esg.dma.selectingStakeholders.table.modals.outro.subtitle"
                value={outro_text}
                defaultValue={t('esg.stakeholderSurvey.outro.message')}
                onChange={(value) => {
                  update.mutate({ ...row, outro_text: value });
                }}
              />
            </StyledDataCell>
            <StyledDataCell>
              <EditModal
                title={t(
                  'esg.dma.selectingStakeholders.table.modals.reasoning.title'
                )}
                subtitleTranslationKey="esg.dma.selectingStakeholders.table.modals.reasoning.subtitle"
                value={reasoning}
                attention={
                  (selectedForSurvey !== null && reasoning === null) ||
                  reasoning === ''
                }
                onChange={(value) => {
                  update.mutate({ ...row, reasoning: value });
                }}
              />
            </StyledDataCell>

            <StyledDataCell sx={{ backgroundColor: '#F6F6F6' }}>
              <IconButton
                sx={{ borderRadius: 'unset' }}
                onClick={() => _delete.mutate(row.id)}
              >
                <RemoveIcon />
              </IconButton>
            </StyledDataCell>

            <StyledDataCell sx={{ backgroundColor: '#F6F6F6' }}>
              <SendFormModal
                organizationId={String(organization.id)}
                reportId={reportId}
                stakeholderTypeId={esg_stakeholder_type_id}
                stakeholderName={stakeholder.name}
                useLegacyDma
                disabled={
                  reasoning === null ||
                  reasoning === '' ||
                  selectedForSurvey !== true
                }
              />
            </StyledDataCell>
          </StyledRow>
        );
      })}
      {!!availableStakeholderTypes.length && (
        <StyledRow>
          <StyledCell
            style={stakeholderTypesCellStyle}
            sx={{ maxWidth: '200px' }}
          >
            <CustomSelect
              key="new-row"
              value={null}
              options={availableStakeholderTypes.map((s) => ({
                value: s.id,
                label: s.name,
              }))}
              onChange={handleNewRowCreate}
            />
          </StyledCell>
        </StyledRow>
      )}
    </div>
  );
}
