import React from 'react';
import { useEsgDmaValueChainEntities } from 'src/Esg/Dmav2/hooks';
import FieldLabel from '../Forms/components/Label';
import EntityStageForm from '../Forms/EntityStage';
import { Box } from '@mui/material';
import NewEntityStageForm from '../Forms/EntityStage/NewEntityStage';

const entityStageOptions = [
  { value: 'is_own_operations', label: 'own operations' },
  { value: 'is_downstream', label: 'downstream' },
  { value: 'is_upstream', label: 'upstream' },
];

interface Props {
  entityId: number;
}

export default function EntityStages(props: Props) {
  const { entityId } = props;
  const { _instance: entity, update } = useEsgDmaValueChainEntities({
    id: entityId,
  });

  if (!entity) return null;

  const availableOptions: typeof entityStageOptions = [];
  const assignedOptions: typeof entityStageOptions = [];

  entityStageOptions.forEach((option) => {
    const value = entity[option.value as keyof typeof entity];
    if (value) assignedOptions.push(option);
    else availableOptions.push(option);
  });

  const handleUnassignStage = (value: string) =>
    update.mutate({ ...entity, [value]: false });

  return (
    <>
      <FieldLabel fieldName="stage">
        <Box width="100%">
          {assignedOptions.map((option) => {
            return (
              <EntityStageForm
                key={option.value}
                value={option.value}
                // TODO: confirmation modal ??
                onDelete={() => handleUnassignStage(option.value)}
              />
            );
          })}
        </Box>
      </FieldLabel>

      {availableOptions.length > 0 && (
        <NewEntityStageForm
          entity={entity}
          onSave={(data) => update.mutate(data)}
          options={availableOptions}
          buttonSx={{ ml: '25%' }}
        />
      )}
    </>
  );
}
