import React from 'react';
import { SxProps, TableCell, TableRow, Theme, Typography } from '@mui/material';

import { ConsolidationTableValueSchema } from '../../types';
import colors from 'src/theme/colors';

interface IndividualGroupsRowProps extends ConsolidationTableValueSchema {
  sx?: SxProps<Theme>;
}

export default function IndividualGroupsRow(props: IndividualGroupsRowProps) {
  const { sx, organization_metadata, value } = props;

  return (
    <TableRow sx={sx}>
      <TableCell
        sx={{
          borderLeft: `1px solid ${colors.disabledGraphics}`,
          borderRight: `1px solid ${colors.disabledGraphics}`,
        }}
      >
        <Typography variant="subtitle2" sx={{ pl: '8px' }}>
          {organization_metadata.name}
        </Typography>
      </TableCell>
      <TableCell sx={{ borderRight: `1px solid ${colors.disabledGraphics}` }}>
        <Typography variant="captionText" sx={{ pl: '8px' }}>
          {value}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
