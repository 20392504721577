/* eslint-disable no-unused-vars */
export interface NextButtonProps {
  error: boolean;
}

export interface JWTToken {
  exp: number;
  period: string;
  organization_id: number;
  organization_name: string;
}

export enum EmployeeSurveyHideSectionTags {
  HIDE_SECTION_1 = 'GHG_EMPLOYEE_SURVEY_HIDE_SECTION_1',
  HIDE_SECTION_2 = 'GHG_EMPLOYEE_SURVEY_HIDE_SECTION_2',
  HIDE_SECTION_3 = 'GHG_EMPLOYEE_SURVEY_HIDE_SECTION_3',
  HIDE_SECTION_4 = 'GHG_EMPLOYEE_SURVEY_HIDE_SECTION_4',
}
