import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { EmployeeSurveyHideSectionTags } from './index.interfaces';

// eslint-disable-next-line import/prefer-default-export
export const useEmployeeSurveyHideSections = (token: string) => {
  const url = '/web/v2/employee-survey-sections';
  const params = { token };
  const queryKey = ['employee-survey-sections', { token }];

  return useQuery(queryKey, () =>
    client
      .get<EmployeeSurveyHideSectionTags[]>(url, { params })
      .then((res) => res.data)
  );
};
