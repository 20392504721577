import React from 'react';
import {
  EsgQv3FieldHintsOutSchema,
  QuestionnaireAnswer as QuestionnaireAnswerType,
} from '../QuestionnaireAnswer/index.types';

import { ModifiedQuestionAnswers } from '../types';
import { Errors } from '../Field/Fields/types';

export type QuestionnaireContextType = {
  questionnaireAnswerId?: string;
  invalidateQuestionnaireQuery: (
    _modifiedQuestionAnswers: ModifiedQuestionAnswers
  ) => void;
  questionnaireAnswerState?: QuestionnaireAnswerType;
  setQuestionnaireAnswerState: React.Dispatch<
    React.SetStateAction<QuestionnaireAnswerType | undefined>
  >;
  tokenAuth: () => Promise<any>;
  modifiedQuestionAnswers: ModifiedQuestionAnswers;
  setModifiedQuestionAnswers: React.Dispatch<
    React.SetStateAction<ModifiedQuestionAnswers>
  >;
  errors: Errors;
  setErrors: (errors: Errors) => void;
  hints?: EsgQv3FieldHintsOutSchema;
};

const QuestionnaireContext = React.createContext<QuestionnaireContextType>({
  questionnaireAnswerState: undefined,
  setQuestionnaireAnswerState: (data) => data,
  questionnaireAnswerId: undefined,
  invalidateQuestionnaireQuery: (
    _modifiedQuestionAnswers?: ModifiedQuestionAnswers
  ) => {},
  tokenAuth: () => new Promise(() => {}),
  modifiedQuestionAnswers: {},
  setModifiedQuestionAnswers: (data) => data,
  errors: {},
  setErrors: (errors) => errors,
  hints: {},
});

export default QuestionnaireContext;
