import React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';

import { genEmptyAnswer, paths, resolvedDependencyFields } from '../helpers';

import { QuestionAnswer as QuestionAnswerType } from '../types';
import { StyledContainer, StyledFieldReference } from './index.styles';
import Field from '../Field';
import { Field as FieldType } from '../Field/index.types';
import QuestionnaireContext from '../Data/QuestionnaireContext';
import { client } from '../../../../utils/api-client';
import IconSvg from '../../IconSvg';
import { toast } from 'react-toastify';
import { createQuestionAnswer, updateQuestionAnswer } from '../api';
import { extractErrors } from '../../../../utils/validation';
import { StyledFlex } from 'src/components/StyledFlex';
import ConfirmationModal from 'src/components/ConfirmationModal';
import _ from 'lodash';
import { FieldReferences } from 'src/views/Pages/ESG/forms/references';
import { FieldValue } from '../Field/Fields/types';

type Props = {
  questionAnswer: QuestionAnswerType;
  fields: FieldType[];
  containerStyle?: { [key: string]: string };
  question: any;
  t: TFunction;
};

type State = {
  deleteQuestionAnswerOpen: boolean;
};

const MemorizedField = React.memo(Field);

class QuestionAnswer extends React.Component<Props, State> {
  static contextType = QuestionnaireContext;
  context!: React.ContextType<typeof QuestionnaireContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      deleteQuestionAnswerOpen: false,
    };
  }

  catchErrors = (err: any) => {
    this.context.setErrors(extractErrors(err));
  };

  handleSuccess = () => {
    this.removeModifiedQuestionAnswer();
    this.context.invalidateQuestionnaireQuery(
      this.context.modifiedQuestionAnswers
    );
    toast.success(
      String(this.props.t('questionnaireV3.questionAnswer.changesSaved'))
    );
    this.context.setErrors({});
  };

  handleCreateQuestionAnswer = () => {
    createQuestionAnswer(
      this.context.tokenAuth,
      this.context.questionnaireAnswerId,
      this.props.questionAnswer,
      this.props.question,
      this.props.fields
    )
      .then(this.handleSuccess)
      .catch(this.catchErrors);
  };

  handleUpdateQuestionAnswer = () => {
    updateQuestionAnswer(
      this.context.tokenAuth,
      this.props.questionAnswer,
      this.props.fields
    )
      .then(this.handleSuccess)
      .catch(this.catchErrors);
  };

  removeModifiedQuestionAnswer = () => {
    this.context.setModifiedQuestionAnswers((prevState) => {
      const newState = { ...prevState };
      delete newState[this.props.question.id]?.[this.props.questionAnswer.id];
      return newState;
    });
  };

  handleDeleteQuestionAnswer = () => {
    this.context.tokenAuth().then((token) =>
      client
        .delete(
          `${paths.questionnaireAnswerRecords}/${this.props.questionAnswer.id}`,
          {
            headers: {
              'X-Questionnaires-Token': token,
            },
          }
        )
        .then(() => this.context.invalidateQuestionnaireQuery({}))
        .catch((err) => console.error(err))
    );
    this.removeModifiedQuestionAnswer();
    this.setState({ deleteQuestionAnswerOpen: false });
  };

  handleCancelQuestionAnswer = () => {
    this.context.setQuestionnaireAnswerState((prevState) => {
      if (!prevState) return prevState;
      const newState = _.cloneDeep(prevState);
      const questionAnswers =
        newState.questionnaire.questions[this.props.question.key]
          .questionanswers || {};
      delete questionAnswers[this.props.questionAnswer.id];
      if (!this.props.question.allow_many) {
        const emptyAnswer: QuestionAnswerType = genEmptyAnswer(
          this.props.question,
          this.context.questionnaireAnswerId
        );
        questionAnswers[emptyAnswer.id] = emptyAnswer;
      }
      newState.questionnaire.questions[
        this.props.question.key
      ].questionanswers = questionAnswers;
      return newState;
    });
    this.removeModifiedQuestionAnswer();
  };

  handleFieldChange = (field: FieldType, value: FieldValue) => {
    // set modified values for local caching
    const { question, questionAnswer } = this.props;
    this.context.setModifiedQuestionAnswers((prevState) => ({
      ...prevState,
      [question.id]: {
        ...(prevState[question.id] || {}),
        [questionAnswer.id]: {
          ...questionAnswer,
          ...(prevState[question.id]?.[questionAnswer.id] || {}),
          record: { ...(questionAnswer.record || {}), [field.key]: value },
          question_key: question.key,
        },
      },
    }));

    // updating context state
    this.context.setQuestionnaireAnswerState((prevState) => {
      // Escaping undefined state
      if (!prevState) return prevState;

      // keep all objects unrelated to this question the same
      const newState: any = {
        ...prevState,
        questionnaire: {
          ...prevState.questionnaire,
          questions: {
            ...prevState.questionnaire.questions,
            [question.key]: {
              ...prevState.questionnaire.questions[question.key],
              questionanswers: {
                ...prevState.questionnaire.questions[question.key]
                  .questionanswers,
              },
            },
          },
        },
      };

      // creating questionAnswers if does not exist
      // in general questionanswers should always exists in this case
      // but in TS it's defined as optional since in response.data
      // if the questionnaire has no questionanswers - this key will not appear.

      const questionAnswers =
        newState.questionnaire.questions[question.key].questionanswers;

      const record = questionAnswers[questionAnswer.id]?.record;
      if (record) {
        record[field.key] = value;
      }

      return newState;
    });
  };

  render() {
    const { questionAnswer, fields, containerStyle } = this.props;
    const { id } = questionAnswer || {};

    const isNew = String(id).startsWith('temp-id-');

    const dependencyAwareFields = resolvedDependencyFields(
      fields,
      questionAnswer
    );

    return (
      <StyledContainer style={containerStyle}>
        {dependencyAwareFields?.map((field) => (
          <div key={`question-${id}-${id || 'new'}-field-${field.id}`}>
            <div
              style={{
                position: 'relative',
              }}
            >
              {field.reference_id !== 0 && (
                <StyledFieldReference>
                  <Typography variant="captionText">
                    #{field.reference_id}
                  </Typography>
                </StyledFieldReference>
              )}
            </div>
            <MemorizedField
              value={questionAnswer.record[field.key]}
              fieldAnswerId={
                questionAnswer.field_answer_ids[
                  Object.keys(questionAnswer.record).indexOf(field.key)
                ]
              }
              onChange={this.handleFieldChange}
              field={field}
              key={field.id}
              errors={this.context.errors}
              hints={this.context.hints?.[field.id]}
              onForceQuestionAnswerSave={
                isNew
                  ? this.handleCreateQuestionAnswer
                  : this.handleUpdateQuestionAnswer
              }
            />
            <FieldReferences fieldKey={field.id} />
          </div>
        ))}
        {Object.keys(
          this.context.modifiedQuestionAnswers[this.props.question.id] || {}
        ).includes(String(questionAnswer.id)) && (
          <StyledFlex style={{ margin: '30px 0px' }}>
            {isNew && (
              <Button
                className="Esg EsgSecondary"
                size="small"
                variant="contained"
                color="error"
                onClick={this.handleCancelQuestionAnswer}
                disableElevation
              >
                {this.props.t('questionnaireV3.questionAnswer.cancelButton')}
              </Button>
            )}
            {isNew && (
              <Button
                className="Esg EsgPrimary"
                size="small"
                onClick={this.handleCreateQuestionAnswer}
                disableElevation
              >
                {this.props.t('questionnaireV3.questionAnswer.createButton')}
              </Button>
            )}
            {!isNew && (
              <Button
                className="Esg EsgSecondary"
                size="small"
                onClick={() =>
                  this.setState({ deleteQuestionAnswerOpen: true })
                }
                startIcon={<IconSvg name="trash-icon" />}
                variant="contained"
                disableElevation
              >
                {this.props.t('questionnaireV3.questionAnswer.deleteButton')}
              </Button>
            )}

            {!isNew && (
              <Button
                className="Esg EsgPrimary"
                size="small"
                onClick={this.handleUpdateQuestionAnswer}
                variant="contained"
                disableElevation
              >
                {this.props.t('questionnaireV3.questionAnswer.saveButton')}
              </Button>
            )}
          </StyledFlex>
        )}
        <ConfirmationModal
          open={this.state.deleteQuestionAnswerOpen}
          titleKey={this.props.t(
            'questionnaireV3.questionAnswer.confirmDeleteTitle'
          )}
          textKey={this.props.t(
            'questionnaireV3.questionAnswer.confirmDeleteText'
          )}
          onTrue={this.handleDeleteQuestionAnswer}
          onFalse={() => this.setState({ deleteQuestionAnswerOpen: false })}
          onClose={() => this.setState({ deleteQuestionAnswerOpen: false })}
        />
      </StyledContainer>
    );
  }
}

export default withTranslation()(QuestionAnswer);
