import React from 'react';
import { Indicator, Question } from '../types';
import TableCollapsable from './TableCollapsable';
import { TableRow, Typography } from '@mui/material';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import { useEsgUserPermissions } from '../hooks';
import PermissionSelect from './PermissionSelect';
import { useTranslation } from 'react-i18next';
import { QuestionnaireMethodology } from 'src/Esg/types';

interface Props {
  isExpanded: boolean;
  indicator: Indicator & { questions: Question[] };
  userId: number;
  reportYear: number | null;
  questionnaireMethodology: QuestionnaireMethodology;
}
export default function QuestionCollapse(props: Props) {
  const {
    indicator,
    userId,
    reportYear,
    questionnaireMethodology,
    isExpanded,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'superadmin.settings.tabs.users.usersTable.permissions',
  });
  const { options, userOrganizations } = useEsgUserPermissions(userId);

  return (
    <TableCollapsable isExpanded={isExpanded}>
      {indicator.questions.map((question) => {
        const key = question.id;
        return (
          <TableRow key={key}>
            <StyledTableStickyCell>
              <Typography sx={{ pl: '50px' }}>{question.title}</Typography>
            </StyledTableStickyCell>
            {userOrganizations.map((organization) => {
              const report = options.reports.find(
                (r) =>
                  r.period === reportYear &&
                  r.questionnaire_methodology === questionnaireMethodology &&
                  r.esg_organization_id === organization.id
              );

              return !report ? (
                <StyledTableCell key={key + organization.id}>
                  <Typography>{t('other.noReport')}</Typography>
                </StyledTableCell>
              ) : (
                <StyledTableCell key={key + organization.id} sx={{ p: '8px' }}>
                  <PermissionSelect
                    userId={userId}
                    organizationId={organization.id}
                    questionId={question.id}
                    indicatorId={indicator.questionnaire_id}
                    indicatorCategoryId={indicator.esg_indicator_category_id}
                    reportId={report.id}
                  />
                </StyledTableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableCollapsable>
  );
}
