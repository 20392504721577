import React from 'react';
import { TableRow } from '@mui/material';

import { Stage23HierarchySchema } from '../../../types';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import { useDmaImpacts, useRiskOpportunities } from 'src/Esg/Dmav2/hooks';
import { useEsgReports } from 'src/Esg/hooks';
import { useParams } from 'react-router-dom';
import MatterRow from './MatterRow';
import { prepareData } from './utils';

interface Props {
  title: string;
  data: Stage23HierarchySchema;
}

export default function TitleRow(props: Props) {
  const { title, data } = props;
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const impacts = useDmaImpacts(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );
  const riskOpportunities = useRiskOpportunities(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  // wait for impacts and riskOpportunities to load
  if (riskOpportunities.isLoading || impacts.isLoading) return null;

  const matters = prepareData(data, impacts._data, riskOpportunities._data);

  // do not show empty rows
  if (matters.length === 0) return null;

  return (
    <>
      <TableRow>
        <CollapsableButtonCell
          style={{ padding: '8px' }}
          colSpan={13}
          text={title}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sx={{ bgcolor: data.color || '' }}
        />
      </TableRow>

      {!collapsed &&
        matters.map(({ subSubTopicId, title, impact, riskOpportunity }) => (
          <MatterRow
            key={`${subSubTopicId}-${impact?.id}-${riskOpportunity?.id}`}
            title={title}
            impact={impact}
            riskOpportunity={riskOpportunity}
          />
        ))}
    </>
  );
}
