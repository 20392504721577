import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';
import LightTooltip from 'src/components/LightTooltip';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { FactCheck } from '@mui/icons-material';
import { ImageSlider } from '../components/ImageSlider';
import { generateImageUrls } from '../utils';

export default function Stage5() {
  const { t, language: l } = useLanguage({ keyPrefix: 'esg.dma' });
  const stepTwoImageNumber = l === 'pl' ? 6 : 5; // There is additional sources slide for Polish version

  return (
    <DmaStage
      title={t('stagesV2.finalAssesment.title')}
      subtitle={t('stagesV2.finalAssesment.subtitle')}
    >
      <DmaStep
        title={t('stagesV2.finalAssesment.steps.1')}
        videoComponent={<ImageSlider images={generateImageUrls(l, 9, 8)} />}
      />
      <DmaStep
        title={t('stagesV2.finalAssesment.steps.2')}
        videoComponent={
          <ImageSlider images={generateImageUrls(l, 10, stepTwoImageNumber)} />
        }
      />
      <DmaStep
        title={t('stagesV2.finalAssesment.steps.3')}
        navigateToComponent={
          <LightTooltip title={t('step.step5TopicEvaluationButton.toggle')}>
            <Link to="./step-5">
              <IconButton>
                <FactCheck />
              </IconButton>
            </Link>
          </LightTooltip>
        }
      />
    </DmaStage>
  );
}
