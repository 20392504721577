import React from 'react';

import { Box, Collapse, Divider } from '@mui/material';
import { useEsgDmaValueChainEntities } from 'src/Esg/Dmav2/hooks';
import EntityForm from '../Forms/Entity';
import EntityStakeholderTypes from './EntityStakeholderTypes';
import EntityEmpoyeesByCountries from './EmpoyeesByCountries';
import EntityStages from './EntityStages';
import Title from './Title';

interface Props {
  id: number;
}

export default function TitleRow(props: Props) {
  const { id } = props;
  const {
    _instance: entity,
    _delete,
    update,
  } = useEsgDmaValueChainEntities({ id });
  const [collapsed, setCollapsed] = React.useState(false);

  if (!entity) return null;

  return (
    <Box width="100%" mb="20px">
      <Title
        sx={{ bgcolor: '#cfdf9d' }}
        collapsed={collapsed}
        onClick={() => setCollapsed((prevState) => !prevState)}
        onDeleteClick={() => _delete.mutate(id)}
      />

      <Collapse in={!collapsed} sx={{ bgcolor: '#f4f4f4' }}>
        <Box width="95%" margin="auto" p="20px 0px 30px">
          {/* Base Model Form */}
          <EntityForm entity={entity} onSave={(data) => update.mutate(data)} />
          {/* Entity Model Relations */}
          <Divider sx={{ m: '20px 0px' }} />
          <EntityEmpoyeesByCountries entityId={id} />
          <Divider sx={{ m: '20px 0px' }} />
          <EntityStakeholderTypes entityId={id} />
          <Divider sx={{ m: '20px 0px' }} />
          <EntityStages entityId={id} />
        </Box>
      </Collapse>
    </Box>
  );
}
