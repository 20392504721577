import React from 'react';
import { TextField } from '@mui/material';

interface Props extends React.ComponentProps<typeof TextField> {
  onDelayedChange: (_: string) => void;
  delay?: number;
}

export default function DelayedTextField(props: Props) {
  const { onDelayedChange, delay = 1000, ...textFieldProps } = props;

  const [searchTimeout, setSearchTimeout] = React.useState<any>(null);
  const [value, setValue] = React.useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    setValue(v);
    clearTimeout(searchTimeout);
    const timeout = setTimeout(() => onDelayedChange(v), delay);
    setSearchTimeout(timeout);
  };

  return (
    <TextField
      {...textFieldProps}
      value={value ?? ''}
      onChange={handleChange}
    />
  );
}
