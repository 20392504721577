import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { FieldValue, UniversalField } from '../types';
import { StyledErrorMessage } from '../../index.styles';

export default function RadioGroupField(props: UniversalField) {
  const { t } = useTranslation();

  const { field, value, onChange, errors, disabled } = props;
  const { required, fieldoption_set: options } = field;

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  /* backend side validation */
  React.useEffect(() => {
    const newMessage = errors?.[field.key];
    if (!!newMessage) setErrorMessage(newMessage);
  }, [errors]);

  /* Frontend side validation */
  const _validate = (_value: FieldValue) => {
    // required field - validation error on false
    if (required && !_value) {
      setErrorMessage(t('questionnaireV3.field.fieldRequired'));
    }
    // cleaning error messages if none
    else if (!!errorMessage) {
      setErrorMessage(undefined);
    }
  };

  // local handle change injects frontend validation
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: _value } = e.target;
    _validate(_value);
    onChange(_value);
  };

  return (
    <FormControl sx={{ mb: '16px' }} error={!!errorMessage}>
      <FormLabel>{field.label}</FormLabel>
      <RadioGroup value={value} onChange={handleChange}>
        {options?.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.key}
            control={<Radio disabled={!!disabled} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
    </FormControl>
  );
}
