import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCustomSelectValue = styled(Typography)`
  color: #2f2f2f;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledNullComponent = styled(
  ({ disabled, ...props }: { disabled: boolean } & BoxProps) => (
    <Box {...props} />
  )
)`
  border: 1px solid #b6bbb9;
  border-radius: 8px;
  width: 100%;
  min-width: 100px;
  height: 40px;
  padding: 10px;
  background-color: #fff;
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};

  color: #38414f;
  font-family: Poppins;
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.39px;
  text-wrap: nowrap;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
