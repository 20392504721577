import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { useLanguage } from 'src/common/hooks';
import colors from 'src/theme/colors';

export default function Head() {
  const { t } = useLanguage({
    keyPrefix: 'esg.dma.financialTopicsEvaluationTableV2.columns',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput, width: '380px' }}
          rowSpan={2}
          colSpan={1}
        >
          {t('riskOrOpportunityName')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          rowSpan={2}
        >
          {t('associatedImpactName')}
        </TableCell>
        <TableCell rowSpan={2}>{t('riskOrOpportunityDescription')}</TableCell>
        <TableCell rowSpan={2}>{t('riskOrOpportunityType')}</TableCell>
        <TableCell
          colSpan={3}
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
          sx={{ height: '65px' }}
        >
          {t('valueChainStage')}
        </TableCell>
        {/* Delete icon column */}
        <TableCell rowSpan={2} sx={{ width: '56px' }} />
      </TableRow>
      <TableRow
        sx={{
          // fix for the sticky header https://stackoverflow.com/a/74700805
          '& .MuiTableCell-root': {
            top: '65px',
          },
        }}
      >
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('upstream')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundGreyDark }}
          align="center"
        >
          {t('ownOperations')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('downstream')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
