import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import { EsgDmaValueChainEntityOutSchema } from 'src/Esg/Dmav2/types';
import Field from '../components/Field';
import withActivationButton from '../components/withActivationButton';

interface Props {
  entity: EsgDmaValueChainEntityOutSchema;
  options: { value: number | string; label: string }[];
  //eslint-disable-next-line no-unused-vars
  onSave: (entity: EsgDmaValueChainEntityOutSchema) => void;
}

function NewEntityStage(props: Props) {
  const { entity, options, onSave } = props;
  const handleChange = (event: SelectChangeEvent) =>
    onSave({ ...entity, [event.target.value as string]: true });

  return <Field value="" options={options} onChange={handleChange} />;
}

export default withActivationButton(NewEntityStage);
