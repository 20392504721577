import React from 'react';

import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { useLanguage } from 'src/common/hooks';
import FieldLabel from './Label';
import NumberField from 'src/components/NumberField';

interface Props {
  fieldName?: string;
  value: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: React.ChangeEvent<any> | SelectChangeEvent<any>) => void;
  options?: { value: number | string; label: string }[];
  sx?: SxProps;
  textFieldProps?: TextFieldProps;
  isNumber?: boolean;
  helpText?: string;
}

export const DisabledField = ({ fieldName }: Partial<Props>) => (
  <Field fieldName={fieldName} value="-" textFieldProps={{ disabled: true }} />
);

export default function Field(props: Props) {
  const { t } = useLanguage({ keyPrefix: 'esg.dma.step1.valueChain' });
  const { fieldName, value, onChange = () => {}, options, sx } = props;
  const { textFieldProps, isNumber, helpText } = props;

  const isSelect = !!options;
  const isTextField = !isSelect && !isNumber;

  return (
    <FieldLabel fieldName={fieldName} sx={sx} helpText={helpText}>
      {isSelect && (
        // case for select field
        <Select fullWidth size="small" value={value} onChange={onChange}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {isNumber && (
        // case for number field
        <NumberField
          size="small"
          fullWidth
          placeholder={fieldName && t(`placeholders.${fieldName}`)}
          value={value}
          onChange={(_, e) => onChange(e)}
        />
      )}
      {isTextField && (
        // case for text field
        <TextField
          size="small"
          fullWidth
          placeholder={fieldName && t(`placeholders.${fieldName}`)}
          value={value}
          onChange={onChange}
          {...textFieldProps}
        />
      )}
    </FieldLabel>
  );
}
