import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFlex } from 'src/components/StyledFlex';

interface Props {
  onSave: () => void;
  disabled?: boolean;
}

export default function SaveButton(props: Props) {
  const { onSave, disabled } = props;
  const { t } = useTranslation();
  return (
    <StyledFlex sx={{ justifyContent: 'flex-end', m: '10px 50px 20px' }}>
      <Button size="small" onClick={onSave} disabled={disabled}>
        {t('common.save')}
      </Button>
    </StyledFlex>
  );
}
