import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import colors from 'src/theme/colors';
import { ConsolidationTableDataSchema } from '../../types';
import { useEsgConsolidationTableData } from '../../hooks';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import AcceptableTextField from 'src/components/AcceptableTextField';
import { useTranslation } from 'react-i18next';
import IndividualGroupsRow from './IndividualGroupsRow';
import DimensionsSelectors from './DimensionsSelectors';
import { lightenColor } from 'src/views/components/QuestionnaireV3/helpers';

interface ConsolidatedNonNumericTableProps {
  tableId: number;
  period: string;
  questionnaireKey: string;
  color: string;
}

export default function ConsolidatedNonNumericTable(
  props: ConsolidatedNonNumericTableProps
) {
  const { tableId, period, questionnaireKey, color } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg.dataVisualization',
  });

  const {
    data: consolidationTableData,
    isError,
    isSuccess,
    update,
  } = useEsgConsolidationTableData(tableId, period);
  const [collapsed, setCollapsed] = React.useState(false);
  const [collapsedGroupsList, setCollapsedGroupsList] = React.useState(false);

  const [selectedDimensions, setSelectedDimensions] = React.useState<
    Array<string | number | null>
  >([]);

  useEffect(() => {
    // Use first row as default selected dimensions
    if (
      consolidationTableData !== undefined &&
      consolidationTableData.rows.length > 0
    ) {
      setSelectedDimensions(
        consolidationTableData.rows[0].table_dimensions_values
      );
    }
  }, [consolidationTableData]);

  if (isError) {
    return <SomethingWentWrong />;
  }
  if (!isSuccess || !consolidationTableData) {
    return <PageLoading />;
  }

  if (consolidationTableData.rows.length === 0) return null;

  const selectedData = consolidationTableData.rows.find((row) => {
    return row.table_dimensions_values.every((dim, index) => {
      return selectedDimensions[index] === dim;
    });
  });

  const selectedDataIndex = consolidationTableData.rows.findIndex((row) => {
    return row.table_dimensions_values.every((dim, index) => {
      return selectedDimensions[index] === dim;
    });
  });

  const updateConsolidationTableData = (value: any) => {
    const dimensionValues = Object.fromEntries(
      selectedDimensions.map((dim, index) => [
        consolidationTableData.table_dimensions[index].key,
        dim,
      ])
    );
    update.mutate({
      value,
      table_dimensions_values: dimensionValues,
    });
  };

  const hasTitle = (data: unknown): data is ConsolidationTableDataSchema =>
    !!(data && typeof data === 'object' && 'title' in data);

  const title = hasTitle(consolidationTableData)
    ? `${questionnaireKey} > ${consolidationTableData.title}`
    : '';

  return (
    <TableContainer>
      <Table stickyHeader sx={{ minWidth: '1130px' }}>
        <TableBody>
          <TableRow>
            <CollapsableButtonCell
              colSpan={2}
              text={title}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              sx={{ border: `1px solid ${color}`, backgroundColor: color }}
            />
          </TableRow>

          {!collapsed && (
            <>
              <DimensionsSelectors
                key={selectedDimensions.join('_')}
                tableId={tableId}
                period={period}
                selectedDimensions={selectedDimensions}
                setSelectedDimensions={setSelectedDimensions}
              />
              <TableRow sx={{ height: '86px' }}>
                <TableCell
                  sx={{
                    backgroundColor: lightenColor(color, 3),
                    borderRight: `1px solid ${colors.disabledGraphics}`,
                  }}
                >
                  <Typography variant="subtitle2" sx={{ pl: '8px' }}>
                    {t('capitalGroup')}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: lightenColor(color, 3),
                    minWidth: '200px',
                  }}
                >
                  <AcceptableTextField
                    key={selectedDataIndex}
                    size="medium"
                    multiline
                    submitOnEnter={false}
                    minRows={2}
                    sx={{
                      width: '100%',
                      backgroundColor: colors.pureWhite,
                      borderRadius: '4px',
                    }}
                    value={selectedData?.consolidated_value}
                    onAccept={updateConsolidationTableData}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <CollapsableButtonCell
                  colSpan={11}
                  text={t('responsesFromIndividualCompanies')}
                  collapsed={collapsedGroupsList}
                  setCollapsed={setCollapsedGroupsList}
                  sx={{ border: `1px solid ${colors.disabledGraphics}` }}
                />
              </TableRow>
              {!collapsedGroupsList &&
                selectedData?.values.map((data, index) => (
                  <IndividualGroupsRow
                    sx={{
                      backgroundColor:
                        index % 2 === 0
                          ? 'transparent'
                          : colors.backgroundInput,
                    }}
                    {...data}
                  />
                ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
