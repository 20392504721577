import React from 'react';

import Field from '../components/Field';
import { EsgDmaValueChainEntityInSchema } from 'src/Esg/Dmav2/types';

interface Props {
  form: Partial<EsgDmaValueChainEntityInSchema>;
  //eslint-disable-next-line no-unused-vars
  onFieldChange: (key: string, value: any) => void;
}

export default function EntityForm(props: Props) {
  const { form, onFieldChange } = props;
  return (
    <>
      <Field
        fieldName="name"
        value={form.name ?? ''}
        onChange={(e) => onFieldChange('name', e.target.value)}
      />
      <Field
        fieldName="essentialProducts"
        value={form.essential_products ?? ''}
        textFieldProps={{ multiline: true, rows: 4 }}
        onChange={(e) => onFieldChange('essential_products', e.target.value)}
      />
      <Field
        fieldName="essentialServices"
        value={form.essential_services ?? ''}
        textFieldProps={{ multiline: true, rows: 4 }}
        onChange={(e) => onFieldChange('essential_services', e.target.value)}
      />
      <Field
        fieldName="collaborationDetails"
        value={form.collaboration_details ?? ''}
        textFieldProps={{ multiline: true, rows: 3 }}
        onChange={(e) => onFieldChange('collaboration_details', e.target.value)}
      />
    </>
  );
}
