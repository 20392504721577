import React from 'react';
import { Button, SxProps } from '@mui/material';

const withActivationButton = <P extends object>(Component: React.FC<P>) => {
  return (props: P & { isActive?: boolean; buttonSx?: SxProps }) => {
    const [active, setActive] = React.useState(props.isActive || false);
    if (active)
      return <Component {...props} onClose={() => setActive(false)} />;
    return (
      <Button
        sx={props.buttonSx}
        size="small"
        variant="outlined"
        onClick={() => setActive(true)}
      >
        Add
      </Button>
    );
  };
};

export default withActivationButton;
