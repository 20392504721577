import React from 'react';
import { useEsgConsolidationTables } from '../hooks';
import { ConsolidationTableDataSchema } from '../types';
import { StyledPanel } from 'src/components/StyledPanel';
import ConsolidationNumericTable from './ConsolidationNumericTable';
import ConsolidatedNonNumericTable from './ConsolidationNonNumericTable';
import { Box, Typography } from '@mui/material';

interface Props {
  questionnaireKey: string;
  period: string;
  color: string;
}

export default function ConsolidationQuestionPanels({
  questionnaireKey,
  period,
  color,
}: Props) {
  const { data } = useEsgConsolidationTables(questionnaireKey);

  const tablesGroupedByQuestion = data.reduce(
    (acc, table) => {
      if (!acc[table.question.id]) {
        acc[table.question.id] = [];
      }
      acc[table.question.id].push(table);
      return acc;
    },
    {} as Record<string, ConsolidationTableDataSchema[]>
  );
  const questionsById = Object.fromEntries(
    data.map((table) => [table.question.id, table.question])
  );

  return (
    <>
      {Object.entries(tablesGroupedByQuestion).map(([questionId, tables]) => (
        <StyledPanel key={questionId} sx={{ mt: '32px' }}>
          <Typography variant="h4" sx={{ mb: '16px' }}>
            {questionsById[questionId]?.title}
          </Typography>
          {tables.map((table, index) => (
            <Box sx={{ mt: index > 0 ? '32px' : '0px' }} key={table.id}>
              {table.aggregation_type === 'numeric' && (
                <ConsolidationNumericTable
                  tableId={table.id}
                  period={period}
                  questionnaireKey={questionnaireKey}
                  color={color}
                />
              )}
              {table.aggregation_type === 'text' && (
                <ConsolidatedNonNumericTable
                  tableId={table.id}
                  period={period}
                  questionnaireKey={questionnaireKey}
                  color={color}
                />
              )}
            </Box>
          ))}
        </StyledPanel>
      ))}
    </>
  );
}
