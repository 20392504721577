import React from 'react';

import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  headerKeys: string[];
  omitEdit?: boolean;
}

export default function TableHeader({ headerKeys, omitEdit }: Props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'ghg.tableV2.headers' });
  const width = `${Math.floor(100 / headerKeys.length)}%`;
  return (
    <TableHead>
      <TableRow>
        {headerKeys.map((header) => (
          <TableCell key={header} width={width} sx={{ ml: '6px' }}>
            {t(header)}
          </TableCell>
        ))}
        {!omitEdit && (
          <TableCell sx={{ ml: '6px' }}>{t('editOptions')}</TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}
