import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from 'src/Ghg/interfaces';
import { useGoodsAndServices } from '../../hooks';
import { useGhgOrganization } from 'src/common/hooks';
import {
  PreviewBox,
  StyledHeader,
  StyledTh,
} from 'src/views/Pages/Questionnaire/index.styles';
import AddProductButton from 'src/components/AddProductButton';
import GoodsPreview from 'src/views/components/Questionnaire/preview/goodsPreview';
import AverageMethodGoodForm from './form';
import { AverageMethodGoodData } from '../../types';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import AddButtonHolder from 'src/views/components/Questionnaire/AddButtonHolder';
import UploadExcelButton from 'src/components/UploadExcelButton';
import { getPeriodDate, questionForGoodType } from 'src/Ghg/utils';
import MethodDescriptionPanel from '../../components/MethodDescriptionPanel';
import { Typography } from '@mui/material';

interface Props {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

export const initialFormState = {
  emission_factor_is_known: false,
  good_data_factor_unknown: {
    good: '',
    quantity: '',
  },
  good_data_factor_known: {
    factor: '',
    amount: '',
    unit_label: '',
  },
  comment: '',
  name_label: '',
  _initial: true,
};

export default function AverageMethodBox({ type }: Props) {
  const organization = useGhgOrganization();

  const [showForm, setShowForm] = useState(false);
  const [active, setActive] = useState<undefined | number>(undefined);
  const [state, setState] = useState([initialFormState]);

  const period = getPeriodDate(organization.co2.reporting_freq);

  const keyPrefix = `goods-and-services.${questionForGoodType[type]}`;

  const {
    t,
    i18n: { t: tRoot },
  } = useTranslation(undefined, { keyPrefix });
  const { t: methodTranslation } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}.method2`,
  });

  const api = useGoodsAndServices({
    organization_id: organization.id,
    period,
    type,
  });

  const data = api.query.data?.average_method_data;
  const newForm = data?.length === 0;
  const emissionShares = api.queryEmissions.data?.average_method_data;

  useEffect(() => {
    if (data === undefined) return;
    setShowForm(false);

    setState(data.length !== 0 ? (data as any) : [initialFormState]);
  }, [data]);

  const update = async (newData: AverageMethodGoodData[]) => {
    const allData = api.query.data;
    if (allData === undefined) return;
    return await api.update.mutateAsync({
      ...allData,
      average_method_data: newData,
    });
  };

  if (api.isError) return <SomethingWentWrong />;
  if (api.isLoading) return <PageLoading />;

  const combineGoodsData = (existingData: any, newData: any) => {
    const validGoods = existingData.filter((good: any) => !good._initial);
    return [...newData, ...validGoods];
  };

  const methodBoxVisible = newForm && !showForm;

  return (
    <>
      {!methodBoxVisible && (
        <Typography variant="h3" sx={{ mb: '24px' }}>
          {t('method2.title')}
        </Typography>
      )}
      {newForm && (
        <div>
          {!showForm ? (
            <MethodDescriptionPanel
              t={methodTranslation}
              onButtonClick={() => setShowForm(true)}
            />
          ) : (
            <PreviewBox>
              {state.map((_, id) => (
                <AverageMethodGoodForm
                  setNewForm={() => {}}
                  variant={type}
                  active={active}
                  setActive={setActive}
                  key={id}
                  id={id}
                  state={state}
                  setState={setState}
                  update={update}
                />
              ))}
            </PreviewBox>
          )}
        </div>
      )}
      {!newForm && (
        <PreviewBox>
          <StyledHeader>
            <StyledTh width={33}>{t('table.column1')}</StyledTh>
            <StyledTh width={33}>{t('table.column4')}</StyledTh>
            <StyledTh width={33}>
              {tRoot(
                'direct-emission.stationary-combustion.table.emission_shared'
              )}
            </StyledTh>
            <StyledTh width={0}>{tRoot('sold-products.preview.edit')}</StyledTh>
          </StyledHeader>
          <div>
            {state.map((_, id) => {
              if (active === id) {
                return (
                  <AverageMethodGoodForm
                    setNewForm={() => {}}
                    variant={type}
                    active={active}
                    key={id}
                    id={id}
                    state={state}
                    setState={setState}
                    setActive={setActive}
                    update={update}
                  />
                );
              } else {
                return (
                  <GoodsPreview
                    setState={setState}
                    key={id}
                    state={state}
                    id={id}
                    setActive={setActive}
                    emissionShares={emissionShares}
                  />
                );
              }
            })}
          </div>
        </PreviewBox>
      )}
      {!methodBoxVisible && (
        <AddButtonHolder
          firstChild={
            <AddProductButton
              onClick={() => {
                if (newForm && state.length > 0) return;
                setState([initialFormState, ...state]);
                setActive(0);
              }}
            />
          }
          secondChild={
            <UploadExcelButton
              name={t('title')}
              slug="AverageMethodGoodData"
              fullData={state}
              combineData={combineGoodsData}
              sendData={update}
              refresh={api.refetch}
            />
          }
        />
      )}
    </>
  );
}
