import React from 'react';
import { useTranslation } from 'react-i18next';
import InviteSupplierModal from '../components/InviteSupplierModal';
import { useAppSelector } from 'src/redux-file/hooks';
import ProductTable from './ProductTable';
import { GOODS_AND_SERVICES_OR_CAPITAL_GOODS } from 'src/Ghg/interfaces';
import { timeConverter } from 'src/utils/timeConverter';
import { useSupplierGoods } from '../hooks';
import { useGhgOrganization } from '../../../common/hooks';
import MethodDescriptionPanel from '../components/MethodDescriptionPanel';
import { questionForGoodType } from 'src/Ghg/utils';

interface Props {
  type: GOODS_AND_SERVICES_OR_CAPITAL_GOODS;
}

export default function SupplierMethodBox({ type }: Props) {
  const organization = useGhgOrganization();
  const reportingPeriod = organization.co2.reporting_freq;
  const { selectedDate } = useAppSelector((state) => state.app);

  const _reportingPeriod = timeConverter(
    selectedDate || new Date(),
    reportingPeriod
  );

  const { t } = useTranslation(undefined, {
    keyPrefix: `goods-and-services.${questionForGoodType[type]}.method1`,
  });

  const [inviteSupplierModalOpen, setInviteSupplierModalOpen] =
    React.useState<boolean>(false);

  const { query } = useSupplierGoods(
    organization.id,
    `?type=${type}&reporting_period=${_reportingPeriod}`
  );

  if (query.data === undefined) return null;

  return (
    <>
      {query.data.length > 0 ? (
        <ProductTable
          data={query.data}
          onAddNewRow={() => setInviteSupplierModalOpen(true)}
          type={type}
        />
      ) : (
        <MethodDescriptionPanel
          t={t}
          onButtonClick={() => setInviteSupplierModalOpen(true)}
        />
      )}
      <InviteSupplierModal
        open={inviteSupplierModalOpen}
        onClose={() => setInviteSupplierModalOpen(false)}
      />
    </>
  );
}
