import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { styled, Typography } from '@mui/material';
import { client } from '../../../../utils/api-client';

import QuestionnairePreview from './Preview';
import Management from './Management';
import themeColors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled('div')``;

interface Props {
  addManagementPanel?: boolean;
  overrideFeedUrl?: string;
}

export default function Preview({
  addManagementPanel,
  overrideFeedUrl,
}: Props) {
  const { questionnaireAnswerId } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    const previewInterceptor = client.interceptors.request.use((config) => {
      // only allow GET requests,
      //  and requests related to language selection
      if (
        config?.method !== 'get' &&
        (config?.url?.indexOf('/web/users/me/language') ?? -1) === -1
      ) {
        alert('blocked !!');
        return Promise.reject(new Error('Blocked'));
      }
      return config;
    });
    return () => client.interceptors.request.eject(previewInterceptor);
  }, []);

  return (
    <StyledContainer>
      {addManagementPanel && <Management />}
      {!addManagementPanel && (
        <Typography
          variant="h1"
          color={themeColors.dark}
          sx={{
            color: themeColors.darkGrey,
            justifySelf: 'center',
            mt: '24px',
            mb: '24px',
          }}
        >
          {t('questionnaireV3.readonly')}
        </Typography>
      )}
      <QuestionnairePreview
        key={questionnaireAnswerId}
        id={String(questionnaireAnswerId)}
        overrideFeedUrl={overrideFeedUrl}
      />
    </StyledContainer>
  );
}
