import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReports } from 'src/Cbam/hooks';
import { ReportingFreq } from 'src/utils/api.interfaces';
import { periodToDate, timeConverter } from 'src/utils/timeConverter';
import { IntuitiveCalendarPeriod } from 'src/views/components/IntuitiveCalendar';
import { StyledPanel } from 'src/components/StyledPanel';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { CbamReportCreateInSchema } from 'src/Cbam/types';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { toast } from 'react-toastify';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import { useCbamOrganization } from 'src/common/hooks';

export default function CreateCbamReport() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.report',
  });
  const { id: organizationId } = useCbamOrganization();
  const [form, setForm] = useState({
    cbam_organization_id: organizationId,
    name: '',
    remarks: '',
    period: timeConverter(new Date(), ReportingFreq.Q),
    signature_place: '',
    signature_name: '',
    signature_position: '',
    signature_global_data_confirmation: false,
    signature_use_of_data_confirmation: false,
    signature_other_applicable_methodology_confirmation: false,
  });
  const [errors, setErrors] = React.useState<FormErrors>({});
  const [isModified, setIsModified] = React.useState(false);

  const { create } = useReports();
  const navigate = useNavigate();

  const handleChange = (field: string, value: any) => {
    const newForm = {
      ...form,
      [field]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  const isError = (fieldName: keyof CbamReportCreateInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamReportCreateInSchema) =>
    isError(fieldName) && (mapError(errors, fieldName, form[fieldName]) || '');

  const handleSave = () => {
    setIsModified(false);

    create
      .mutateAsync(form)
      .then((response) => {
        navigate(`../${response.data.id}/about`);
      })
      .catch((err: any) => {
        setIsModified(true);
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });
  };

  return (
    <StyledPanel>
      <NavigationBlockerModal shouldBlock={isModified} />

      <Typography variant="h1">{t('newReport')}</Typography>
      <Box sx={{ backgroundColor: 'white', margin: '20px 0' }}>
        <TextField
          key="name"
          id="name"
          sx={{ width: '100%' }}
          label={t('reportName')}
          value={form.name}
          onChange={(event) => handleChange('name', event.target.value)}
          required={requiredFields.includes(
            'name' as keyof CbamReportCreateInSchema
          )}
          error={isError('name' as keyof CbamReportCreateInSchema)}
          helperText={errorText('name' as keyof CbamReportCreateInSchema)}
        />
      </Box>
      <IntuitiveCalendarPeriod
        reportingPeriod={ReportingFreq.Q}
        selectedDate={periodToDate(form.period, ReportingFreq.Q)}
        setSelectedDate={(selDate: Date) =>
          handleChange('period', timeConverter(selDate, ReportingFreq.Q))
        }
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '24px',
        }}
      >
        <Button
          onClick={() => handleSave()}
          disabled={
            !isModified || Object.keys(validateForm(form, t)).length > 0
          }
        >
          {t('create')}
        </Button>
      </div>
    </StyledPanel>
  );
}
