import React from 'react';
import { useTranslation } from 'react-i18next';
import { MatrixAxesText } from '../styles';

export default function ChartAxesLegend() {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          position: 'absolute',
          transform: 'translate(-5%, 100px) rotate(-90deg)',
          left: '0',
          bottom: '50%',
          transformOrigin: 'left center',
        }}
      >
        <MatrixAxesText>
          {t('esg.dma.stakeholderAnalytics.dashboard.barchart.positiveImpact')}
        </MatrixAxesText>
      </div>
      <div
        style={{
          position: 'absolute',
          left: '25%',
          bottom: '-20px',
        }}
      >
        <MatrixAxesText>
          {t('esg.dma.stakeholderAnalytics.dashboard.barchart.negativeImpact')}
        </MatrixAxesText>
      </div>
      <svg
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
      >
        <line
          x1="26"
          y1="471"
          x2="99%"
          y2="471"
          stroke="#6E6E6E"
          strokeWidth="2px"
          markerEnd="url(#arrowheadRight)"
        />
        <line
          x1="27"
          y1="471"
          x2="27"
          y2="1%"
          stroke="#6E6E6E"
          strokeWidth="2px"
          markerEnd="url(#arrowheadUp)"
        />
        <defs>
          <marker
            id="arrowheadRight"
            markerWidth="16"
            markerHeight="16"
            refX="12"
            refY="5"
            orient="auto"
          >
            <line
              x1="8"
              y1="1"
              x2="12"
              y2="5"
              stroke="#6E6E6E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1="8"
              y1="9"
              x2="12"
              y2="5"
              stroke="#6E6E6E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </marker>
          <marker
            id="arrowheadUp"
            markerWidth="16"
            markerHeight="16"
            refX="5"
            refY="0"
          >
            <line
              x1="1"
              y1="5"
              x2="5"
              y2="0"
              stroke="#6E6E6E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <line
              x1="9"
              y1="5"
              x2="5"
              y2="0"
              stroke="#6E6E6E"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </marker>
        </defs>
      </svg>
    </>
  );
}
