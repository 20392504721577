import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  CbamInstallationOperatorInSchema,
  CbamInstallationOperatorOutSchema,
} from '../types';
import { toast } from 'react-toastify';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import { useCbamOrganization, useNonEUCountries } from 'src/common/hooks';
import { useTranslation } from 'react-i18next';
import { FormErrors, requiredFields, validateForm } from './FormValidation';
import { extractSchemaErrors, mapError } from 'src/utils/validation';
import { useOperators } from '../hooks';
import {
  StyledFormControl,
  StyledSectionHeader,
  StyledSectionDivider,
  StyledTextField,
} from '../styles';
import InfoPanel from '../components/InfoPanel';
import OperatorInfoPanel from '../Operators/OperatorInfoPanel';

interface Props {
  id?: Number;
  survey?: boolean;
  onSaved?: () => void;
}

export default function OperatorForm(props: Props) {
  const { id, survey: _, onSaved } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'cbam.operators',
  });
  const { data, create, update } = useOperators();
  const { data: countries } = useNonEUCountries();
  const { id: organizationId } = useCbamOrganization();

  const operator = data.find((operator) => operator.id === id);

  const [form, setForm] = React.useState<
    CbamInstallationOperatorOutSchema | CbamInstallationOperatorInSchema
  >({
    cbam_organization_id: organizationId,
    identifier: '',
    name: '',
    name_en: '',

    country: '',
    sub_division: '',
    city: '',
    street_name: '',
    street_name_additional_line: '',
    street_number: '',
    post_code: '',
    po_box: '',

    contact_name: '',
    contact_email: '',
    contact_phone_number: '',
  });

  const [errors, setErrors] = React.useState<FormErrors>({});

  const [isModified, setIsModified] = React.useState(false);

  const handleFormChange = (key: string, value: string | null) => {
    const newForm = {
      ...form,
      [key]: value,
    };
    setForm(newForm);
    setErrors(validateForm(newForm, t));
    setIsModified(true);
  };

  React.useEffect(() => {
    if (operator) setForm(operator);
  }, [operator]);

  const save = !operator ? create.mutateAsync : update.mutateAsync;

  const isError = (fieldName: keyof CbamInstallationOperatorInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof CbamInstallationOperatorInSchema) =>
    isError(fieldName) && (mapError(errors, fieldName, form[fieldName]) || '');

  const handleSaveClick = () => {
    setIsModified(false);
    save(form)
      .then(() => onSaved?.())
      .catch((err: any) => {
        setIsModified(true);
        toast.error('Failed to save');
        const extractedErrors = extractSchemaErrors(err, form);
        setErrors(extractedErrors);
      });
  };

  return (
    <Box>
      <NavigationBlockerModal shouldBlock={isModified} />
      <Box sx={{ mb: '12px' }}>
        <OperatorInfoPanel />
      </Box>
      <StyledPanel>
        <StyledSectionHeader>{t('general_information')}</StyledSectionHeader>
        {['identifier', 'name', 'name_en'].map((field) => (
          <StyledTextField
            key={field}
            label={t(`form.${field}`)}
            value={form[field as keyof CbamInstallationOperatorInSchema]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFormChange(field, e.currentTarget.value)
            }
            required={requiredFields.includes(
              field as keyof CbamInstallationOperatorInSchema
            )}
            error={isError(field as keyof CbamInstallationOperatorInSchema)}
            helperText={errorText(
              field as keyof CbamInstallationOperatorInSchema
            )}
          />
        ))}
        <StyledSectionDivider />
        <StyledSectionHeader>{t('address')}</StyledSectionHeader>
        <StyledFormControl required>
          <InputLabel id="country">{t('form.country')}</InputLabel>
          <Select
            key={form.country}
            labelId="country"
            label={t('form.country')}
            value={form.country}
            onChange={(e) => handleFormChange('country', e.target.value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.iso_code} value={country.iso_code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {[
          'sub_division',
          'city',
          'street_name',
          'street_name_additional_line',
          'street_number',
          'post_code',
          'po_box',
        ].map((field) => (
          <StyledTextField
            key={field}
            label={t(`form.${field}`)}
            value={form[field as keyof CbamInstallationOperatorInSchema]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFormChange(field, e.currentTarget.value)
            }
            required={requiredFields.includes(
              field as keyof CbamInstallationOperatorInSchema
            )}
            error={isError(field as keyof CbamInstallationOperatorInSchema)}
            helperText={errorText(
              field as keyof CbamInstallationOperatorInSchema
            )}
          />
        ))}
        <StyledSectionDivider />
        <StyledSectionHeader>{t('contact')}</StyledSectionHeader>
        <Box sx={{ mb: '12px' }}>
          <InfoPanel>
            <Typography>
              Personal data of the person authorized to represent the Operator
              in matters related to CBAM.
            </Typography>
          </InfoPanel>
        </Box>
        {['contact_name', 'contact_email', 'contact_phone_number'].map(
          (field) => (
            <StyledTextField
              key={field}
              label={t(`form.${field}`)}
              value={form[field as keyof CbamInstallationOperatorInSchema]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleFormChange(field, e.currentTarget.value)
              }
              required={requiredFields.includes(
                field as keyof CbamInstallationOperatorInSchema
              )}
              error={isError(field as keyof CbamInstallationOperatorInSchema)}
              helperText={errorText(
                field as keyof CbamInstallationOperatorInSchema
              )}
            />
          )
        )}
      </StyledPanel>
      <br />
      <br />
      <Button
        sx={{ float: 'right' }}
        onClick={handleSaveClick}
        disabled={!isModified || Object.keys(validateForm(form, t)).length > 0}
      >
        {t('save')}
      </Button>
    </Box>
  );
}
