import { Box, Button, Dialog, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useLanguage } from 'src/common/hooks';
import { StyledPanel } from 'src/Esg/styles';
import colors from 'src/theme/colors';

export type Props = {
  minimizedHeight?: number | string;
  children?: React.ReactNode;
};

const fullScreenSxProps: SxProps<Theme> = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: colors.background,
};

export default function FullScreenPanel({
  minimizedHeight = '600px',
  children,
}: Props) {
  const { t } = useLanguage();

  const [isFullScreen, setIsFullScreen] = React.useState(false);

  return (
    <>
      <Box sx={isFullScreen ? fullScreenSxProps : {}}>
        <Button
          onClick={() => setIsFullScreen(true)}
          variant="outlined"
          sx={{ m: isFullScreen ? 2 : 0, mb: 2 }}
        >
          {t('fullScreen.open')}
        </Button>
        <StyledPanel
          sx={{
            p: 0,
            overflow: 'auto',
            height: minimizedHeight,
          }}
        >
          {isFullScreen ? null : children}
        </StyledPanel>
      </Box>
      <Dialog
        fullScreen
        open={isFullScreen}
        onClose={() => setIsFullScreen(false)}
        sx={{
          '& .MuiPaper-root': {
            p: 0,
            borderRadius: 0,
          },
        }}
      >
        <Button
          onClick={() => setIsFullScreen(false)}
          variant="outlined"
          sx={{ m: 2, alignSelf: 'flex-start' }}
        >
          {t('fullScreen.close')}
        </Button>
        <StyledPanel
          sx={{
            p: 0,
            overflow: 'auto',
            height: 'calc(100vh - 72px)',
          }}
        >
          {isFullScreen ? children : null}
        </StyledPanel>
      </Dialog>
    </>
  );
}
