import React from 'react';
import { useParams } from 'react-router-dom';

import { StyledPanel } from 'src/Esg/styles';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { useEsgDmas, useEsgDmaValueChainEntities } from '../../hooks';
import ValueChainEntity from './ValueChainEntity';
import NewValueChainEntity from './ValueChainEntity/AddNew';
import { useEsgReports } from 'src/Esg/hooks';

export default function ValueChainEntities() {
  const { reportId } = useParams();
  const { _instance: esgReport } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma } = useEsgDmas(
    { id: esgReport?.esg_dma_id },
    { enabled: !!esgReport }
  );
  const { _data, isError, isLoading } = useEsgDmaValueChainEntities(
    { esg_dma_id: dma?.id },
    { enabled: !!dma }
  );

  if (isError) return <SomethingWentWrong />;
  if (isLoading) return <PageLoading />;

  return (
    <StyledPanel>
      {/* Listing existing value chain entities */}
      {_data.map((entity) => (
        <ValueChainEntity key={entity.id} id={entity.id} />
      ))}
      {/* Adding new value chain entity */}
      <NewValueChainEntity buttonSx={{ m: '20px' }} />
    </StyledPanel>
  );
}
