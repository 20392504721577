import React from 'react';

import { Field as FieldType } from './index.types';
import { getFieldComponent } from './Fields';
import { FieldValue, Errors } from './Fields/types';
import { Box } from '@mui/material';
import { StyledTitle } from './index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import FieldAnswerComments from './FieldAnswerComments';
import { usePlatform } from 'src/redux-file/hooks';

type Props = {
  field: FieldType;
  fieldAnswerId?: number;
  errors: Errors;
  value?: FieldValue;
  onForceQuestionAnswerSave: () => void;
  onChange: (field: FieldType, value: FieldValue) => void;
  hints?: any[];
};

export default function Field(props: Props) {
  const {
    field,
    fieldAnswerId,
    errors,
    onChange,
    onForceQuestionAnswerSave,
    hints,
  } = props;

  const FieldComponent = React.useMemo(
    () => getFieldComponent(field.visual_type),
    [field.visual_type]
  );

  const { user } = usePlatform();

  const onChangeCallback = React.useCallback(
    (value: FieldValue) => {
      onChange(field, value);
    },
    [field, onChange]
  );

  const value = props.value ?? '';
  const { type, title } = field;
  const showTitle = title && type !== 'empty';

  const commentsVisible = fieldAnswerId && user?.id;
  const containerSx = commentsVisible
    ? { alignItems: 'flex-start', width: 'calc(100% + 75px)' }
    : {};

  return (
    <>
      {showTitle && <StyledTitle>{field.title}</StyledTitle>}
      <StyledFlex sx={containerSx}>
        <Box width="100%">
          <FieldComponent
            field={field}
            fieldAnswerId={fieldAnswerId}
            value={value}
            onChange={onChangeCallback}
            errors={errors}
            onForceQuestionAnswerSave={onForceQuestionAnswerSave}
            hints={hints}
          />
        </Box>
        {commentsVisible && (
          <Box sx={{ ml: '75px', mt: field?.title ? '10px' : '30px' }}>
            <FieldAnswerComments fieldAnswerId={fieldAnswerId} />
          </Box>
        )}
      </StyledFlex>
    </>
  );
}
